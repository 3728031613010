import { WidgetProps } from "@rjsf/utils";
import { FormWrapped } from "./FormWrapped";
import { IFormWidgetWrapped } from "./IFormWrapped";

export class FormWidgetWrapped<T extends IFormWidgetWrapped>
    extends FormWrapped<T>
    implements WidgetProps {
    constructor(wrapped: T) {
        super(wrapped);
    }

    get label(): string {
        return this._wrapped.label || "";
    }

    get options() {
        return this._wrapped.options;
    }

    get multiple(): boolean {
        return this._wrapped.multiple || false;
    }

    toPlainObject(): object {
        const item = super.toPlainObject();
        return {
            ...item,
            label: this.label,
            options: this.options,
            multiple: this.multiple,
        };
    }
}
