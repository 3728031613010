import React from "react";
import { Avatar } from "@mrs/webclient-shared-ui-lib";
import { IRegistrationComponent } from "../RegistrationComponents";

export const AvatarRegistration: IRegistrationComponent<React.FC> = {
    component: Avatar,
    meta: {
        name: "Avatar",
        props: {
            size: "number",
            src: "string",
        },
    },
};
