export const browserPrefix = (() => {
    const styles = window.getComputedStyle(document.documentElement, "");
    // @ts-ignore
    const browserMatch = (Array.prototype.slice
        .call(styles)
        .join("")
        .match(/-(moz|webkit|ms)-/) ||
        // @ts-ignore
        (styles.OLink === "" && ["", "o"]))[1];

    return "-" + browserMatch + "-";
})();

export const isWebkitBrowserPrefix = browserPrefix === "-webkit-";

export function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let matches =
        userAgent.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || [];
    if (/trident/i.test(matches[1])) {
        const msieMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return { name: "IE", version: msieMatch[1] || "" };
    }
    if (matches[1] === "Chrome") {
        const edgeMatch = userAgent.match(/\bEdg\/(\d+)/);
        if (edgeMatch !== null) {
            return { name: "Edge(Chromium)", version: edgeMatch[1] };
        }
        const operaMatch = userAgent.match(/\bOPR\/(\d+)/);
        if (operaMatch !== null) {
            return { name: "Opera", version: operaMatch[1] };
        }
    }
    matches = matches[2]
        ? [matches[1], matches[2]]
        : [navigator.appName, navigator.appVersion, "-?"];
    const versionMatch = userAgent.match(/version\/(\d+)/i);
    if (versionMatch !== null) {
        matches.splice(1, 1, versionMatch[1]);
    }
    return {
        name: matches[0],
        version: matches[1],
    };
}
