export const SUCCESS_COLOR_HUE_DARK = 700;
export const SUCCESS_COLOR_HUE_MAIN = 500;
export const SUCCESS_COLOR_HUE_LIGHT = 300;

export const SUCCESS_COLOR_HUES = {
    0: "#FFFFFF",
    50: "#F0FDF4",
    100: "#DCFCE7",
    200: "#BBF7D0",
    300: "#86EFAC",
    400: "#4ADE80",
    500: "#22C55E",
    600: "#16A34A",
    700: "#15803D",
    800: "#166534",
    900: "#14532D",
};
