import React, { useCallback, useEffect } from "react";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";

export const useTextWidget = (props: IFormWidgetWrapped) => {
    const { id, options } = props;

    const [value, setValue] = React.useState<string>(props.value);

    useEffect(() => {
        if (props.value !== value) setValue(props.value);
    }, [props.value]);

    const onChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            const val = target.value === "" ? options.emptyValue : target.value;
            setValue(val);
        },
        [options],
    );

    const onBlur = useCallback(
        async ({ target }: React.FocusEvent<HTMLInputElement>) => {
            await props.onChange(target.value);
            props.onBlur(id, target.value);
        },
        [id],
    );

    const onFocus = useCallback(
        ({ target }: React.FocusEvent<HTMLInputElement>) =>
            props.onFocus(id, target.value),
        [id],
    );

    return {
        value,
        onChange,
        onBlur,
        onFocus,
    };
};
