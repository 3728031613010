import get from "lodash-es/get";

const dependenciesDefault = {
    react: require("react"),
    "react-dom": require("react-dom"),
    "react-dom/client": require("react-dom/client"),
    "react-dom/server": require("react-dom/server"),
    mobx: require("mobx"),
    "mobx-react": require("mobx-react"),
    "lodash-es": require("lodash-es"),
    i18next: require("i18next"),
    "date-fns": require("date-fns"),
    "date-fns/locale": require("date-fns/locale"),

    "@mrs/webclient-shared-ui-lib": require("@mrs/webclient-shared-ui-lib"),
    "@mui/lab": require("@mui/lab"),
    "@mui/material": require("@mui/material"),
    "@mui/material/styles": require("@mui/material/styles"),
    "@mui/material/utils": require("@mui/material/utils"),
    "react-i18next": require("react-i18next"),
    "web-ifc-viewer": require("web-ifc-viewer"),
};

const createRequires = (dependencies: object) => (name: string) => {
    return get(dependencies, name, {});
};

export interface IRemoteModule {
    default(params?: object): any;
}

export function createLoadRemoteModule(data: string) {
    const exports = {} as IRemoteModule;
    const module = { exports };
    const hook = new Function("require", "module", "exports", data);
    const requires = createRequires(dependenciesDefault);
    hook(requires, module, exports);
    return module.exports;
}
