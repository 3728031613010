import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    DateUtils,
    IconByValue,
    IPeriod,
    IViewFilter,
    MRSIcon,
    TVoidCallback,
    VIEW_FILTER_TYPE,
    ICheckedFilter,
} from "@mrs/webclient-shared-ui-lib";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { FilterChip } from "../filterChip/FilterChip";
import { ChipSize } from "../../../shared/Chip/Chip";

export const CHIPS_PANEL_HEIGHT = 56;

interface IViewChipFiltersProps {
    filters: ICheckedFilter[];
    openCurrentFilter: TVoidCallback<ICheckedFilter>;
    onClearFilter: TVoidCallback<ICheckedFilter>;
    onClearAllFilters: () => void;
}

export const ViewChipFilters = (props: IViewChipFiltersProps) => {
    const {
        filters,
        openCurrentFilter,
        onClearAllFilters,
        onClearFilter,
    } = props;
    const { t } = useTranslation();
    const filtersPanelRef = useRef<HTMLDivElement>(null);

    const onWheel = useCallback((event: React.WheelEvent<HTMLElement>) => {
        if (event.deltaY === 0) return;
        const current = filtersPanelRef.current;
        if (!!current) {
            current.scrollTo({
                left: current.scrollLeft + event.deltaY,
                behavior: "smooth",
            });
        }
    }, []);

    return (
        <>
            <Box ref={filtersPanelRef} onWheel={onWheel} sx={styles.chipsPanel}>
                <Button
                    onClick={onClearAllFilters}
                    variant={"outlined"}
                    sx={styles.reset}
                >
                    {t("common:common.resetAll")}
                </Button>
                {filters.map((filter: ICheckedFilter) => {
                    const item = filter.value;
                    const chipData = getChipData(item);
                    return (
                        <FilterChip
                            key={`chip-${filter.index}`}
                            tooltipTitle={item.title || ""}
                            label={chipData?.title || ""}
                            item={filter}
                            size={ChipSize.ExtraSmall}
                            icon={<IconByValue value={item.icon as MRSIcon} />}
                            sx={styles.chip}
                            onClick={openCurrentFilter}
                            onDelete={onClearFilter}
                        />
                    );
                })}
            </Box>
            <Divider />
        </>
    );
};

function getChipData(item: IViewFilter): Partial<IViewFilter> {
    const value = item.params[0].value;
    switch (item.type) {
        case VIEW_FILTER_TYPE.TEXT: {
            return { title: value as string };
        }
        case VIEW_FILTER_TYPE.VIEW:
            return { title: item.title };
        case VIEW_FILTER_TYPE.DATE: {
            const period: IPeriod = {
                startTime: (value as any).start,
                endTime: (value as any).end,
            };
            return { title: getDateTitle(period) };
        }
        case VIEW_FILTER_TYPE.SWITCH:
            return { title: item.title };
    }
}

function getDateTitle(value: IPeriod) {
    let title = "";
    if (value.startTime && value.endTime) {
        const start = DateUtils.getDateMonth(value.startTime);
        const end = DateUtils.getDateMonth(value.endTime);
        title = `${start} - ${end}`;
    }
    return title;
}

const styles = {
    chipsPanel: {
        display: "flex",
        alignItems: "center",
        overflowX: "auto",
        overflowY: "hidden",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        backgroundColor: "background.paper",
        borderRadius: 1,
        minHeight: CHIPS_PANEL_HEIGHT,
        p: (theme: Theme) => theme.spacing(0, 2),
    },
    reset: {
        height: 32,
        minWidth: "inherit",
        whiteSpace: "nowrap",
        p: (theme: Theme) => theme.spacing(0.5, 1.5),
        mr: 1,
        "&:hover": {
            backgroundColor: "action.hover",
            border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
        },
    },
    chip: {
        backgroundColor: "background.default",
        "&:hover": {
            backgroundColor: "background.default",
        },
    },
};
