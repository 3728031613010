import { ILoginPresenter, ILoginParams } from "./ILoginPresenter";
import { computed, observable, toJS } from "mobx";
import { Notify } from "@utils/notify/Notify";
import { LoginErrorCodes } from "../../../../core/context/login/dto/LoginErrorCodes";
import { CurrentUser } from "../../../../core/context/user/currentUser/CurrentUser";
import { LoginPanes } from "../LoginPanes";
import { i18next } from "../../../../../lib/translator";
import { ApplicationDiType } from "@ui/diType";
import { lazyInject } from "../../../../core/AppDiContainer";
import { ConfigAccess } from "@utils/ConfigAccess";
import { IAuthService } from "../../../../core/context/auth/service/IAuthService";
import isEmpty from "lodash-es/isEmpty";
import { ClientException } from "../../../../core/sharedKernel/error/ClientException";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventType,
} from "@mrs/webclient-shared-ui-lib";

interface IError {
    code: number;
    message: string;
}

export class LoginPresenter implements ILoginPresenter {
    @observable private _orgUrl: string = "";
    @observable private _errorCode: LoginErrorCodes | null = null;
    @observable private _currentPane: LoginPanes | null = null;
    @observable private _isLoading: boolean = false;

    @lazyInject(ApplicationDiType.IAuthService)
    private readonly authService: IAuthService;

    @computed
    get orgUrl(): string {
        return this._orgUrl;
    }

    @computed
    get isLoading(): boolean {
        return this._isLoading;
    }

    @computed
    get errorCode(): LoginErrorCodes | null {
        return toJS(this._errorCode);
    }

    @computed
    get showErrorPage(): boolean {
        return !!this._errorCode;
    }

    @computed
    get currentPane(): LoginPanes | null {
        return toJS(this._currentPane);
    }

    init = async (params?: ILoginParams) => {
        if (!!params?.errorCode) {
            this._errorCode = params.errorCode;
            return this.setCurrentPane(LoginPanes.ERROR);
        }
        if (!isEmpty(ConfigAccess.config)) return this.redirectToKeycloak();

        this._orgUrl = window.location.origin;
        try {
            await this.redirectToKeycloakByConfig();
        } catch (e) {
            console.error(e);
        }
        if (isEmpty(ConfigAccess.config)) {
            this._orgUrl = "";
            this.setCurrentPane(LoginPanes.SUBNET_LOGIN);
        }
    };

    onChangeOrgUrl = (value: string) => {
        this._orgUrl = value;
    };

    subnetLogin = async () => {
        AnalyticsService.logEvent({
            type: AnalyticsEventType.System,
            location: AnalyticsEventLocation.URL,
            component: AnalyticsEventComponent.LoginUrl,
            action: AnalyticsEventAction.Click,
        });
        if (!this._orgUrl) {
            return this.showErrorMessage();
        }

        try {
            this.setIsLoading(true);
            await this.redirectToKeycloakByConfig();
        } catch (e) {
            Notify.error({ message: (e as IError).message });
        } finally {
            this.setIsLoading(false);
        }
    };

    redirectToKeycloakByConfig = async () => {
        try {
            await this.requestConfig();
            this.redirectToKeycloak();
        } catch (e) {
            throw e;
        }
    };

    tryLogin = () => {
        this._errorCode = null;
        this.init();
    };

    private redirectToKeycloak = () => {
        this.authService.login();
        this._currentPane = null;
    };

    private setCurrentPane = (currentPane: LoginPanes) => {
        this._currentPane = currentPane;
    };

    private setIsLoading = (isLoading: boolean) => {
        this._isLoading = isLoading;
    };

    private requestConfig = async () => {
        const orgUrl = this._orgUrl.trim();
        if (!orgUrl) return;
        try {
            await ConfigAccess.requestConfig(orgUrl);
            CurrentUser.dropUser();
            ConfigAccess.setConfigUrl(orgUrl);
        } catch (e) {
            console.error(e);
            throw new ClientException(i18next.t("common:login.subnetError"));
        }
    };

    private showErrorMessage() {
        Notify.error({ message: i18next.t("common:login.notValid") as string });
    }
}
