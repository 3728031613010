export enum ObjectType {
    DOCUMENT = "document",
    // formInstance
    FORM_INSTANCE = "formInstance",
    // view
    VIEW = "view",
    // report
    REPORT = "report",
    // post
    POST = "post",
    // dictionary
    DICTIONARY = "dictionary",
}
