import React from "react";
import { ExternalToast } from "sonner";

export enum NotifyVariant {
    Error = "error",
    Success = "success",
    Warning = "warning",
    Info = "info",
}
export type TNotifyPosition =
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "top-center"
    | "bottom-center";

export interface INotification {
    message: string | React.ReactNode;
    data?: ExternalToast;
    variant: NotifyVariant;
    position?: TNotifyPosition;
}
