import { Changes } from "../../types/Changes";
import { IWithId, ObjectId } from "@mrs/webclient-shared-ui-lib";
import { ObjectType } from "../../../ObjectType";
import { ICRUDRepository } from "../../repository/ICRUDRepository";
import { IAbstractApplicationService } from "../IAbstractApplicationService";
import { ClientException } from "../../error/ClientException";
import { IFindOptions } from "@utils/IFindOptions";
import { PaginationUtils } from "@utils/PaginationUtils";
import cloneDeep from "lodash-es/cloneDeep";
import { injectable, unmanaged } from "inversify";

@injectable()
export class AbstractApplicationService<
    T extends IWithId,
    TypeDTO extends IWithId,
    C,
    R extends ICRUDRepository<T, TypeDTO, C>
> implements IAbstractApplicationService<T, C> {
    protected repository: R;
    protected entityType: ObjectType;

    protected constructor(@unmanaged() repository: R, entityType: ObjectType) {
        this.repository = repository;
        this.entityType = entityType;
    }

    public create(createDto: C): Promise<T> {
        return this.repository.create(createDto);
    }

    public async update(updateDto: Changes<T>): Promise<T> {
        const entity = await this.repository.getById(updateDto.id);

        if (!entity) {
            throw new ClientException("Item for update not found");
        }

        return this.repository.update(entity, updateDto);
    }

    public remove(ids: ObjectId[]): Promise<boolean> {
        return this.repository.remove(ids);
    }

    public getAll(options?: IFindOptions): Promise<T[]> {
        return this.repository.getAll(options);
    }

    public getById(id: ObjectId): Promise<T | undefined> {
        if (!id) return Promise.resolve(undefined);
        return this.repository.getById(id);
    }

    public async getByIds(
        ids: ObjectId[],
        options?: IFindOptions,
        limit = 100,
    ): Promise<T[]> {
        if (!ids || ids.length === 0) return Promise.resolve([]);

        if (ids.length < limit) {
            return this.repository.getByIds(ids, options);
        }

        return PaginationUtils.requestWithPagination<T>(
            cloneDeep(ids),
            (requestIds: ObjectId[]) =>
                this.repository.getByIds(requestIds, options),
            limit,
        );
    }
}
