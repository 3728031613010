import { IFilterDialogPresenter } from "./IFilterDialogPresenter";
import { computed, observable, toJS } from "mobx";
import isEqual from "lodash-es/isEqual";
import {
    KeyCode,
    VIEW_FILTER_TYPE,
    ICheckedFilter,
} from "@mrs/webclient-shared-ui-lib";
import { ObjectSearch } from "@utils/ObjectSearch";
import cloneDeep from "lodash-es/cloneDeep";

export class FilterDialogPresenter implements IFilterDialogPresenter {
    @observable private _filters: ICheckedFilter[] = [];
    @observable private _currentFilterIndex: number | null = null;
    @observable private _searchValue: string = "";
    @observable private _showFilter: boolean = false;
    @observable private _isValidData: boolean = true;

    @computed
    get filters(): ICheckedFilter[] {
        return toJS(this._filters);
    }

    @computed
    get filtersBySearch(): ICheckedFilter[] {
        return this.getItemsBySearchValue(this.searchValue, this.filters);
    }

    @computed
    get currentFilter(): ICheckedFilter | null {
        return (
            this.filtersBySearch.find(
                (el, i) => i === this._currentFilterIndex,
            ) || null
        );
    }

    @computed
    get searchValue(): string {
        return this._searchValue;
    }

    @computed
    get showFilter(): boolean {
        return this._showFilter;
    }

    init = (filters: ICheckedFilter[]) => {
        this.updateFilters(filters);
    };

    unmount = () => {
        this._filters = [];
        this._searchValue = "";
        this._currentFilterIndex = null;
    };

    updateFilters = (value: ICheckedFilter[]) => {
        this._filters = cloneDeep(value);
    };

    onClose = () => {
        this._searchValue = "";
        this._currentFilterIndex = null;
        this._showFilter = false;
    };

    setCurrentFilter = (index: number | null, isOpen: boolean) => {
        this.changeCurrentFilterIndex(index);
        this._showFilter = isOpen;
    };

    onClickItem = (item: ICheckedFilter) => {
        if (item.value.type === VIEW_FILTER_TYPE.SWITCH) {
            this.onClickSwitchItem(item);
        } else {
            this.setCurrentFilter(item.index, true);
        }
    };

    onChangeSearchValue = (value: string) => {
        this._searchValue = value;
        this._currentFilterIndex = null;
    };

    canApply = (filters: ICheckedFilter[]) => {
        return !isEqual(this.filters, filters) && this._isValidData;
    };

    handleKeyDown = (event: KeyboardEvent) => {
        const code = event.code;
        const ctrlKey = event.ctrlKey;

        if (code === KeyCode.ARROW_DOWN && !ctrlKey && !this.showFilter) {
            event.preventDefault();
            this.increaseCurrentIndex();
        }
        if (code === KeyCode.ARROW_UP && !ctrlKey && !this.showFilter) {
            event.preventDefault();
            this.decreaseCurrentIndex();
        }
        if (
            (code === KeyCode.SPACE || code === KeyCode.ENTER) &&
            !ctrlKey &&
            !this.showFilter &&
            this.currentFilter
        ) {
            event.preventDefault();
            if (this.currentFilter.value.type !== VIEW_FILTER_TYPE.SWITCH) {
                this._showFilter = true;
            }
        }
    };

    setFilter = (value: ICheckedFilter) => {
        const index = this.filters.findIndex(
            (filter: ICheckedFilter) => filter.index === value.index,
        );
        if (index > -1) {
            this._filters[index] = value;
        }
    };

    setIsValidData = (value: boolean) => {
        this._isValidData = value;
    };

    private increaseCurrentIndex = () => {
        const index =
            this._currentFilterIndex !== null
                ? this._currentFilterIndex + 1
                : 0;
        this._currentFilterIndex =
            index > this.filtersBySearch.length - 1 ? index - 1 : index;
    };

    private decreaseCurrentIndex = () => {
        const index = this._currentFilterIndex || 0;
        this._currentFilterIndex = index === 0 ? index : index - 1;
    };

    private changeCurrentFilterIndex = (index: number | null) => {
        const result = this.filtersBySearch.findIndex(
            (el: ICheckedFilter) => el.index === index,
        );
        this._currentFilterIndex = result > -1 ? result : null;
    };

    private onClickSwitchItem = (item: ICheckedFilter) => {
        const newChecked = !item.checked;
        this.setFilter({ ...item, checked: newChecked });
        this.changeCurrentFilterIndex(newChecked ? item.index : null);
    };

    private getItemsBySearchValue(
        searchValue: string,
        items: ICheckedFilter[],
    ): ICheckedFilter[] {
        const checker = ObjectSearch.createFieldDataChecker(searchValue);
        return !!items
            ? items.filter((item: ICheckedFilter) =>
                  ObjectSearch.searchInObjectFieldsData(
                      item.value,
                      ["title"],
                      checker,
                  ),
              )
            : [];
    }
}
