import {
    ICreateDocumentCallbacks,
    IDocumentController,
    IFinalizeUploadCallbacks,
    IMultipartUploadCallbacks,
} from "../IDocumentController";
import {
    ObjectId,
    TVoidCallback,
    FileUtils,
    IDocumentDTO,
    ObjectToUpload,
    IDocumentProgress,
    UploadType,
    MongoId,
    IActivateMultipartUploadCreateDTO,
    IActivateMultipartUploadDTO,
    IFinalizeMultipartUploadCreateDTO,
    IUploadedPart,
    IMultipartUploadData,
} from "@mrs/webclient-shared-ui-lib";
import { Broadcast } from "../../../../../infrastructure/broadcast/broadcast";
import { UploaderEvents } from "@lib/upload/UploaderEvents";
import { DocumentAttachmentUploader } from "@lib/upload/processors/document-attachment";
import { MultipartUpload } from "@lib/upload/multipartUpload/MultipartUpload";
import { IDocumentService } from "../../service/IDocumentService";
import { ConfigAccess } from "@utils/ConfigAccess";
import { StorageUtils } from "@utils/StorageUtils";
import { DocumentEvents } from "../../DocumentEvents";
import { DocumentStatus } from "@mrs/webclient-shared-ui-lib/dist/interfaces/document/DocumentStatus";
import { MultipartUploadEvents } from "@lib/upload/multipartUpload/MultipartUploadEvents";

const DEFAULT_MAX_ATTACHMENT_SIZE = 50;

export class DocumentController implements IDocumentController {
    private service: IDocumentService;

    constructor(service: IDocumentService) {
        this.service = service;
    }

    async getByIds(ids: ObjectId[]): Promise<IDocumentDTO[]> {
        return this.service.getByIds(ids);
    }

    createDocument(document: File, params: ICreateDocumentCallbacks) {
        const uploadObject = this.createUploadObjectFromFile(document);
        const documentId = uploadObject.additionalData.createDocument.id;

        Broadcast.on(
            UploaderEvents.onAdded,
            (event: any) => {
                this.onUploaderAdded(event, params.onAddedDocument);
            },
            null,
        );
        Broadcast.on(
            UploaderEvents.onDoneUpload,
            (event: any) => {
                if (event.data.id === documentId) {
                    this.onUploaderDone(event, params.onDoneUploadDocument);
                }
            },
            null,
        );
        Broadcast.on(
            UploaderEvents.onProgress,
            (event: IDocumentProgress) => {
                if (event.id === documentId) {
                    this.onProgress(event, params.onProgress);
                }
            },
            null,
        );
        Broadcast.on(
            UploaderEvents.onFail,
            (event: any) => {
                if (
                    event.data.additionalData.createDocument.id === documentId
                ) {
                    this.onFail(documentId, params.onFail);
                }
            },
            null,
        );

        this.createNewDocument(uploadObject);
    }

    createDocumentFromUrl(url: string, params: ICreateDocumentCallbacks) {
        Broadcast.on(
            UploaderEvents.onAdded,
            (event: any) => {
                this.onUploaderAdded(event, params.onAddedDocument);
            },
            null,
        );
        Broadcast.on(
            UploaderEvents.onDoneUpload,
            (event: any) => {
                if (event.data.url === url) {
                    this.onUploaderDone(event, params.onDoneUploadDocument);
                }
            },
            null,
        );
        Broadcast.on(
            UploaderEvents.onFail,
            (event: any) => {
                this.onFail(
                    event.data.additionalData.createDocument.id,
                    params.onFail,
                );
            },
            null,
        );

        const uploadObject = this.createUploadObjectFromUrl(url);
        this.createNewDocument(uploadObject);
    }

    async multipartUploadDocument(
        values: IMultipartUploadData[],
        params: IMultipartUploadCallbacks,
    ) {
        Broadcast.on(
            MultipartUploadEvents.onDoneUploadPart,
            async (event: IUploadedPart) => {
                await this.onDoneMultipartUploadPart(
                    event,
                    params.onDoneUploadPart,
                );
            },
            null,
        );
        Broadcast.on(
            MultipartUploadEvents.onProgress,
            (event: IDocumentProgress) => {
                this.onProgressMultipartUpload(event, params.onProgress);
            },
            null,
        );
        Broadcast.on(
            MultipartUploadEvents.onFail,
            (id: ObjectId) => {
                this.onFailMultipartUpload(id, params.onFail);
            },
            null,
        );

        await MultipartUpload.upload(values);
    }

    async download(url: string, fileName: string): Promise<void> {
        const blob = await this.service.download(url);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    async activateMultipartUpload(
        data: IActivateMultipartUploadCreateDTO,
    ): Promise<IActivateMultipartUploadDTO> {
        return this.service.activateMultipartUpload(data);
    }

    async finalizeMultipartUpload(
        documentId: ObjectId,
        data: IFinalizeMultipartUploadCreateDTO,
        params: IFinalizeUploadCallbacks,
    ) {
        Broadcast.on(
            DocumentEvents.onUpdated,
            async (event: IDocumentDTO) => {
                const { id, status } = event;
                if (id === documentId && status === DocumentStatus.UPLOADED) {
                    await this.onDoneMultipartUpload(
                        event,
                        params.onDoneUpload,
                    );
                }
            },
            null,
        );

        await this.service.finalizeMultipartUpload(data);
    }

    toEntity(data: any) {
        return this.service.toEntity(data);
    }

    toEntities(data: any[]) {
        return this.service.toEntities(data);
    }

    toStorageUrl(url: string): string {
        return StorageUtils.toStorageUrl(url);
    }

    maxAttachmentSize(): number {
        return (
            ConfigAccess.config.maxAttachmentSize || DEFAULT_MAX_ATTACHMENT_SIZE
        );
    }

    private onUploaderAdded = (
        params: any,
        onAddedDocument: TVoidCallback<ObjectToUpload>,
    ) => {
        Broadcast.off(UploaderEvents.onAdded, this.onUploaderAdded, null);
        onAddedDocument(params.data);
    };

    private onUploaderDone = (
        params: any,
        onDoneUploadDocument: TVoidCallback<IDocumentDTO>,
    ) => {
        Broadcast.off(UploaderEvents.onDoneUpload, this.onUploaderDone, null);
        onDoneUploadDocument({ ...params.data });
    };

    private onDoneMultipartUpload = async (
        event: IDocumentDTO,
        onDoneUpload: IFinalizeUploadCallbacks["onDoneUpload"],
    ) => {
        Broadcast.off(
            DocumentEvents.onUpdated,
            this.onDoneMultipartUpload,
            null,
        );
        await onDoneUpload(event);
    };

    private onDoneMultipartUploadPart = async (
        event: IUploadedPart,
        onDoneUploadPart: IMultipartUploadCallbacks["onDoneUploadPart"],
    ) => {
        Broadcast.off(
            MultipartUploadEvents.onDoneUploadPart,
            this.onDoneMultipartUploadPart,
            null,
        );
        await onDoneUploadPart(event);
    };

    private onProgressMultipartUpload = (
        event: IDocumentProgress,
        onProgress: IMultipartUploadCallbacks["onProgress"],
    ) => {
        Broadcast.off(
            MultipartUploadEvents.onProgress,
            this.onProgressMultipartUpload,
            null,
        );
        onProgress(event);
    };

    private onFailMultipartUpload = (
        id: ObjectId,
        onFail: IMultipartUploadCallbacks["onFail"],
    ) => {
        Broadcast.off(
            MultipartUploadEvents.onFail,
            this.onFailMultipartUpload,
            null,
        );
        onFail(id);
    };

    private onProgress = (
        event: IDocumentProgress,
        onProgressUploadDocument: TVoidCallback<IDocumentProgress>,
    ) => {
        Broadcast.off(UploaderEvents.onProgress, this.onProgress, null);
        onProgressUploadDocument(event);
    };

    private onFail = (
        id: ObjectId,
        onFailUploadDocument: TVoidCallback<ObjectId>,
    ) => {
        Broadcast.off(UploaderEvents.onFail, this.onFail, null);
        onFailUploadDocument(id);
    };

    private createNewDocument(uploadObject: ObjectToUpload) {
        DocumentAttachmentUploader.upload(uploadObject);
    }

    private createUploadObjectFromFile(file: File): ObjectToUpload {
        const data = new ObjectToUpload();
        const fileName = file.name;

        data.originalFileName = fileName;
        data.type = UploadType.DOCUMENT_SELECT;
        data.object = file;
        data.additionalData = this.getAdditionalData(fileName);
        return data;
    }

    private createUploadObjectFromUrl(url: string): ObjectToUpload {
        const data = new ObjectToUpload();
        const fileName = FileUtils.getFileName(url);

        data.originalFileName = fileName;
        data.type = UploadType.UPLOAD_FROM_URL;
        data.object = url;
        data.additionalData = this.getAdditionalData(fileName);
        return data;
    }

    private getAdditionalData(fileName: string) {
        const id = MongoId.create();
        return {
            createDocument: {
                id,
                createdDate: new Date().getTime(),
                name: fileName,
            },
        };
    }
}
