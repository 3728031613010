import { ConfigAccess } from "@utils/ConfigAccess";
import { Broadcast } from "../infrastructure/broadcast/broadcast";
import { AuthEvents } from "../core/context/auth/AuthEvents";
import { lazyInject } from "../core/AppDiContainer";
import { ApplicationDiType } from "@ui/diType";
import { IAuthService } from "../core/context/auth/service/IAuthService";

const MINUTE_IN_MILLISECONDS = 60000;
const MOUSE_EVENT_TYPES = [
    "click",
    "keydown",
    "contextmenu",
    "wheel",
    "auxclick",
];

class SessionUtilsClass {
    private timerId?: NodeJS.Timeout;
    private _sessionStart: Date;

    @lazyInject(ApplicationDiType.IAuthService)
    private readonly authService: IAuthService;

    init() {
        this._sessionStart = new Date();
        const maxInactiveSessionTime =
            ConfigAccess.config.maxInactiveSessionTime;
        if (!maxInactiveSessionTime) return;

        Broadcast.on(AuthEvents.login, this.setTimer, null);
        MOUSE_EVENT_TYPES.forEach((event) => {
            window.addEventListener(event, this.setTimer);
        });
    }

    get sessionStart(): Date {
        return this._sessionStart;
    }

    private setTimer = () => {
        if (!!this.timerId) clearTimeout(this.timerId);
        const maxInactiveSessionTime =
            ConfigAccess.config.maxInactiveSessionTime;
        this.timerId = setTimeout(() => {
            this.authService.logout();
        }, maxInactiveSessionTime * MINUTE_IN_MILLISECONDS);
    };
}

export const SessionUtils = new SessionUtilsClass();
