import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTranslation } from "react-i18next";
import { Translator } from "@translator";
import {
    DEFAULT_NAMESPACE,
    getCurrentLanguage,
    SUPPORTED_LANGUAGES,
} from "@lib/translator/i18next";
import { ScreenWrapper } from "@ui/components/shared/ScreenWrapper/ScreenWrapper";

export const SettingsScreen = () => {
    const { t } = useTranslation(DEFAULT_NAMESPACE);
    const translator = useMemo(() => Translator.getInstance(), []);
    const currentLanguage = getCurrentLanguage();

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        value: string,
    ) => {
        translator.setLanguage(value);
    };

    return (
        <ScreenWrapper>
            <Box display={"flex"} flexDirection={"column"} p={2}>
                <FormControl>
                    <FormLabel>{t("common:settings.language.title")}</FormLabel>
                    <RadioGroup value={currentLanguage} onChange={handleChange}>
                        <FormControlLabel
                            value={SUPPORTED_LANGUAGES.RU}
                            control={<Radio />}
                            label={t("common:settings.language.ru")}
                        />
                        <FormControlLabel
                            value={SUPPORTED_LANGUAGES.EN}
                            control={<Radio />}
                            label={t("common:settings.language.en")}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        </ScreenWrapper>
    );
};
