import { Registry, RJSFSchema, UiSchema } from "@rjsf/utils";
import { IFormWrapped } from "@mrs/webclient-shared-ui-lib";
import get from "lodash-es/get";
import head from "lodash-es/head";

export abstract class FormWrapped<T extends IFormWrapped> {
    protected _wrapped: T;

    protected constructor(wrapped: T) {
        this._wrapped = wrapped;
    }

    get id(): string {
        return this._wrapped.id;
    }

    get name(): string {
        return this._wrapped.name;
    }

    get value() {
        return this._wrapped.value;
    }

    get schema(): RJSFSchema {
        return this._wrapped.schema;
    }

    get uiSchema(): UiSchema | undefined {
        return this._wrapped.uiSchema;
    }

    get registry() {
        return get(this._wrapped, "registry") as Registry;
    }

    get errors(): string[] {
        return get(this._wrapped, "rawErrors", []) as string[];
    }

    get required(): boolean {
        return this._wrapped.required || false;
    }

    get autofocus(): boolean {
        return this._wrapped.autofocus || false;
    }

    get disabled(): boolean {
        return this._wrapped.disabled || false;
    }

    get readonly(): boolean {
        return this._wrapped.readonly || false;
    }

    get helperText(): string {
        const value: string = this.schema.helperText || "";
        return head(this.errors) || value;
    }

    onChange = (value: any) => {
        this._wrapped.onChange(value);
    };

    onBlur = (id: string, value: any) => {
        this._wrapped.onBlur(id, value);
    };

    onFocus = (id: string, value: any) => {
        this._wrapped.onFocus(id, value);
    };

    toPlainObject(): object {
        return {
            id: this.id,
            name: this.name,
            value: this.value,
            schema: this.schema,
            uiSchema: this.uiSchema,
            helperText: this.helperText,
            errors: this.errors,
            required: this.required,
            autofocus: this.autofocus,
            disabled: this.disabled,
            readonly: this.readonly,
            onChange: this.onChange,
            onBlur: this.onBlur,
            onFocus: this.onFocus,
        };
    }
}
