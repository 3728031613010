import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { IWithSx, TVoidCallback } from "@mrs/webclient-shared-ui-lib";

export interface ITwoActionsListItemProps<T = any> extends IWithSx {
    item: T;
    title: string;
    description?: string;
    selected?: boolean;
    disabled?: boolean;
    onClick: TVoidCallback<T>;
    onSecondActionClick?: TVoidCallback<T>;
    icon?: React.JSX.Element;
    secondIcon?: React.JSX.Element;
    TypographyProps?: TypographyProps;
}

export const TwoActionsListItem = (props: ITwoActionsListItemProps) => {
    const {
        item,
        selected,
        onClick,
        onSecondActionClick,
        sx,
        disabled,
        icon = null,
        secondIcon = null,
    } = props;

    const SecondIcon = useCallback(() => secondIcon, [secondIcon]);

    const onItemClick = useCallback(() => onClick(item), [item]);
    const onRightButtonClick = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            onSecondActionClick && onSecondActionClick(item);
        },
        [item],
    );

    return (
        <ListItem
            onClick={onItemClick}
            selected={selected}
            autoFocus={selected}
            button
            disableRipple
            disabled={disabled}
            sx={[
                {
                    display: "flex",
                    alignItems: "center",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {!!icon && (
                <Box
                    sx={{
                        display: "flex",
                        "& > svg": {
                            mr: 2,
                        },
                    }}
                >
                    {icon}
                </Box>
            )}

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 0,
                    flexGrow: 1,
                }}
            >
                <Typography variant={"body2"} {...props.TypographyProps}>
                    {props.title}
                </Typography>

                <Typography variant={"caption"} {...props.TypographyProps}>
                    {props.description}
                </Typography>
            </Box>

            {secondIcon && onSecondActionClick ? (
                <IconButton onClick={onRightButtonClick}>
                    <SecondIcon />
                </IconButton>
            ) : (
                <SecondIcon />
            )}
        </ListItem>
    );
};
