import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { ISearchViewModel } from "./search/ISearchViewModel";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { SearchWithCloseButton } from "../SearchWithCloseButton/SearchWithCloseButton";
import { EmptyList } from "@mrs/webclient-shared-ui-lib";
import { SEARCH_HEIGHT } from "@ui/theme/baseTheme/BaseTheme";

interface ISelectionListProps {
    children: React.ReactElement;
    isEmptyList?: boolean;
    searchViewModel?: ISearchViewModel;
    withSearch?: boolean;
    isMultiSelect?: boolean;
    autofocus?: boolean;
}

export const SelectionList = observer((props: ISelectionListProps) => {
    const { searchViewModel, withSearch = true, children, isEmptyList } = props;
    const { t } = useTranslation(DEFAULT_NAMESPACE);
    const emptyListHeight = useMemo(
        () => (withSearch ? `calc(100% - ${SEARCH_HEIGHT}px)` : undefined),
        [],
    );

    return (
        <Box height={"100%"}>
            {withSearch && !!searchViewModel && (
                <>
                    <SearchWithCloseButton
                        value={searchViewModel.searchValue}
                        placeholder={t("common.search")}
                        startIcon
                        autoFocus={props.autofocus}
                        onChange={searchViewModel.onChangeSearchValue}
                        sx={{ p: 2, borderRadius: 0 }}
                    />
                    <Divider />
                </>
            )}
            {!isEmptyList ? (
                <Box sx={!!props.isMultiSelect ? { mb: 0 } : {}}>
                    {children}
                </Box>
            ) : (
                <Box height={emptyListHeight}>
                    <EmptyList message={t("common.nothingFound")} />
                </Box>
            )}
        </Box>
    );
});
