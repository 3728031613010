import React, { useCallback, useState } from "react";
import { DateRangePicker, LocalizationProvider, DateRange } from "@mui/lab";
import isSameDay from "date-fns/isSameDay";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE, LOCALE } from "@lib/translator/i18next";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { MuiTextFieldProps } from "@mui/lab/internal/pickers/PureDateInput";
import get from "lodash-es/get";
import { IWithSx, TVoidCallback } from "@mrs/webclient-shared-ui-lib";

const POPPER_HEIGHT = 350;

interface IDateRangeSelectProps extends IWithSx {
    startText?: string;
    endText?: string;
    inputFormat?: string;
    mask?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    placeholder?: string;
    value: DateRange<Date>;
    onChange: TVoidCallback<DateRange<Date>>;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const DateRangeSelect = (props: IDateRangeSelectProps) => {
    const {
        startText = "",
        endText = "",
        inputFormat = "dd.MM.yyyy",
        mask = "__.__.____",
        sx,
    } = props;

    const { t, i18n } = useTranslation(DEFAULT_NAMESPACE);
    const currentLocale = get(LOCALE, i18n.language || "en");

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [startHelperText, setStartHelperText] = useState<string>("");
    const [startError, setStartError] = useState(null);

    const [finishHelperText, setFinishHelperText] = useState<string>("");
    const [finishError, setFinishError] = useState(null);

    const openSelect = useCallback(() => {
        setIsOpen(true);
        !!props.onFocus && props.onFocus();
    }, []);

    const closeSelect = useCallback(() => {
        setIsOpen(false);
        !!props.onBlur && props.onBlur();
    }, []);

    const onError = useCallback((reason: any, value: any): void => {
        checkReasonError(reason[0], value, setStartHelperText, setStartError);
        checkReasonError(reason[1], value, setFinishHelperText, setFinishError);
    }, []);

    const checkReasonError = useCallback(
        (
            reason: any,
            value: any,
            setHelperText: (value: string) => void,
            setError: (value: any) => void,
        ) => {
            switch (reason) {
                case "invalidDate":
                    setHelperText(t("datePicker.error.dateFormatError"));
                    setError(reason);
                    break;

                case "invalidRange":
                    const startDate = value[0] as Date;
                    const endDate = value[1] as Date;
                    if (isSameDay(startDate, endDate)) {
                        setError(null);
                        setHelperText("");
                        break;
                    }

                    setHelperText(t("datePicker.error.dateRangeError"));
                    setError(reason);
                    break;

                case "maxDate":
                    setHelperText(t("datePicker.error.maxDateError"));
                    setError(reason);
                    break;

                case "minDate":
                    setHelperText(t("datePicker.error.minDateError"));
                    setError(reason);
                    break;

                default:
                    setHelperText("");
                    setError(null);
                    break;
            }
        },
        [],
    );

    const renderTextFieldComponent = useCallback(
        (params: MuiTextFieldProps, helperText: string, error: any) => {
            const { label, inputProps, ...other } = params;
            return (
                <TextField
                    {...other}
                    InputProps={{
                        notched: false,
                        sx: { mt: 0 },
                    }}
                    inputProps={{
                        ...inputProps,
                        placeholder: props.placeholder || (label as string),
                    }}
                    onClick={openSelect}
                    InputLabelProps={{ shrink: true }}
                    variant={"outlined"}
                    fullWidth
                    helperText={helperText}
                    error={Boolean(error)}
                />
            );
        },
        [props.placeholder],
    );

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={currentLocale}
        >
            <DateRangePicker
                open={isOpen}
                onClose={closeSelect}
                value={props.value}
                onChange={props.onChange}
                renderInput={(
                    startProps: MuiTextFieldProps,
                    endProps: MuiTextFieldProps,
                ) => (
                    <Box
                        sx={[
                            {
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                            },
                            ...(Array.isArray(sx) ? sx : [sx]),
                        ]}
                    >
                        {renderTextFieldComponent(
                            startProps,
                            startHelperText,
                            startError,
                        )}
                        <Box sx={{ m: (theme) => theme.spacing(0, 1) }} />
                        {renderTextFieldComponent(
                            endProps,
                            finishHelperText,
                            finishError,
                        )}
                    </Box>
                )}
                startText={startText}
                endText={endText}
                mask={mask}
                inputFormat={inputFormat}
                PopperProps={{
                    disablePortal: true,
                    sx: styles.popper,
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: () => [0, 16],
                            },
                        },
                    ],
                }}
                disabled={props.disabled}
                showToolbar={false}
                onError={onError}
            />
        </LocalizationProvider>
    );
};

const styles = {
    popper: {
        height: POPPER_HEIGHT,
        "& > .MuiPaper-root": {
            height: "inherit",
            boxShadow: "unset",
            transition: "none !important",
            transformOrigin: "unset",
            border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
            "& > div:first-of-type": {
                height: "inherit",
                "& > div": {
                    height: "inherit",
                },
            },
        },
    },
};
