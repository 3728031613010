import React from "react";
import { useParams } from "react-router-dom";
import { PageViewManager } from "@ui/components/managers/PageViewManager/PageViewManager";

export const ViewScreen = () => {
    const params = useParams();
    return params.viewName ? (
        <PageViewManager
            viewName={params.viewName}
            viewItemId={params.viewItemId}
        />
    ) : null;
};
