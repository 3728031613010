import { ITab } from "./TypeDefinition";

export enum BaseSchemaType {
    String = "string",
    Number = "number",
    Array = "array",
    Boolean = "boolean",
    Object = "object",
}

export enum CUSTOM_JSON_FORM_TYPE {
    OBJECT = "object",
    TEXT = "text",
    NUMBER = "number",
    DATE = "date",
    DATETIME = "datetime",
    CHECKBOXES = "checkboxes",
    RADIO = "radio",
    EMAIL = "email",
    TEXTAREA = "textarea",
    ARRAY = "array",
    SELECT = "select",
    BUTTON_SWITCHER = "buttonSwitcher",
}

export interface ICustomJsonForm {
    properties: TProperties;
    required: string[];
    order: string[];
    tabs: ITab[];
    title?: string;
}

export type TProperties = Record<string, IProperty>;

export interface IProperty {
    type: CUSTOM_JSON_FORM_TYPE;
    title: string;
    description: string;
    disabled?: boolean;
    enum: string[] | number[];
    enumNames: string[];
    minItems: number;
    maxItems: number;
    minLength: number;
    maxLength: number;
    items: {
        enum: string[] | number[];
        enumNames: string[];
        type: CUSTOM_JSON_FORM_TYPE;
    };
    readOnly?: boolean;
    hidden?: boolean;
    fullScreen?: boolean;
    properties?: TProperties;
}
