import { ObjectType } from "../../../../ObjectType";
import { AbstractApplicationService } from "../../../../sharedKernel/service/impl/AbstractApplicationService";
import { IFormInstanceRepository } from "../../repository/IFormInstanceRepository";
import { IFormInstanceService } from "../IFormInstanceService";
import { injectable } from "inversify";
import { FormInstance } from "../../model/FormInstance";
import { IFormInstanceCreateDTO } from "../../dto/IFormInstanceCreateDTO";
import { IFormInstanceDTO } from "../../dto/IFormInstanceDTO";
import { Changes } from "../../../../sharedKernel/types/Changes";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";

@injectable()
export class FormInstanceService
    extends AbstractApplicationService<
        FormInstance,
        IFormInstanceDTO,
        IFormInstanceCreateDTO,
        IFormInstanceRepository
    >
    implements IFormInstanceService {
    constructor(repository: IFormInstanceRepository) {
        super(repository, ObjectType.FORM_INSTANCE);
    }

    createEntity(
        dto: Partial<IFormInstanceDTO>,
        initData: object | null,
    ): FormInstance {
        return this.repository.createEntity(dto, initData);
    }

    async createFormInstance(entity: FormInstance): Promise<FormInstance> {
        return this.repository.createFormInstance(entity);
    }

    updateFormInstance(
        entity: FormInstance,
        changes: Changes<FormInstance>,
    ): Promise<FormInstance> {
        return this.repository.updateFormInstance(entity, changes);
    }

    async changeFormInstance(
        id: ObjectId,
        changes: object,
    ): Promise<FormInstance | undefined> {
        return this.repository.changeFormInstance(id, changes);
    }
}
