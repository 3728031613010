import React from "react";
import "reflect-metadata";
import { BrowserRouter } from "react-router-dom";
import { Main } from "./Main";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BaseTheme } from "./theme/baseTheme/BaseTheme";
import { PlasmicRootProvider } from "@plasmicapp/loader-react";
import { PlasmicService } from "../../plasmic/PlasmicService";

export const AppUI = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={BaseTheme}>
                <PlasmicRootProvider loader={PlasmicService.init()}>
                    <BrowserRouter>
                        <Main />
                    </BrowserRouter>
                </PlasmicRootProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
