import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { JSONSchema7Type } from "json-schema";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";

interface IButtonSwitcherWidgetProps extends IFormWidgetWrapped {}

export const ButtonSwitcherWidget = observer(
    (props: IButtonSwitcherWidgetProps) => {
        const { schema, value, onChange } = props;

        const enums = schema.enum || [];
        const enumLabels = (schema as any).enumNames || [];
        const hidden = useMemo(() => schema?.hidden || false, [schema]);

        return !hidden ? (
            <>
                {schema.title && (
                    <Typography variant={"body1"}>{schema.title}</Typography>
                )}
                <Box sx={styles.buttonContainer}>
                    {!!enums.length &&
                        enums.map((item: JSONSchema7Type, index: number) => {
                            const isSelected = item === value;
                            return (
                                <Button
                                    key={`button-${index}`}
                                    onClick={() => onChange(String(item))}
                                    variant={
                                        isSelected ? "outlined" : undefined
                                    }
                                    color={"inherit"}
                                    fullWidth
                                    disableRipple
                                    sx={[
                                        styles.button,
                                        ...(isSelected
                                            ? [styles.buttonSelected]
                                            : []),
                                    ]}
                                >
                                    {enumLabels[index]}
                                </Button>
                            );
                        })}
                </Box>
            </>
        ) : (
            <></>
        );
    },
);

const styles = {
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        m: (theme: Theme) => theme.spacing(0, -2),
        p: (theme: Theme) => theme.spacing(0, 2),
        flexWrap: "nowrap",
        overflowX: "auto",
        scrollBehavior: "smooth",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    button: {
        width: "auto",
        minWidth: "auto",
        mr: 1,
        p: (theme: Theme) => theme.spacing(1, 2),
        whiteSpace: "nowrap",
        fontSize: 16,
        border: "1px solid transparent",
    },
    buttonSelected: {
        backgroundColor: "background.paper",
        color: "text.primary",
        border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    },
};
