import React from "react";
import { SectionItem } from "../../../../shared/SectionItem/SectionItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TwoActionsListItem } from "../../../../shared/TwoActionsListItem/TwoActionsListItem";
import {
    FluentUiIconName,
    Icon,
    IconByValue,
    MRSIcon,
    TVoidCallback,
    VIEW_FILTER_TYPE,
    ICheckedFilter,
} from "@mrs/webclient-shared-ui-lib";

export interface IFilterDialogItemProps {
    item: ICheckedFilter;
    isSelected: boolean;
    onClick: TVoidCallback<ICheckedFilter>;
}

export const FilterDialogItem = (props: IFilterDialogItemProps) => {
    const { item, isSelected, onClick } = props;
    const { value } = item;

    return value.type === VIEW_FILTER_TYPE.SWITCH ? (
        <SectionItem
            id={value.type}
            sectionItemElement={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <IconByValue
                        value={value.icon as MRSIcon}
                        sx={{
                            mr: 2,
                            color: "text.secondary",
                        }}
                    />
                    <Typography variant={"body2"}>{value.title}</Typography>
                </Box>
            }
            checked={item.checked}
            isSelected={isSelected}
            onClick={() => onClick(item)}
            withCheckbox={true}
            sx={{
                p: (theme) => theme.spacing(0.75, 1, 0.75, 2),
                backgroundColor: "background.paper",
            }}
        />
    ) : (
        <TwoActionsListItem
            item={item}
            title={value.title}
            icon={
                <IconByValue
                    value={value.icon as MRSIcon}
                    sx={{
                        color: "text.secondary",
                    }}
                />
            }
            secondIcon={
                <Icon
                    iconName={FluentUiIconName.ChevronRightRegular}
                    sx={{
                        color: "text.secondary",
                    }}
                />
            }
            onClick={() => onClick(item)}
            selected={isSelected}
            sx={{
                justifyContent: "space-between",
                backgroundColor: "background.paper",
                p: (theme) => theme.spacing(1.75, 2),
            }}
        />
    );
};
