import {
    ArrayUtils,
    IMultipartUploadData,
    IMultipartUploadPartDTO,
    IUploadedPart,
    IWithId,
    ObjectId,
} from "@mrs/webclient-shared-ui-lib";
import head from "lodash-es/head";
import cloneDeep from "lodash-es/cloneDeep";
import { Broadcast } from "../../../app/infrastructure/broadcast/broadcast";
import { MultipartUploadEvents } from "./MultipartUploadEvents";

export interface IMultipartUploadPart
    extends IWithId,
        IMultipartUploadPartDTO {}

const MAX_THREADS_COUNT = 5;
const MAX_REQUEST_ATTEMPT = 3;

export class MultipartUploadClass {
    private _threadsCount: number = 0;
    private _uploadParts: IMultipartUploadPart[] = [];
    private _errorParts: IMultipartUploadPart[] = [];
    private _multipartUploads: IMultipartUploadData[] = [];
    private _allProgress: Record<ObjectId, object> = {};

    upload = async (uploads: IMultipartUploadData[]) => {
        this._multipartUploads.push(...uploads);

        for (const data of uploads) {
            const { documentId, parts } = data;
            parts.forEach((part) => {
                this._uploadParts.push({ id: documentId, ...part });
            });
        }

        await this.uploadNextChunk();
    };

    private uploadNextChunk = async () => {
        if (this._threadsCount >= MAX_THREADS_COUNT) return;

        let part: IMultipartUploadPart | undefined;

        if (!!this._errorParts.length) {
            part = head(this._errorParts);
        } else {
            part = this._uploadParts.shift();
        }

        if (!part) return;

        const { id, partNumber } = part;

        const uploadingFile = this.getUploadingFileById(id);

        if (!!uploadingFile) {
            const { file, maxPartSize } = uploadingFile;
            const partSize = (partNumber - 1) * maxPartSize;
            const chunk = file.slice(partSize, partSize + maxPartSize);

            try {
                await this.uploadChunk(chunk, part);
            } catch (e) {
                this.onFail(part);
            } finally {
                await this.uploadNextChunk();
            }
        }
    };

    private uploadChunk = (chunk: Blob, part: IMultipartUploadPart) => {
        const { id, url, partNumber } = part;
        return new Promise(async (resolve, reject) => {
            const httpRequest = new XMLHttpRequest();
            this._threadsCount += 1;

            await this.uploadNextChunk();

            httpRequest.open("PUT", url, true);

            httpRequest.upload.onprogress = (event: ProgressEvent) => {
                this.onProgress(part, event.loaded);
            };

            httpRequest.onreadystatechange = (event: Event) => {
                if (httpRequest.readyState !== 4) return;
                this._threadsCount -= 1;

                if (httpRequest.status === 200) {
                    const etag = this.getEtagHeader(httpRequest);
                    this.onDoneUploadPart({ etag, id, partNumber });

                    const errorPart = this._errorParts.find(
                        this.isEqualToPart(part),
                    );
                    ArrayUtils.removeItem(this._errorParts, errorPart);

                    resolve(event);
                } else {
                    reject(event);
                }
            };

            httpRequest.send(chunk);
        });
    };

    private getEtagHeader(httpRequest: XMLHttpRequest) {
        const etag = httpRequest.getResponseHeader("etag") || "";
        return etag.replace(/"/g, "");
    }

    private onDoneUploadPart = (data: IUploadedPart) => {
        Broadcast.trig(MultipartUploadEvents.onDoneUploadPart, data);
    };

    private onProgress = (part: IMultipartUploadPart, progressDone: number) => {
        const { id, partNumber } = part;

        const allProgress = cloneDeep(this._allProgress[id]) || {};
        this._allProgress[id] = { ...allProgress, [partNumber]: progressDone };

        const progress: number[] = Object.values(this._allProgress[id]);
        const done = progress.reduce((result, value) => result + value, 0);

        Broadcast.trig(MultipartUploadEvents.onProgress, {
            id,
            progressDone: done,
        });
    };

    private onFail = (part: IMultipartUploadPart) => {
        const { id } = part;

        const uploadingFile = this.getUploadingFileById(id);
        if (!uploadingFile) return;

        this._errorParts.push(part);
        const errors = this._errorParts.filter(this.isEqualToPart(part));

        if (errors.length >= MAX_REQUEST_ATTEMPT) {
            this.clear(id);
            Broadcast.trig(MultipartUploadEvents.onFail, id);
        }
    };

    private clear = (id: ObjectId) => {
        const uploadingFile = this.getUploadingFileById(id);
        ArrayUtils.removeItem(this._multipartUploads, uploadingFile);

        this._errorParts = ArrayUtils.removeAllItemsById(this._errorParts, id);
        this._uploadParts = ArrayUtils.removeAllItemsById(
            this._uploadParts,
            id,
        );

        delete this._allProgress[id];
    };

    private getUploadingFileById = (id: ObjectId) => {
        return this._multipartUploads.find((value) => value.documentId === id);
    };

    private isEqualToPart = (part: IMultipartUploadPart) => {
        const { id, partNumber } = part;
        return (value: IMultipartUploadPart) =>
            value.id === id && value.partNumber === partNumber;
    };
}

export const MultipartUpload = new MultipartUploadClass();
