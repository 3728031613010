import { IQueryService } from "./IQueryService";
import { injectable } from "inversify";
import { IWithId, TQueryParams } from "@mrs/webclient-shared-ui-lib";
import { IRestService } from "../http/IRestService";

@injectable()
export class QueryService<T extends IWithId> implements IQueryService<T> {
    private readonly restService: IRestService;

    constructor(restService: IRestService) {
        this.restService = restService;
    }

    async getViewItems(
        viewName: string,
        queryParams: TQueryParams,
        queryName: string = "default",
    ): Promise<T[]> {
        return this.restService
            .get(`query/execute?id=${viewName}&queryName=${queryName}`, {
                queryParams,
            })
            .asPromise();
    }
}
