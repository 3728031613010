import React from "react";
import { TProperty } from "@mrs/webclient-shared-ui-lib";
import { Field, FieldProps, Widget, WidgetProps } from "@rjsf/utils";
import { BaseSchemaType } from "../../../../core/context/form/dto/IFormDTO";

export enum CustomFormUIKey {
    Field = "ui:field",
    Widget = "ui:widget",
}

type OptionalCustomFormUIKey = {
    [T in CustomFormUIKey]?: string;
};

export interface ICustomForm<T extends FieldProps | WidgetProps = any> {
    type?: BaseSchemaType;
    fieldName: string;
    script?: string;
    component?: (props: T) => React.JSX.Element;
    options?: object;
}

export interface ICustomFormData extends TProperty<ICustomForm> {}

export interface ICustomFormDataWithUIKey
    extends TProperty<ICustomForm & OptionalCustomFormUIKey> {}

export interface ICustomFormFields extends TProperty<Field> {}

export interface ICustomFormWidgets extends TProperty<Widget> {}
