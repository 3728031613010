import {
    CUSTOM_JSON_FORM_TYPE,
    ICustomJsonForm,
    IProperty,
    TProperties,
} from "../dto/IFormDTO";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-webclient";
import { Identifiable } from "src/app/core/sharedKernel/model/Identifiable";
import { ITab, TabType } from "../dto/TypeDefinition";

export class Form extends Identifiable {
    private readonly _title: string;
    private readonly _code: string;
    private readonly _scheme: ICustomJsonForm;
    private readonly _tags: ObjectId[];
    constructor(dto: dto.form.FormDTO) {
        super(dto);
        this._title = dto.title || "";
        this._code = dto.code || "";
        this._tags = dto.tags;
        this._scheme = this.toSchema(dto.scheme);
    }

    get title(): string {
        return this._title;
    }

    get code(): string {
        return this._code;
    }

    get scheme(): ICustomJsonForm {
        return this._scheme;
    }

    get tags(): ObjectId[] {
        return this._tags;
    }

    private toSchema(data: dto.form.Scheme): ICustomJsonForm {
        return {
            required: data.required || [],
            order: data.order || [],
            tabs: data.tabs?.map(this.toTab) || [],
            title: data.title || "",
            properties:
                data.properties?.reduce(
                    (acc: TProperties, curr: dto.form.property.Property) =>
                        this.reducer(acc, curr),
                    {},
                ) || {},
        };
    }

    private reducer(
        acc: TProperties,
        curr: dto.form.property.Property,
    ): TProperties {
        const { name, ...other } = curr;
        const newValue = Object.assign({}, other) as IProperty;
        if (
            newValue.type === CUSTOM_JSON_FORM_TYPE.OBJECT &&
            newValue.properties
        ) {
            newValue.properties = (curr as dto.form.property.ObjectProperty).properties.reduce(
                this.reducer,
                {},
            );
        }
        acc[name] = newValue;
        return acc;
    }

    private toTab(tab: dto.form.tabs.Tab): ITab {
        return {
            ...tab,
            name: tab.name,
            title: tab.title,
            type: (tab.type as unknown) as TabType,
            hidden: tab.hidden || false,
            disabled: tab.disabled || false,
        };
    }
}
