import { IWithName } from "@mrs/webclient-shared-ui-lib";

export class Identifiable implements IWithName {
    private readonly _name!: string;

    protected constructor(dto: IWithName) {
        this._name = dto.name;
    }

    get name(): string {
        return this._name;
    }
}
