import { IRestService } from "../../../../infrastructure/http/IRestService";
import { Routes } from "@ui/Routes";
import { IAuthService, IResponseToken } from "./IAuthService";
import { ConfigAccess } from "@utils/ConfigAccess";
import { IKeycloakUserDTO } from "../../user/dto/IKeycloakUserDTO";
import { appStorage } from "../../../../infrastructure/storage/AppStorage";
import { CurrentUser } from "../../user/currentUser/CurrentUser";
import { injectable } from "inversify";
import { ConfigurationAccess } from "../../configuration/ConfigurationAccess";

export const ACCESS_TOKEN = "access-token";
export const REFRESH_TOKEN = "refresh-token";

@injectable()
export class AuthService implements IAuthService {
    private readonly restService: IRestService;

    constructor(restService: IRestService) {
        this.restService = restService;
    }

    login() {
        const keycloakLink = `${this.oauth.authorizationUrl}?response_type=code&client_id=${this.oauth.clientId}&redirect_uri=${this.baseRedirectUrl}&registration_id=keycloak`;
        window.location.replace(keycloakLink);
    }

    logout() {
        const keycloakLink = `${this.oauth.logoutUrl}?redirect_uri=${window.location.origin}`;
        appStorage.removeItem(ACCESS_TOKEN);
        appStorage.removeItem(REFRESH_TOKEN);
        CurrentUser.dropUser();
        window.location.replace(keycloakLink);
        ConfigurationAccess.clean();
    }

    async getToken(code: string): Promise<IResponseToken> {
        const data = new URLSearchParams({
            code,
            grant_type: "authorization_code",
            client_id: this.oauth.clientId,
            client_secret: this.oauth.clientSecret,
            redirect_uri: this.baseRedirectUrl,
        });
        const token: IResponseToken = await this.restService
            .post(this.oauth.tokenUri, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data,
                withCredentials: true,
            })
            .asPromise();
        appStorage.setItem(ACCESS_TOKEN, token.access_token);
        appStorage.setItem(REFRESH_TOKEN, token.refresh_token);
        return token;
    }

    async updateToken(): Promise<void> {
        const data = new URLSearchParams({
            grant_type: "refresh_token",
            client_id: this.oauth.clientId,
            client_secret: this.oauth.clientSecret,
            refresh_token: appStorage.getItem(REFRESH_TOKEN) || "",
        });
        const token: IResponseToken = await this.restService
            .post(this.oauth.refreshUrl, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data,
                withCredentials: true,
            })
            .asPromise();
        appStorage.setItem(ACCESS_TOKEN, token.access_token);
        appStorage.setItem(REFRESH_TOKEN, token.refresh_token);
        CurrentUser.updateToken(token.access_token);
    }

    async getUserInfo(): Promise<IKeycloakUserDTO> {
        const user = await this.restService
            .get(this.oauth.userInfoUri, {
                withCredentials: true,
            })
            .asPromise();
        return this.toKeycloakUserInfo(user);
    }

    private get baseRedirectUrl() {
        return window.location.origin + Routes.SUCCESS_LOGIN;
    }

    private get oauth() {
        return ConfigAccess.config.oauth;
    }

    private toKeycloakUserInfo(dto: any): IKeycloakUserDTO {
        return {
            id: dto.sub,
            email: dto.email,
            emailVerified: dto.email_verified,
            surname: dto.family_name,
            name: dto.given_name,
            fullName: dto.name,
            userName: dto.preferred_username,
        };
    }
}
