import get from "lodash-es/get";

class QueryBuilderClass {
    addParams(baseUrl?: string, params?: object): string {
        params = params || {};

        let ret = baseUrl || "";
        let isFirstParam = baseUrl?.indexOf("?") === -1;
        Object.keys(params).forEach((paramName) => {
            const paramValue = get(params, paramName);
            if (!this._isValidValue(paramValue)) return;

            const prefix = isFirstParam ? "?" : "&";
            ret += `${prefix}${paramName}=${paramValue}`;
            isFirstParam = false;
        });
        return ret;
    }

    private _isValidValue(val: any): boolean {
        return val !== undefined && val !== null && val !== "";
    }
}

export const QueryBuilder = new QueryBuilderClass();
