import React from "react";
import { observer } from "mobx-react";
import { IRemoteModule } from "./LoadRemoteModule";

interface IRemoteComponentProps {
    module: IRemoteModule;
    [props: string]: any;
}

export const RemoteComponent = observer((props: IRemoteComponentProps) => {
    const { module, ...other } = props;

    return <React.Fragment children={module?.default(other)} />;
});
