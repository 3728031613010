import axiosRetry from "axios-retry";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { RequestUtils } from "@utils/request/RequestUtils";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import {
    IRequestParams,
    IRestService,
    IUploadRequestParams,
    IWithDataRequestParams,
    TErrorResponse,
    TRequestMethod,
} from "./IRestService";
import { HttpRequest } from "./model/HttpRequest";
import { injectable } from "inversify";
import { ConfigAccess } from "@utils/ConfigAccess";
import { useInject } from "../../core/AppDiContainer";
import { IAuthService } from "../../core/context/auth/service/IAuthService";
import { ApplicationDiType } from "@ui/diType";
import { QueryBuilder } from "./QueryBuilder";
import { Broadcast } from "../broadcast/broadcast";
import { ApplicationEvents } from "../../core/ApplicationEvents";

axiosRetry(axios, { retries: 10, retryDelay: () => 500 }); // 500ms between retry

export const NO_CONNECTION_CODE = 1000;

@injectable()
export class RestService<T> implements IRestService<T> {
    get(path: string, params: IRequestParams = {}): HttpRequest<T> {
        const axiosConfig = this.getRequestConfig(
            { url: path, method: "get" },
            params,
        );

        return this.cancelableRequest(axiosConfig);
    }

    post(path: string, params: IWithDataRequestParams<T>): HttpRequest<T> {
        const axiosConfig = this.getRequestConfig(
            { url: path, method: "post" },
            params,
        );

        return this.cancelableRequest(axiosConfig);
    }

    patch(path: string, params: IWithDataRequestParams<T>): HttpRequest<T> {
        const axiosConfig = this.getRequestConfig(
            { url: path, method: "patch" },
            params,
        );

        return this.cancelableRequest(axiosConfig);
    }

    put(path: string, params: IWithDataRequestParams<T>): HttpRequest<T> {
        const axiosConfig = this.getRequestConfig(
            { url: path, method: "put" },
            params,
        );
        this.addHeaders(axiosConfig, {
            "Content-Type": "application/json; charset=UTF-8",
        });

        return this.cancelableRequest(axiosConfig);
    }

    upload(
        path: string,
        params: IUploadRequestParams<T>,
        method: TRequestMethod = "post",
    ): HttpRequest<T> {
        const { data, additionalData, ...others } = params;
        const formData = this.getUploadFormData(additionalData);
        formData.append("file", data);

        const axiosConfig = this.getRequestConfig(
            { url: path, method, data: formData },
            others,
        );

        this.addHeaders(axiosConfig, { "Content-Type": "multipart/form-data" });

        return this.cancelableRequest(axiosConfig);
    }

    download(path: string, params?: IRequestParams): HttpRequest<Blob> {
        return this.cancelableRequest({
            url: path,
            method: "get",
            responseType: "blob",
            ...params,
        });
    }

    uploadFromUrl(
        path: string,
        params: IUploadRequestParams<T>,
        method: TRequestMethod = "post",
    ): HttpRequest<T> {
        const { data, additionalData, ...others } = params;
        const formData = this.getUploadFormData(additionalData);
        formData.append("fileUrl", data);

        const axiosConfig = this.getRequestConfig(
            { url: path, method, data: formData },
            others,
        );

        return this.cancelableRequest(axiosConfig);
    }

    uploadBase64(
        path: string,
        params: IUploadRequestParams<T>,
    ): HttpRequest<T> {
        const { data, ...others } = params;
        const axiosConfig = this.getRequestConfig(
            { url: path, method: "put", data: { file: data } },
            others,
        );
        return this.cancelableRequest(axiosConfig);
    }

    delete(
        path: string,
        params: IWithDataRequestParams,
    ): HttpRequest<ObjectId[]> {
        const axiosConfig = this.getRequestConfig(
            { url: path, method: "delete" },
            params,
        );

        return this.cancelableRequest<ObjectId[]>(axiosConfig);
    }

    protected getRequestConfig(
        config: AxiosRequestConfig,
        settings:
            | IRequestParams
            | IWithDataRequestParams<T>
            | IUploadRequestParams<T>,
    ) {
        const { queryParams, cancelRequest, headers, ...others } = settings;

        config.url = queryParams
            ? QueryBuilder.addParams(config.url, queryParams)
            : config.url;
        config.headers = {
            ...RequestUtils.getHeaders(),
            ...config.headers,
            ...headers,
        };
        config.baseURL =
            ConfigAccess.config?.server?.additionalServiceUrl || "";

        return { ...config, ...others };
    }

    private cancelableRequest<ResponseType = T>(
        params: AxiosRequestConfig,
    ): HttpRequest<ResponseType> {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        params.cancelToken = source.token;

        const axiosPromise = new Promise<ResponseType>((resolve, reject) => {
            axios
                .request(params)
                .then((result) => {
                    const response = result.data;
                    if (this.isErrorResponse(response)) {
                        return reject((response as unknown) as TErrorResponse);
                    }
                    resolve(response);
                })
                .catch((reason: AxiosError) => {
                    if (reason.request && !reason.response) {
                        // No connection
                        // timeout
                        // CORS error
                        Broadcast.trig(ApplicationEvents.noInternet, null);
                        return reject({
                            code: NO_CONNECTION_CODE,
                            message: reason.message,
                        } as TErrorResponse);
                    }
                    // TODO добавить обработку ошибок

                    reject((reason.response as unknown) as TErrorResponse);
                });
        });

        return new HttpRequest<ResponseType>(axiosPromise, source.cancel);
    }

    private addHeaders(axiosConfig: AxiosRequestConfig, headersObject: {}) {
        const currentHeaders = axiosConfig.headers || {};
        axiosConfig.headers = { ...headersObject, ...currentHeaders };
    }

    private isErrorResponse(response: TErrorResponse): boolean {
        return response.error || (!!response?.code && !!response.message);
    }

    private getUploadFormData(additionalData: any) {
        const formData = new FormData();
        const values = additionalData || {};
        for (const [key, value] of Object.entries(values)) {
            formData.append(key, JSON.stringify(value));
        }
        return formData;
    }
}

axios.interceptors.response.use(
    (axiosResponse) => axiosResponse,
    async (error) => {
        const authService = useInject<IAuthService>(
            ApplicationDiType.IAuthService,
        );
        if (error?.response && RequestUtils.isTokenError(error?.response)) {
            await authService.updateToken();
            const config = error?.config;
            config.headers = {
                ...config.headers,
                ...RequestUtils.getHeaders(),
            };
            return axios.request(config);
        }
        if (
            error?.response &&
            RequestUtils.isRefreshTokenError(error?.response)
        ) {
            return authService.logout();
        }

        throw error;
    },
);
