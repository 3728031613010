import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Routes as ROUTES } from "../../../Routes";
import { LoginScreen } from "@ui/screens/LoginScreen/LoginScreen";
import { SuccessLoginScreen } from "@ui/screens/SuccessLoginScreen/SuccessLoginScreen";
import { useEffect } from "react";
import { PlasmicService } from "../../../../../plasmic/PlasmicService";
import { PlasmicComponent } from "../../../../../plasmic/PlasmicComponent";

interface IAuthRoute {
    id: string;
    urlTemplate: string;
    component: React.ReactNode;
}

const authRoutes: IAuthRoute[] = [
    {
        id: "login",
        urlTemplate: ROUTES.LOGIN,
        component: <LoginScreen />,
    },
    {
        id: "successLogin",
        urlTemplate: ROUTES.SUCCESS_LOGIN,
        component: <SuccessLoginScreen />,
    },
    {
        id: "plasmic",
        urlTemplate: ROUTES.PLASMIC,
        component: <PlasmicComponent />,
    },
    {
        id: "empty",
        urlTemplate: "/",
        component: <Navigate to={ROUTES.LOGIN} replace />,
    },
    {
        id: "other",
        urlTemplate: "*",
        component: <Navigate to={ROUTES.LOGIN} replace />,
    },
];

export const AuthRoutes = () => {
    useEffect(() => {
        PlasmicService.registerComponents();
    }, []);

    return (
        <Routes>
            {authRoutes.map((route) => (
                <Route
                    key={route.id}
                    path={route.urlTemplate}
                    element={route.component}
                />
            ))}
        </Routes>
    );
};
