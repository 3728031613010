import React, { useMemo } from "react";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";
import TextField from "@mui/material/TextField";
import { useTextWidget } from "@ui/components/shared/CustomForm/CustomFormWidget/hooks/useTextWidget";

const DEFAULT_ROWS = 5;

interface ITextareaWidgetProps extends IFormWidgetWrapped {}

export const TextareaWidget = (props: ITextareaWidgetProps) => {
    const {
        id,
        required,
        disabled,
        autofocus,
        label,
        schema,
        errors = [],
        helperText,
    } = props;

    const { value, onChange, onBlur, onFocus } = useTextWidget(props);

    const rowsMin = useMemo(() => (schema as any).rowsMin || DEFAULT_ROWS, [
        schema,
    ]);

    return !schema?.hidden ? (
        <TextField
            id={id}
            label={label || schema.title}
            placeholder={schema.description}
            disabled={disabled}
            value={value}
            required={required}
            autoFocus={autofocus}
            multiline={true}
            minRows={rowsMin}
            maxRows={(schema as any).rowsMax || rowsMin}
            error={errors.length > 0}
            helperText={helperText}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            fullWidth
            InputProps={{ notched: false }}
            InputLabelProps={{ shrink: true }}
            variant={"outlined"}
        />
    ) : (
        <></>
    );
};
