type TChecker = Pick<RegExp, "test" | "lastIndex">;

export const ObjectSearch = {
    createFieldDataChecker: createFieldDataChecker,

    // the 3-rd param can be either fieldDataChecker object or string to search
    searchInObjectFieldsData: function(
        obj: any,
        fieldsArr: string[],
        searchValue: string | TChecker,
    ): boolean {
        const fieldDataChecker =
            typeof searchValue === "string"
                ? createFieldDataChecker(searchValue)
                : searchValue;

        return searchObjectByFieldChecker(obj, fieldsArr, fieldDataChecker);
    },
};

const AlWAYS_TRUE_CHECKER: TChecker = {
    lastIndex: 0,
    test: (val: string) => true,
};

function createFieldDataChecker(checkValue: string): TChecker {
    var tester = createRegExpChecker(checkValue);
    return {
        test: (toTest: string) => {
            tester.lastIndex = 0;
            return tester.test(toTest);
        },
    } as TChecker;
}

function createRegExpChecker(checkValue: string): TChecker {
    let tester: TChecker = AlWAYS_TRUE_CHECKER;
    if (checkValue) {
        try {
            const escapedValue: string = escapeForRegExp(checkValue);
            tester = new RegExp(escapedValue, "ig");
        } catch (e) {
            tester = AlWAYS_TRUE_CHECKER;
        }
    }
    return tester;
}

function escapeForRegExp(str: string) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function searchObjectByFieldChecker(
    obj: any,
    fieldsArr: string[],
    fieldDataChecker: TChecker,
): boolean {
    fieldsArr = fieldsArr || [];

    for (var i = 0, l = fieldsArr.length; i < l; i++) {
        var field = fieldsArr[i];
        var fieldData = obj[field] || "";
        var isTestPassed = fieldDataChecker.test(fieldData);
        if (isTestPassed) {
            return true;
        }
    }
    return false;
}
