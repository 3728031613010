import { IPostController } from "../IPostController";
import {
    IPostDTO,
    ObjectId,
    TQueryParams,
    TVoidCallback,
    IPostCreateDTO,
} from "@mrs/webclient-shared-ui-lib";
import { Changes } from "../../../../sharedKernel/types/Changes";
import { IPostService } from "../../service/IPostService";
import { Broadcast } from "../../../../../infrastructure/broadcast/broadcast";
import { PostEvents } from "../../PostEvents";

export class PostController implements IPostController {
    private service: IPostService;
    constructor(service: IPostService) {
        this.service = service;
    }

    async getPosts(params: TQueryParams): Promise<IPostDTO[]> {
        return this.service.getPosts(params);
    }

    async getPostById(id: ObjectId): Promise<IPostDTO> {
        return this.service.getPostById(id);
    }

    async createPost(post: IPostCreateDTO): Promise<IPostDTO> {
        return this.service.createPost(post);
    }

    async updatePost(
        entity: IPostDTO,
        changes: Changes<IPostDTO>,
    ): Promise<IPostDTO> {
        return this.service.updatePost(entity, changes);
    }

    async remove(ids: ObjectId[]): Promise<boolean> {
        return this.service.remove(ids);
    }

    initEvents = (
        onCreate: TVoidCallback<any>,
        onUpdate: TVoidCallback<any>,
        onRemove: TVoidCallback<any>,
    ) => {
        Broadcast.on(PostEvents.onCreated, onCreate, null);
        Broadcast.on(PostEvents.onUpdated, onUpdate, null);
        Broadcast.on(PostEvents.onRemove, onRemove, null);
    };
}
