import { BaseApiService } from "../../../../sharedKernel/apiService/impl/BaseApiService";
import { IPostApiService } from "../IPostApiService";
import { IRestService } from "../../../../../infrastructure/http/IRestService";
import { IPostDTO, IPostCreateDTO } from "@mrs/webclient-shared-ui-lib";

export class PostApiService extends BaseApiService<IPostDTO, IPostCreateDTO>
    implements IPostApiService {
    constructor(service: IRestService, resourceName: string) {
        super(service, resourceName);
    }
}
