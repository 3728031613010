import { FileAttachClass } from "./base-file-attachment";

export const DOCUMENT_RESOURCE_NAME = "document";

export class DocumentAttachClass extends FileAttachClass {
    constructor() {
        super(DOCUMENT_RESOURCE_NAME);
    }
}

export const DocumentAttachmentUploader = new DocumentAttachClass();
