import { ObjectType } from "../../../../core/ObjectType";
import { FormInstanceDiType } from "../../../../core/context/formInstance/diType";

export const itemDialogPresenterByType: Record<string, string> = {
    [ObjectType.FORM_INSTANCE]: FormInstanceDiType.FormInstanceDialogPresenter,
};

export const addDialogPresenterByType: Record<string, string> = {
    [ObjectType.FORM_INSTANCE]:
        FormInstanceDiType.AddFormInstanceDialogPresenter,
};
