import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { PlasmicCanvasHost } from "@plasmicapp/loader-react";
import { PlasmicTheme } from "./theme/PlasmicTheme";

export const PlasmicComponent = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={PlasmicTheme}>
                <PlasmicCanvasHost />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
