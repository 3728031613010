import React from "react";
import { createRoot } from "react-dom/client";
import { ApplicationModule } from "./module";
import { AppUI } from "./AppUI";
import "../global.scss";
import { DocumentContext } from "../core/context/document/DocumentContext";
import { FormInstanceContext } from "../core/context/formInstance/FormInstanceContext";
import { Broadcast } from "../infrastructure/broadcast/broadcast";
import { WebsocketEvents } from "../infrastructure/broadcast/WebSocketEvents";
import { EventsBroker } from "../core/context/events/EventsBroker";
import { RestService } from "../infrastructure/http/RestService";
import { PostContext } from "../core/context/post/PostContext";
import { ReportContext } from "../core/context/report/ReportContext";

const applicationModule = new ApplicationModule();
const documentContext = new DocumentContext();
const formInstanceContext = new FormInstanceContext();
const postContext = new PostContext();
const reportContext = new ReportContext();

export class App {
    async start() {
        const restService = new RestService();
        await applicationModule.start(restService);
        documentContext.start(restService);
        formInstanceContext.start(restService);
        postContext.start(restService);
        reportContext.start();
        Broadcast.on(
            WebsocketEvents.onMessage,
            EventsBroker.onMessage,
            null,
            null,
        );
        this.render();
    }

    private render() {
        // @ts-ignore
        if (!window.preventApp) {
            const root = createRoot(document.getElementById("app") as Element);
            root.render(<AppUI />);
        }
    }
}
