import React from "react";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";
import TextField from "@mui/material/TextField";
import { useTextWidget } from "@ui/components/shared/CustomForm/CustomFormWidget/hooks/useTextWidget";

interface ITextWidgetProps extends IFormWidgetWrapped {}

export const TextWidget = (props: ITextWidgetProps) => {
    const {
        id,
        required,
        disabled,
        label,
        autofocus,
        schema,
        errors = [],
        helperText,
    } = props;

    const { value, onChange, onBlur, onFocus } = useTextWidget(props);

    return !schema?.hidden ? (
        <TextField
            id={id}
            label={label}
            autoFocus={autofocus}
            required={required}
            disabled={disabled}
            type={schema.type as string}
            value={value || ""}
            error={!!errors.length}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            helperText={helperText}
            fullWidth
            InputProps={{ notched: false }}
            InputLabelProps={{ shrink: true }}
            placeholder={props.schema.description}
            variant={"outlined"}
        />
    ) : (
        <></>
    );
};
