import { IAnalyticsEvent, IUserDTO } from "@mrs/webclient-shared-ui-lib";
import { CurrentUser } from "../../app/core/context/user/currentUser/CurrentUser";
import { DeviceOs } from "@utils/DeviceOs";
import { SessionUtils } from "@utils/SessionUtils";
import { RestService } from "../../app/infrastructure/http/RestService";
import { IRestService } from "../../app/infrastructure/http/IRestService";
import { ConfigAccess } from "@utils/ConfigAccess";
import { getCurrentLanguage } from "@translator";
import { getBrowserInfo } from "@utils/BrowserUtils";
import { Broadcast } from "../../app/infrastructure/broadcast/broadcast";
import { CurrentUserEvents } from "../../app/core/context/user/CurrentUserEvents";

type TBuildVersion = "production" | "development";

interface IBaseParams {
    session_id: Date;
    user: IUserDTO | null;
    tenant: string;
    app_build: TBuildVersion;
    app_language: string;
    app_version: string;
    os_name: string;
    os_version: string;
    geolocation: GeolocationPosition;
    languages: ReadonlyArray<string>;
    time: Date;
    platform: string;
}

class AnalyticsServiceClass {
    private readonly _restService: IRestService = new RestService();
    private readonly _browser = getBrowserInfo();
    private _position!: GeolocationPosition;
    private _user!: IUserDTO | null;

    constructor() {
        navigator.geolocation.getCurrentPosition(this.setPosition);
        this.requestCurrentUser();
        Broadcast.on(
            CurrentUserEvents.onUpdated,
            this.requestCurrentUser,
            null,
        );
    }

    logEvent(event: IAnalyticsEvent) {
        this.analyticsUrl &&
            this.sendEvent([{ ...event, ...this.getBaseParams() }]);
    }

    private sendEvent(data: IAnalyticsEvent[]) {
        this._restService
            .post(this.analyticsUrl, {
                data,
            })
            .asPromise();
    }

    private requestCurrentUser = () => {
        this._user = CurrentUser.getDtoWithParsedToken(true);
    };

    private setPosition = (position: GeolocationPosition) => {
        this._position = position;
    };

    private get analyticsUrl() {
        return ConfigAccess.config.analyticsUrl;
    }

    private getBaseParams(): IBaseParams {
        return {
            session_id: SessionUtils.sessionStart,
            user: this._user,
            time: new Date(),
            platform: DeviceOs.os(),
            app_version: (process.env.BUILD_INFO as any).version,
            tenant: ConfigAccess.configUrl,
            app_build: (process.env.NODE_ENV as TBuildVersion) || "development",
            app_language: getCurrentLanguage(),
            os_name: this._browser.name,
            os_version: this._browser.version,
            geolocation: this._position,
            languages: navigator.languages,
        };
    }
}
export const AnalyticsService = new AnalyticsServiceClass();
