import React, { useCallback, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { IFormInstanceDialogPresenter } from "./presenter/IFormInstanceDialogPresenter";
import isEqual from "lodash-es/isEqual";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventParent,
    AnalyticsEventLocation,
    ConfirmDialog,
    DateUtils,
} from "@mrs/webclient-shared-ui-lib";
import {
    DialogWithSaveButton,
    IDialogTab,
} from "../../shared/DialogWithSaveButton/DialogWithSaveButton";
import { InformationTab } from "../../shared/tabs/InformationTab/InformationTab";
import { CustomForm } from "@ui/components/shared/CustomForm/CustomForm";
import { Form } from "../../../../core/context/form/model/Form";
import {
    isTabForm,
    isTabFullScreen,
    TabType,
} from "../../../../core/context/form/dto/TypeDefinition";
import Box from "@mui/material/Box";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";

enum TABS_ID {
    Main = "main",
    Information = "information",
}

interface IFormInstanceDialogProps {
    presenter: IFormInstanceDialogPresenter;
}

export const FormInstanceDialog = observer(
    (props: IFormInstanceDialogProps) => {
        const {
            isOpen,
            formInstance,
            baseFormInstance,
            owner,
            form,
            isButtonLoading,
            isChangesSaved,
            toolbarActions,
            onChangeData,
            onClickSaveChanges,
            setIsChangesSaved,
            dialogStateDataToDefault,
            logEvent,
            hideFormFields,
        } = props.presenter;
        const { t } = useTranslation();
        const formRef = useRef<HTMLFormElement>(null);

        const hasChanges = useMemo(() => {
            if (!baseFormInstance || !formInstance) return false;
            return !isEqual(baseFormInstance.onSave(), formInstance.onSave());
        }, [baseFormInstance, formInstance]);

        const onClickClose = useCallback(async () => {
            logEvent({
                component: AnalyticsEventComponent.Close,
                action: AnalyticsEventAction.Click,
            });
            if (hasChanges) {
                const message = t("common:common.confirmCloseWithChanges");
                try {
                    await ConfirmDialog("", message, {
                        confirmLabel: t("common:common.reset"),
                        cancelLabel: t("common:common.cancel"),
                        confirmUiTestId: "resetButton",
                        cancelUiTestId: "cancelButton",
                        titleVariant: "body1",
                        muiTheme: BaseTheme,
                    });
                    logEvent({
                        component: AnalyticsEventComponent.Confirm,
                        action: AnalyticsEventAction.Click,
                        parent: AnalyticsEventParent.ResetConfirm,
                    });
                    onClose();
                } catch (e) {
                    logEvent({
                        component: AnalyticsEventComponent.Cancel,
                        action: AnalyticsEventAction.Click,
                        parent: AnalyticsEventParent.ResetConfirm,
                    });
                }
            } else {
                onClose();
            }
        }, [hasChanges]);

        const onClickSave = useCallback(() => {
            formRef.current?.formElement.current.requestSubmit();
        }, []);

        const onClose = useCallback(() => {
            props.presenter.onClose();
            dialogStateDataToDefault();
        }, []);

        const onChangeTab = useCallback((name: string) => {
            logEvent({
                // @ts-ignore
                location: AnalyticsEventLocation.Form,
                component: AnalyticsEventComponent.Tab,
                action: AnalyticsEventAction.Click,
                params: {
                    target: name,
                },
            });
        }, []);

        const renderCustomForm = useCallback(
            (formSchema: Form, type?: TabType) => {
                if (!formInstance) return <div />;
                return (
                    <Box
                        sx={
                            type === TabType.FullScreen
                                ? {
                                      height: "inherit",
                                      "& > form": {
                                          height: "inherit",
                                          "& > div:first-of-type": {
                                              height: "inherit",
                                          },
                                      },
                                  }
                                : {}
                        }
                    >
                        <CustomForm
                            key={"main-tab"}
                            formName={formInstance.formId}
                            formData={formInstance.data}
                            formSchema={formSchema}
                            formRef={formRef}
                            onChange={onChangeData}
                            onSubmit={onClickSaveChanges}
                        >
                            <div />
                        </CustomForm>
                    </Box>
                );
            },
            [formInstance, formRef],
        );

        const renderInformationTab = useCallback(() => {
            if (!formInstance) return <div />;
            const createdDateTitle = DateUtils.getDateMonthAndYear(
                formInstance.createdDate,
            );
            const informationData = [
                {
                    title: t("common:common.owner"),
                    value: owner?.fullName || "",
                },
                {
                    title: t("common:common.organization"),
                    value: owner?.attributes.organization || "",
                },
                {
                    title: t("common:common.createDate"),
                    value: createdDateTitle,
                },
            ];
            return <InformationTab key={"info-tab"} data={informationData} />;
        }, [formInstance, owner]);

        const defaultTabs: IDialogTab[] = useMemo(
            () => [
                {
                    label: t("common:common.tabs.main"),
                    id: TABS_ID.Main,
                    renderElement: renderCustomForm(form),
                },
                {
                    label: t("common:common.tabs.information"),
                    id: TABS_ID.Information,
                    renderElement: renderInformationTab(),
                    uiTestId: "informationTabButton",
                },
            ],
            [form, renderCustomForm, renderInformationTab],
        );

        const customTabs: IDialogTab[] = useMemo(() => {
            const result: IDialogTab[] = [];
            const tabs = form.scheme.tabs || [];
            for (const tab of tabs) {
                if (!!tab.hidden) continue;

                let fieldNames: string[] = [];
                let type: TabType | undefined = undefined;
                if (isTabForm(tab)) {
                    fieldNames = tab.fieldNames;
                    type = TabType.Form;
                } else if (isTabFullScreen(tab)) {
                    fieldNames = [tab.fieldName];
                    type = TabType.FullScreen;
                }

                const formSchema = hideFormFields(form, fieldNames, type);
                const dialogTab: IDialogTab = {
                    id: tab.name,
                    label: tab.title,
                    disabled: tab.disabled,
                    renderElement: renderCustomForm(formSchema, type),
                };
                result.push(dialogTab);
            }
            return result;
        }, [form, renderCustomForm]);

        return (
            <DialogWithSaveButton
                tabs={!!customTabs.length ? customTabs : defaultTabs}
                isOpen={isOpen}
                isLoading={isButtonLoading}
                isChangesSaved={isChangesSaved}
                title={form.title}
                hasChanges={hasChanges}
                onClose={onClickClose}
                setIsChangesSaved={setIsChangesSaved}
                onClickSave={onClickSave}
                onChangeTab={onChangeTab}
                dialogProps={{ id: formInstance?.id, maxWidth: "lg" }}
                toolbarActions={toolbarActions}
            />
        );
    },
);
