import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MRSLogo } from "@ui/components/shared/MRSLogo/MRSLogo";

interface ILoginHeaderProps {
    label: string;
}

export const LoginHeader = ({ label }: ILoginHeaderProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: 1,
                "& > img": {
                    m: 2,
                },
            }}
        >
            <MRSLogo />
            <Typography variant={"h6"}>{label}</Typography>
        </Box>
    );
};
