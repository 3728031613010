import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { LoginPresenter } from "./presenter/LoginPresenter";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { LoginPanes } from "./LoginPanes";
import { LoginError } from "./components/loginError/LoginError";
import { SubnetLogin } from "./components/subnetLogin/SubnetLogin";
import { useLocation } from "react-router-dom";
import { PathUtils } from "@utils/path/PathUtils";
import { ILoginParams } from "./presenter/ILoginPresenter";
import { Loader } from "@mrs/webclient-shared-ui-lib";

const CONTENT_WIDTH = 460;

export const LoginScreen = observer(() => {
    const location = useLocation();
    const presenter = useMemo(() => new LoginPresenter(), []);

    useEffect(() => {
        const params = PathUtils.getQueryStringParams(
            location.search.split("?")[1],
        ) as ILoginParams;
        presenter.init({ errorCode: params.errorCode });
    }, []);

    const getCurrentContent = () => {
        switch (presenter.currentPane) {
            case LoginPanes.ERROR:
                return (
                    <LoginError
                        errorCode={presenter.errorCode}
                        tryLogin={presenter.tryLogin}
                    />
                );
            case LoginPanes.SUBNET_LOGIN:
                return (
                    <SubnetLogin
                        orgUrl={presenter.orgUrl}
                        onChangeOrgUrl={presenter.onChangeOrgUrl}
                        subnetLogin={presenter.subnetLogin}
                    />
                );
            default:
                return <div />;
        }
    };

    return (
        <Box sx={styles.root}>
            <Paper sx={styles.loginContainer} elevation={0}>
                {getCurrentContent()}
            </Paper>
            {presenter.isLoading && <Loader isVisible isFullPage />}
        </Box>
    );
});

const styles = {
    root: {
        position: "relative",
        backgroundColor: "background.default",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
    },
    loginContainer: {
        borderRadius: "5px",
        width: CONTENT_WIDTH,
        margin: "auto",
        textAlign: "center",
        p: 3,
    },
};
