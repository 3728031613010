import { BaseApiService } from "../../../../sharedKernel/apiService/impl/BaseApiService";
import { IFormInstanceApiService } from "../IFormInstanceApiService";
import { IRestService } from "../../../../../infrastructure/http/IRestService";
import { IFormInstanceCreateDTO } from "../../dto/IFormInstanceCreateDTO";
import { IFormInstanceDTO } from "../../dto/IFormInstanceDTO";

export class FormInstanceApiService
    extends BaseApiService<IFormInstanceDTO, IFormInstanceCreateDTO>
    implements IFormInstanceApiService {
    constructor(service: IRestService, resourceName: string) {
        super(service, resourceName);
    }
}
