import {
    IDialogViewManagerParams,
    IDialogViewManagerPresenter,
} from "./IDialogViewManagerPresenter";
import { computed, observable } from "mobx";
import { ViewWithSearchManagerPresenter } from "../../ViewWithSearchManager/presenter/ViewWithSearchManagerPresenter";
import { injectable } from "inversify";
import { IWithId, TVoidCallback } from "@mrs/webclient-shared-ui-lib";
import { IViewWithSearchManagerInitParams } from "../../ViewWithSearchManager/presenter/IViewWithSearchManagerPresenter";

@injectable()
export class DialogViewManagerPresenter<T extends IWithId = any>
    extends ViewWithSearchManagerPresenter
    implements IDialogViewManagerPresenter {
    @observable private _canApply: boolean = false;

    private _selectedItems: T[] = [];
    private readonly _onClose: () => void;
    private readonly _onApply: TVoidCallback<T[]>;

    constructor(params: IDialogViewManagerParams) {
        super({
            multiple: params.multiple,
        });
        this._onApply = params.onApply;
        this._onClose = params.onClose;
    }

    @computed
    public get canApply(): boolean {
        return this._canApply;
    }

    init = async (
        params: Omit<IViewWithSearchManagerInitParams, "onChange">,
    ) => {
        this._canApply = !!params.values.length;
        await super.init({ ...params, onChange: this.onChange });
    };

    unmount = () => {
        super.unmount();
        this._canApply = false;
    };

    onApply = () => {
        this._onApply(this._selectedItems);
        this._onClose();
    };

    private onChange = async (items: T[]) => {
        this._selectedItems = items;
        this._canApply = !!items.length;
    };
}
