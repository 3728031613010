export const PRIMARY_COLOR_HUE_MAIN = 500;
export const PRIMARY_COLOR_HUE_DARK = 700;
export const PRIMARY_COLOR_HUE_LIGHT = 300;
export const PRIMARY_CONTRAST_TEXT = 0;

export const PRIMARY_COLOR_HUES = {
    0: "#FFFFFF",
    50: "#FFF7ED",
    100: "#FFEDD5",
    200: "#FED7AA",
    300: "#FDBA74",
    400: "#FB923C",
    500: "#F97316",
    600: "#EA580C",
    700: "#C2410C",
    800: "#9A3412",
    900: "#7C2D12",
};
