import { IAppSettings } from "@utils/IAppSettings";
import { lazyInject } from "../core/AppDiContainer";
import { ApplicationDiType } from "@ui/diType";
import { IConfigService } from "../core/context/config/service/IConfigService";

const CONFIG = "config";
const CONFIG_URL = "configUrl";

interface IConfig {
    features: Record<string, boolean>;
    server: {
        restStream: string;
        storageUrl: string;
        wsStream: string;
        nServiceUrl: string;
        serviceUrl: string;
        additionalServiceUrl: string;
        additionalWsStream: string;
    };
    oauth: {
        authorizationUrl: string;
        clientId: string;
        clientSecret: string;
        jwkUri: string;
        logoutUrl: string;
        refreshUrl: string;
        tokenUri: string;
        userInfoUri: string;
    };
    configurationUrl: string;
    analyticsUrl: string;
    app: IAppSettings;
    subVersion: string;
    glitchtipDSN: string;
    maxAttachmentSize: number;
    maxInactiveSessionTime: number;
    userViewId: string;
    userDictionary: string;
    defaultPage?: string;
}
class ConfigAccessClass {
    private _config: IConfig = {} as IConfig;

    @lazyInject(ApplicationDiType.IConfigService)
    private readonly configService: IConfigService;

    async init() {
        if (!!this.configUrl) {
            await this.requestConfig(this.configUrl);
        }
    }

    async requestConfig(url: string) {
        const config = await this.configService.getConfig(url);
        this.saveConfig(JSON.stringify(config));
    }

    saveConfig(data: string) {
        this._config = JSON.parse(data);
        this.appStorage.setItem(CONFIG, data);
    }

    removeConfig() {
        this.appStorage.removeItem(CONFIG);
        this._config = {} as IConfig;
    }

    removeConfigUrl() {
        this.appStorage.removeItem(CONFIG_URL);
    }

    setConfigUrl = (url: string) => {
        this.appStorage.setItem(CONFIG_URL, url);
    };

    get config(): IConfig {
        return this._config;
    }

    get configUrl() {
        return this.appStorage.getItem(CONFIG_URL) || "";
    }

    get appStorage(): Storage {
        const sessionStorageConfig = this._config?.features?.sessionStorage;
        return !!sessionStorageConfig ? sessionStorage : localStorage;
    }
}

export const ConfigAccess = new ConfigAccessClass();
