import { IWithName } from "@mrs/webclient-shared-ui-lib";

export enum TabType {
    Form = "form",
    FullScreen = "fullScreen",
}

export interface ITab extends IWithName {
    title: string;
    type: TabType;
    hidden?: boolean;
    disabled?: boolean;
}

interface ITabForm extends ITab {
    fieldNames: string[];
}

interface ITabFullScreen extends ITab {
    fieldName: string;
}

export function isTabForm(tab: ITab): tab is ITabForm {
    return tab.type === TabType.Form;
}

export function isTabFullScreen(tab: ITab): tab is ITabFullScreen {
    return tab.type === TabType.FullScreen;
}
