import get from "lodash-es/get";
import { Theme } from "@mui/material/styles";

const EMPTY_VALUE = "";

export class ThemeUtils {
    public static toThemeColor(theme: Theme, val?: string): string {
        const values = val?.split(".");
        const path =
            values && values.length > 1 ? values.join(".") : EMPTY_VALUE;
        return get(theme.palette, path) || EMPTY_VALUE;
    }
}
