import { alpha } from "@mui/material";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import "./fonts/inter.scss";
import { COMMON_COLOR } from "./color/CommonColors";
import {
    PRIMARY_COLOR_HUES,
    PRIMARY_COLOR_HUE_DARK,
    PRIMARY_COLOR_HUE_LIGHT,
    PRIMARY_COLOR_HUE_MAIN,
    PRIMARY_CONTRAST_TEXT,
} from "./color/PrimaryColor";
import { ERROR_COLOR_HUE_MAIN, ERROR_COLOR_HUES } from "./color/ErrorColor";
import { ADDITIONAL_COLOR } from "./color/AdditionalColor";
import {
    SUCCESS_COLOR_HUE_DARK,
    SUCCESS_COLOR_HUE_LIGHT,
    SUCCESS_COLOR_HUE_MAIN,
    SUCCESS_COLOR_HUES,
} from "./color/SuccessColor";
import {
    WARNING_COLOR_HUE_MAIN,
    WARNING_COLOR_HUES,
} from "./color/WarningColor";
import {
    INFO_COLOR_HUE_DARK,
    INFO_COLOR_HUE_MAIN,
    INFO_COLOR_HUES,
} from "./color/InfoColor";
import { GREY_COLOR_HUES } from "./color/GreyColor";
import { isWebkitBrowserPrefix } from "@utils/BrowserUtils";
import { FluentUiIconName, Icon } from "@mrs/webclient-shared-ui-lib";
import React from "react";

const DIALOG_WIDTH = {
    SM: 600,
    LG: 1000,
};

export enum IndentSize {
    ExtraSmall = 8,
    Small = 16,
    Medium = 24,
    Large = 32,
}

export const HEADER_HEIGHT = 48;
export const SEARCH_HEIGHT = 48;
export const DIVIDER_HEIGHT = 1;
export const MENU_WIDTH = 280;
const DISABLE_OPACITY = 0.26;
const DIVIDER_COLOR = GREY_COLOR_HUES[200];

const theme = createTheme({
    palette: {
        common: {
            black: COMMON_COLOR.BLACK,
            white: COMMON_COLOR.WHITE,
        },
        primary: {
            main: PRIMARY_COLOR_HUES[PRIMARY_COLOR_HUE_MAIN],
            light: PRIMARY_COLOR_HUES[PRIMARY_COLOR_HUE_LIGHT],
            dark: PRIMARY_COLOR_HUES[PRIMARY_COLOR_HUE_DARK],
            contrastText: PRIMARY_COLOR_HUES[PRIMARY_CONTRAST_TEXT],
            ...PRIMARY_COLOR_HUES,
        },
        secondary: {
            main: GREY_COLOR_HUES[500],
            light: GREY_COLOR_HUES[300],
            dark: GREY_COLOR_HUES[600],
            contrastText: GREY_COLOR_HUES[0],
        },
        success: {
            main: SUCCESS_COLOR_HUES[SUCCESS_COLOR_HUE_MAIN],
            light: SUCCESS_COLOR_HUES[SUCCESS_COLOR_HUE_LIGHT],
            dark: SUCCESS_COLOR_HUES[SUCCESS_COLOR_HUE_DARK],
            contrastText: PRIMARY_COLOR_HUES[PRIMARY_CONTRAST_TEXT],
            ...SUCCESS_COLOR_HUES,
        },
        error: {
            main: ERROR_COLOR_HUES[ERROR_COLOR_HUE_MAIN],
            ...ERROR_COLOR_HUES,
        },
        warning: {
            main: WARNING_COLOR_HUES[WARNING_COLOR_HUE_MAIN],
            ...WARNING_COLOR_HUES,
        },
        info: {
            main: INFO_COLOR_HUES[INFO_COLOR_HUE_MAIN],
            dark: INFO_COLOR_HUES[INFO_COLOR_HUE_DARK],
            ...INFO_COLOR_HUES,
        },
        grey: { ...GREY_COLOR_HUES },
        text: {
            primary: ADDITIONAL_COLOR.surfaceHighEmphasis,
            secondary: ADDITIONAL_COLOR.surfaceMediumEmphasis,
            disabled: ADDITIONAL_COLOR.surfaceHighEmphasis,
            // disabled: ADDITIONAL_COLOR.surfaceDisable,
        },
        background: {
            default: ADDITIONAL_COLOR.greySurface,
            paper: ADDITIONAL_COLOR.whiteSurface,
        },
        action: {
            disabledOpacity: 1,
            selectedOpacity: 0.12,
            selected: alpha(GREY_COLOR_HUES[900], 0.08),
            hoverOpacity: 0.08,
            hover: alpha(GREY_COLOR_HUES[900], 0.04),
        },
        divider: DIVIDER_COLOR,
    },
    typography: {
        fontFamily: [
            "Inter",
            "sans-serif",
            "Roboto",
            "Helvetica Neue",
            "Arial",
        ].join(","),
        h4: {
            fontSize: 32,
            lineHeight: "40px",
            fontWeight: 600,
            letterSpacing: 0,
        },
        h5: {
            fontSize: 24,
            lineHeight: "32px",
            fontWeight: 700,
            letterSpacing: 0,
        },
        h6: {
            fontSize: 20,
            lineHeight: "32px",
            fontWeight: 700,
            letterSpacing: 0,
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: "24px",
            fontWeight: 600,
            letterSpacing: "-0.2px",
        },
        subtitle2: {
            fontSize: 14,
            lineHeight: "20px",
            fontWeight: 600,
            letterSpacing: "-0.2px",
        },
        body1: {
            fontSize: 14,
            lineHeight: "24px",
            fontWeight: 400,
            letterSpacing: "-0.2px",
        },
        body2: {
            fontSize: 14,
            lineHeight: "20px",
            fontWeight: 400,
            letterSpacing: "-0.1px",
        },
        button: {
            fontSize: 14,
            lineHeight: "24px",
            fontWeight: 500,
            letterSpacing: 0,
        },
        caption: {
            fontSize: 12,
            lineHeight: "16px",
            fontWeight: 400,
            letterSpacing: 0,
        },
        overline: {
            fontSize: 12,
            lineHeight: "20px",
            fontWeight: 400,
            letterSpacing: 0,
        },
    },
    shadows: [
        "none", // 0
        "0px 1px 2px 0px rgba(51,51,51,0.04), 0px 1px 1px 0px rgba(51,51,51,0.01), 0px 0.5px 0.5px 0px rgba(51,51,51,0.02)", // 1
        "0px -1px 2px 0px rgba(51,51,51,0.04), 0px -1px 1px 0px rgba(51,51,51,0.01), 0px -0.5px 0.5px 0px rgba(51,51,51,0.02)", // 2
        "0px 1px 3px 0px rgba(51,51,51,0.1), 0px 2px 1px 0px rgba(51,51,51,0.02), 0px 1px 1px 0px rgba(51,51,51,0.04)", // 3
        "0px 1px 5px 0px rgba(0,0,0,0.1), 0px 3px 1px 0px rgba(0,0,0,0.02), 0px 2px 2px 0px rgba(51,51,51,0.04)", // 4
        "0px 1px 8px 0px rgba(51,51,51,0.1), 0px 3px 3px 0px rgba(51,51,51,0.02), 0px 3px 4px 0px rgba(51,51,51,0,04)", // 5
        "0px 2px 4px 0px rgba(51,51,51,0.1), 0px 1px 10px 0px rgba(51,51,51,0.02), 0px 4px 5px 0px rgba(51,51,51,0.04)", // 6
        "0px 3px 5px 0px rgba(51,51,51,0.1), 0px 1px 18px 0px rgba(51,51,51,0.02),0px 6px 10px 0px rgba(51,51,51,0.04)", // 7
        "0px 5px 5px 0px rgba(51,51,51,0.1), 0px 3px 14px 0px rgba(51,51,51,0.02), 0px 8px 10px 0px rgba(51,51,51,0.04)", // 8
        "0px 5px 6px 0px rgba(51,51,51,0.1), 0px 3px 16px 0px rgba(51,51,51,0.02), 0px 9px 12px 0px rgba(51,51,51,0.04)", // 9
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)", // do not use
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)", // do not use
        "0px 7px 8px 0px rgba(51,51,51,0.1), 0px 5px 22px 0px rgba(51,51,51,0.02), 0px 12px 17px 0px rgba(51,51,51,0.04)", // 12
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)", // do not use
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)", // do not use
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)", // do not use
        "0px 8px 10px 0px rgba(51,51,51,0.1), 0px 6px 30px 0px rgba(51,51,51,0.02), 0px 16px 24px 0px rgba(51,51,51,0.04)", // 16
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)", // do not use
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)", // do not use
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)", // do not use
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)", // do not use
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)", // do not use
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)", // do not use
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)", // do not use
        "0px 11px 15px 0px rgba(51,51,51,0.1), 0px 9px 46px 0px rgba(51,51,51,0.02), 0px 24px 38px 0px rgba(51,51,51,0.04)", // 24
    ],
    shape: {
        borderRadius: 4,
    },
});

export const BaseTheme = createTheme(theme, {
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    marginBottom: 0,
                },
                noWrap: {
                    overflowWrap: "break-word",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    ...theme.typography.body1,
                    backgroundColor: theme.palette.background.default,
                    border: `1px solid ${theme.palette.divider}`,
                    color: theme.palette.text.secondary,
                },
            },
        },
        MuiAvatarGroup: {
            styleOverrides: {
                root: {
                    mixBlendMode: "multiply",
                    "& > .MuiAvatar-root": {
                        boxSizing: "border-box",
                        border: `1px solid ${theme.palette.divider}`,
                    },
                },
                avatar: {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.secondary,
                    "&.MuiAvatar-root": {
                        boxSizing: "border-box",
                        border: `1px solid ${theme.palette.divider}`,
                    },
                },
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: alpha(theme.palette.common.black, 0.25),
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthSm: {
                    maxWidth: DIALOG_WIDTH.SM,
                },
                paperWidthLg: {
                    maxWidth: DIALOG_WIDTH.LG,
                },
                paper: {
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.paper,
                },
                paperFullScreen: {
                    backdropFilter: "none",
                    maxWidth: "100%",
                    borderRadius: 0,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0, 2),
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(2),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(3),
                },
                spacing: {
                    padding: theme.spacing(2),
                    "& > *": {
                        marginLeft: theme.spacing(1),
                    },
                },
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                marginTop: 0,
            },
            option: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                position: "relative",
                "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                },
                "&:active": {
                    backgroundColor: theme.palette.action.selected,
                },

                "&:not(:last-child):before": {
                    content: '""',
                    position: "absolute",
                    borderBottom: `1px solid ${alpha(
                        theme.palette.common.black,
                        0.12,
                    )}`,
                    width: `calc(100% - ${theme.spacing(2)})`,
                    top: `calc(100% - ${DIVIDER_HEIGHT})`,
                },
            },
            popupIndicator: {
                padding: theme.spacing(0.5),
                marginRight: 0,
            },
            clearIndicator: {
                padding: theme.spacing(0.75),
            },
            endAdornment: {
                bottom: theme.spacing(1),
                top: "auto",
                right: `${theme.spacing(1)} !important`,
            },
            listbox: {
                padding: 0,
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            },
            popper: {
                borderRadius: 15,
                backgroundColor: isWebkitBrowserPrefix
                    ? alpha(theme.palette.primary.contrastText, 0.7)
                    : theme.palette.background.default,
            },
            paper: {
                margin: 0,
                borderRadius: "inherit",
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&:focus": {
                        backgroundColor: "transparent",
                    },
                    "&:focus-within": {
                        paddingLeft: theme.spacing(2),
                    },
                },
                icon: {
                    color: theme.palette.text.secondary,
                    "&.Mui-disabled": {
                        color: theme.palette.text.secondary,
                        // color: theme.palette.text.disabled,
                    },
                },
                iconOutlined: {
                    right: theme.spacing(2),
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1, 1.25),
                    backgroundColor: theme.palette.common.white,
                    marginLeft: 0,
                    marginRight: 0,
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                    "&:active": {
                        backgroundColor: theme.palette.action.selected,
                    },
                    "&.Mui-disabled": {
                        cursor: "not-allowed",
                        backgroundColor: theme.palette.common.white,
                    },
                    "&:not(:last-child)": {
                        borderBottom: `1px solid ${theme.palette.divider}`,
                    },
                },
                label: {
                    ...theme.typography.body2,
                    marginLeft: theme.spacing(1),
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    ...theme.typography.caption,
                    display: "block",
                    fontWeight: 500,
                    marginBottom: theme.spacing(1),
                    color: theme.palette.text.primary,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: `1px solid ${theme.palette.divider}`,
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "primary",
                icon: (
                    <Icon
                        iconName={FluentUiIconName.CheckboxUncheckedRegular}
                        fontSize={"inherit"}
                    />
                ),
                checkedIcon: (
                    <Icon
                        iconName={FluentUiIconName.CheckboxCheckedFilled}
                        fontSize={"inherit"}
                    />
                ),
            },
            styleOverrides: {
                root: {
                    fontSize: 20,
                    "&.MuiButtonBase-root": {
                        padding: theme.spacing(0.75),
                    },
                },
                colorPrimary: {
                    // "&.Mui-disabled": {
                    //     color: theme.palette.text.disabled,
                    // },
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "&.MuiButtonBase-root": {
                        padding: theme.spacing(0.75),
                    },
                },
                colorPrimary: {
                    // "&.Mui-disabled": {
                    //     color: theme.palette.text.disabled,
                    // },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: theme.shape.borderRadius,
                },
                sizeSmall: {
                    padding: theme.spacing(0.25),
                    '&[class*="MuiIconButton-root"]': {
                        "&:hover": {
                            borderRadius: theme.shape.borderRadius,
                        },
                    },
                },
                sizeMedium: {
                    padding: theme.spacing(0.75),
                },
                sizeLarge: {
                    padding: theme.spacing(1.25),
                },
                edgeStart: {
                    marginLeft: theme.spacing(-1),
                },
                edgeEnd: {
                    marginRight: theme.spacing(-1),
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                deleteIcon: {
                    margin: 0,
                    "&:hover": {
                        opacity: 0.92,
                    },
                },
                icon: {
                    margin: 0,
                },
                label: {
                    fontSize: "14px",
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: 16,
                },
                fontSizeMedium: {
                    fontSize: 20,
                },
                fontSizeLarge: {
                    fontSize: 24,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ...theme.typography.body2,
                    textTransform: "none",
                    marginRight: theme.spacing(1),
                    "&:last-child": {
                        marginRight: 0,
                    },
                },
                textColorPrimary: {
                    "&.MuiButtonBase-root": {
                        color: theme.palette.text.secondary,
                        opacity: 1,
                        "&.Mui-selected": {
                            fontWeight: 700,
                            borderRadius: theme.shape.borderRadius,
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.text.primary,
                        },
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: "none",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    ...theme.typography.caption,
                    backgroundColor: theme.palette.background.paper,
                    padding: theme.spacing(0.75, 1.5),
                    color: theme.palette.text.secondary,
                    borderRadius: theme.shape.borderRadius,
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: theme.shadows[6],
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                },
                tooltipPlacementBottom: {
                    marginTop: `${theme.spacing(1)} !important`,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    marginTop: theme.spacing(3),
                    "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.divider,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${theme.palette.primary.main}`,
                    },
                    "&.MuiInputBase-multiline": {
                        padding: 0,
                    },
                },
                notchedOutline: {
                    borderColor: theme.palette.divider,
                },
                adornedEnd: {
                    paddingRight: theme.spacing(1),
                },
                input: {
                    padding: theme.spacing(1.25, 2),
                    "&.Mui-disabled": {
                        cursor: "not-allowed",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    "&::placeholder": {
                        opacity: 1,
                        color: theme.palette.text.secondary,
                    },
                },
                inputMultiline: {
                    overflow: "auto",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    ...theme.typography.caption,
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    "&.Mui-focused": {
                        color: theme.palette.text.primary,
                    },
                    "&.Mui-disabled": {
                        color: theme.palette.text.primary,
                    },
                },
                outlined: {
                    transform: "unset !important",
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                sizeSmall: {
                    padding: theme.spacing(0.625),
                },
                sizeMedium: {
                    padding: theme.spacing(1.125),
                    gap: theme.spacing(1),
                },
                sizeLarge: {
                    padding: theme.spacing(1.625),
                },
            },
        },
        MuiMenuItem: {
            root: {
                height: "56px",
                minWidth: "233px",
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    ...theme.typography.button,
                    height: 40,
                    textTransform: "none",
                    borderRadius: theme.shape.borderRadius,
                    color: theme.palette.text.secondary,
                    "&:disabled": {
                        cursor: "not-allowed",
                        pointerEvents: "auto",
                        backgroundColor: "unset",
                    },
                },
                outlined: {
                    border: `1px solid ${theme.palette.divider}`,
                    "&:hover": {
                        border: `1px solid ${theme.palette.divider}`,
                    },
                    "&:disabled": {
                        border: `1px solid ${theme.palette.divider}`,
                    },
                },
                contained: {
                    "&.Mui-disabled": {
                        opacity: theme.palette.action.disabledOpacity,
                        color: theme.palette.background.paper,
                    },
                },
                containedPrimary: {
                    color: theme.palette.primary.contrastText,
                    "&.Mui-disabled": {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            theme.palette.action.disabledOpacity,
                        ),
                        color: theme.palette.common.white,
                    },
                },
                containedError: {
                    color: theme.palette.primary.contrastText,
                },
                containedSuccess: {
                    color: theme.palette.primary.contrastText,
                },
                sizeSmall: {
                    height: 32,
                },
                sizeLarge: {
                    height: 48,
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                monthTitleContainer: {
                    alignItems: "center",
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: "inherit",
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                iconButton: {
                    backgroundColor: "inherit",
                },
            },
        },
        MuiPickersBasePicker: {
            styleOverrides: {
                containerLandscape: {
                    flexDirection: "column",
                },
            },
        },
        MuiPickersClock: {
            styleOverrides: {
                clock: {
                    backgroundColor: theme.palette.common.white,
                },
            },
        },
        MuiPickersModal: {
            styleOverrides: {
                dialogRoot: {
                    outline: "none !important",
                },
            },
        },
        MuiDateRangePickerDay: {
            styleOverrides: {
                root: ({ ownerState, theme }: any) => {
                    const startStyles = {
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                    };
                    const endStyles = {
                        borderTopRightRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                    };
                    return {
                        "&:first-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview": startStyles,
                        "&:last-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview": endStyles,
                        ...(ownerState.isHighlighting &&
                            !ownerState.outsideCurrentMonth && {
                                "&:first-of-type": startStyles,
                                "&:last-of-type": endStyles,
                            }),
                        ...((ownerState.isStartOfHighlighting ||
                            ownerState.isStartOfMonth) && { ...startStyles }),
                        ...((ownerState.isEndOfHighlighting ||
                            ownerState.isEndOfMonth) && { ...endStyles }),

                        ...(ownerState.isPreviewing &&
                            !ownerState.outsideCurrentMonth && {
                                ...((ownerState.isStartOfPreviewing ||
                                    ownerState.isStartOfMonth) && {
                                    "& >.MuiDateRangePickerDay-rangeIntervalPreview": startStyles,
                                }),
                                ...((ownerState.isEndOfPreviewing ||
                                    ownerState.isEndOfMonth) && {
                                    "& >.MuiDateRangePickerDay-rangeIntervalPreview": endStyles,
                                }),
                            }),
                    };
                },
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: theme.shape.borderRadius,
                backgroundColor: isWebkitBrowserPrefix
                    ? alpha(theme.palette.primary.contrastText, 0.7)
                    : theme.palette.background.default,
            },
        },
        MuiMenu: {
            list: {
                padding: 0,
            },
            paper: {
                borderRadius: "15px",
            },
        },
        MuiListItem: {
            styleOverrides: {
                button: {
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                    "&:pressed": {
                        backgroundColor: theme.palette.action.selected,
                    },
                },
                root: {
                    "&$selected": {
                        backgroundColor: theme.palette.action.hover,
                        "&:hover": {
                            backgroundColor: theme.palette.action.hover,
                        },
                        "&:pressed": {
                            backgroundColor: alpha(
                                theme.palette.primary.main,
                                0.3,
                            ),
                        },
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    fontSize: "20px",
                    minWidth: "36px",
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        cursor: "not-allowed",
                        pointerEvents: "auto",
                        backgroundColor: "unset",
                    },
                },
            },
        },
        PrivateSwitchBase: {
            root: {
                padding: theme.spacing(1.25),
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: theme.spacing(1),
                    marginLeft: 0,
                    marginRight: 0,
                    "&.Mui-disabled": {
                        color: theme.palette.text.secondary,
                    },
                },
                contained: {
                    marginLeft: "inherit",
                    marginRight: "inherit",
                },
            },
        },
        MuiCardActionArea: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
                focusHighlight: {
                    backgroundColor: "unset",
                },
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                content: {
                    padding: theme.spacing(1, 2),
                    boxSizing: "border-box",
                    cursor: "default",

                    "&.Mui-focused": {
                        backgroundColor: "unset",
                    },
                    "&.Mui-focused:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                    "&.Mui-selected, &.Mui-selected.Mui-focused": {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            theme.palette.action.selectedOpacity,
                        ),

                        "&:hover": {
                            backgroundColor: alpha(
                                theme.palette.primary.main,
                                theme.palette.action.selectedOpacity +
                                    theme.palette.action.hoverOpacity,
                            ),
                        },
                    },
                },
                group: {
                    marginLeft: theme.spacing(3.25),
                    paddingLeft: theme.spacing(1.875),
                    borderLeft: `1px solid ${theme.palette.divider}`,
                },

                iconContainer: {
                    width: "auto",
                    cursor: "pointer",
                    alignItems: "center",
                    flexShrink: "inherit",
                    marginRight: theme.spacing(0),
                    "& > svg": {
                        fontSize: 20,
                    },
                },

                label: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "inherit",
                    padding: theme.spacing(0, 2),
                },
            },
        },
    },
} as ThemeOptions);
