import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SKELETON_QUANTITY = 10;
const SKELETON_HEIGHT = 68;

interface ISkeletonsProps {
    quantity?: number;
}

export const Skeletons = (props: ISkeletonsProps) => {
    return (
        <>
            {Array(props.quantity || SKELETON_QUANTITY)
                .fill("")
                .map((e, index) => (
                    <Skeleton
                        key={index}
                        variant={"rectangular"}
                        sx={{
                            height: SKELETON_HEIGHT,
                            borderRadius: 2.5,
                            mt: 2,
                        }}
                    />
                ))}
        </>
    );
};
