export interface ICancelablePromise {
    promise: Promise<any>;
    cancel: () => void;
}

export const makeCancelable = (promise: Promise<any>): ICancelablePromise => {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then((val) => !isCanceled && resolve(val))
            .catch((error) => !isCanceled && reject(error));
    });

    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        },
    };
};
