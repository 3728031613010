import React, { useCallback, useEffect, useState } from "react";

let isOpenMenu = true;
let observers: React.Dispatch<React.SetStateAction<boolean>>[] = [];

const setIsOpenMenu = (isOpen: boolean) => {
    isOpenMenu = isOpen;
    observers.forEach((update) => update(isOpenMenu));
};

export const useAppMenu = () => {
    const [isOpenState, setIsOpenState] = useState<boolean>(isOpenMenu);

    useEffect(() => {
        observers.push(setIsOpenState);
        setIsOpenState(isOpenMenu);

        return () => {
            observers = observers.filter((update) => update !== setIsOpenState);
        };
    }, []);

    const onDrawerClose = useCallback(() => {
        setIsOpenMenu(false);
    }, []);

    const onDrawerOpen = useCallback(() => {
        setIsOpenMenu(true);
    }, []);

    return { open: isOpenState, onDrawerClose, onDrawerOpen };
};
