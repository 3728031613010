import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { FilterDialogPresenter } from "./presenter/FilterDialogPresenter";
import isNull from "lodash-es/isNull";
import { useTranslation } from "react-i18next";
import { FilterDialogItem } from "./FilterDialogItem/FilterDialogItem";
import { ViewFilter } from "../ViewFilter/ViewFilter";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventParent,
    AnalyticsEventType,
    IActionButtonProps,
    KeyCode,
    TVoidCallback,
    VIEW_FILTER_TYPE,
    ICheckedFilter,
    UniversalDialog,
} from "@mrs/webclient-shared-ui-lib";
import Divider from "@mui/material/Divider";
import { SelectionList } from "../../../shared/SelectionList/SelectionList";
import { ISearchViewModel } from "../../../shared/SelectionList/search/ISearchViewModel";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";

const APPLY_HOT_KEY = "Ctrl + Enter";
const DIALOG_HEIGHT = 600;
const DIALOG_WIDTH = 660;

interface IFilterDialogProps {
    open: boolean;
    filters: ICheckedFilter[];
    selectedFilter?: ICheckedFilter;
    onChange: TVoidCallback<ICheckedFilter[]>;
    onClose: (isEscapeKeyDown?: boolean) => void;
}

export const FilterDialog = observer((props: IFilterDialogProps) => {
    const { open, selectedFilter, onChange, onClose } = props;
    const { t } = useTranslation();
    const presenter = useMemo(() => new FilterDialogPresenter(), []);
    const {
        filtersBySearch,
        currentFilter,
        searchValue,
        showFilter,
        init,
        unmount,
        updateFilters,
        setCurrentFilter,
        setFilter,
        setIsValidData,
        onClickItem,
        onChangeSearchValue,
        canApply,
        handleKeyDown,
    } = presenter;

    const contentRef = useRef<HTMLDivElement>(null);

    const _handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            handleKeyDown(event);
            if (
                event.code === KeyCode.ENTER &&
                event.ctrlKey &&
                canApply(props.filters)
            ) {
                event.preventDefault();
                onApply();
            }
        },
        [props.filters],
    );

    useEffect(() => {
        init(props.filters);
        return () => {
            unmount();
        };
    }, []);

    useEffect(() => {
        if (!selectedFilter) return;
        const isSwitchFilterType =
            selectedFilter.value.type === VIEW_FILTER_TYPE.SWITCH;
        setCurrentFilter(selectedFilter.index, !isSwitchFilterType);
    }, [selectedFilter]);

    useEffect(() => {
        updateFilters(props.filters);
    }, [props.filters]);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", _handleKeyDown);
        } else {
            document.removeEventListener("keydown", _handleKeyDown);
        }
    }, [open]);

    const actions: IActionButtonProps[] = useMemo(() => {
        return [
            {
                title: t("common:common.cancel"),
                onClick: () => onCancel(),
                buttonVariant: "outlined",
                color: "secondary",
                size: "small",
                uiTestId: "cancelButton",
            },
            {
                title: t("common:common.apply"),
                onClick: () => onApply(),
                buttonVariant: "contained",
                color: "primary",
                disabled: !canApply(props.filters),
                size: "small",
                uiTestId: "applyButton",
                tooltip: {
                    title: t("common:common.applyFilters"),
                    hotKey: APPLY_HOT_KEY,
                },
            },
        ];
    }, [props.filters, filtersBySearch]);

    const onApply = useCallback(() => {
        onChange(presenter.filters);
        onCloseDialog();
        setCurrentFilter(null, false);
    }, [presenter.filters]);

    const onCancel = useCallback(
        (isEscapeKeyDown?: boolean) => {
            onCloseDialog(isEscapeKeyDown);
            updateFilters(props.filters);
        },
        [props.filters],
    );

    const onCloseDialog = useCallback((isEscapeKeyDown?: boolean) => {
        onClose(isEscapeKeyDown);
        presenter.onClose();
        AnalyticsService.logEvent({
            type: AnalyticsEventType.System,
            location: AnalyticsEventLocation.ViewManager,
            component: AnalyticsEventComponent.Close,
            action: isEscapeKeyDown
                ? AnalyticsEventAction.HotKey
                : AnalyticsEventAction.Click,
            parent: AnalyticsEventParent.Filter,
        });
    }, []);

    return (
        <UniversalDialog
            isOpen={open}
            title={
                showFilter && currentFilter
                    ? currentFilter.value.title
                    : t("common:common.filters")
            }
            actions={actions}
            withBackIcon={showFilter && !isNull(currentFilter)}
            onClose={onCancel}
            onBack={() => setCurrentFilter(null, false)}
            dialogProps={{
                disableEnforceFocus: true,
                sx: {
                    "& .MuiDialog-paper": {
                        height: DIALOG_HEIGHT,
                        width: "100%",
                        maxWidth: DIALOG_WIDTH,
                        backgroundColor: "background.paper",
                    },
                },
            }}
            dialogContentProps={{ ref: contentRef }}
        >
            {!showFilter ? (
                <SelectionList
                    isEmptyList={!filtersBySearch.length}
                    isMultiSelect={false}
                    autofocus={true}
                    searchViewModel={
                        {
                            searchValue,
                            onChangeSearchValue,
                            onClearSearch: () => onChangeSearchValue(""),
                        } as ISearchViewModel
                    }
                >
                    <>
                        {filtersBySearch.map((item, index: number) => (
                            <React.Fragment key={`${item.index}-${index}`}>
                                <FilterDialogItem
                                    item={item}
                                    isSelected={
                                        item.index === currentFilter?.index
                                    }
                                    onClick={onClickItem}
                                />
                                {index !== filtersBySearch.length - 1 && (
                                    <Divider />
                                )}
                            </React.Fragment>
                        ))}
                    </>
                </SelectionList>
            ) : (
                <ViewFilter
                    currentFilter={currentFilter}
                    contentRef={contentRef}
                    autoFocus
                    onChange={setFilter}
                    onDataValidation={setIsValidData}
                />
            )}
        </UniversalDialog>
    );
});
