import React, { useMemo } from "react";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";
import { RadioVariant } from "./RadioVariant";
import { ButtonVariant } from "./ButtonVariant";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

interface IRadioWidgetProps extends IFormWidgetWrapped {}

export const RadioWidget = (props: IRadioWidgetProps) => {
    const { id, schema, required, helperText, label, errors } = props;

    const hidden = useMemo(() => schema?.hidden || false, [schema]);

    return !hidden ? (
        <>
            <FormLabel required={required} htmlFor={id}>
                {label || schema.title}
            </FormLabel>
            {(schema as any)?.variant === "button" ? (
                <ButtonVariant {...props} />
            ) : (
                <RadioVariant {...props} />
            )}
            {helperText && (
                <FormHelperText error={!!errors?.length}>
                    {helperText}
                </FormHelperText>
            )}
        </>
    ) : (
        <></>
    );
};
