import React from "react";
import { IWithSx, ObjectId } from "@mrs/webclient-shared-ui-lib";
import { alpha } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";

interface ISectionItemProps extends IWithSx {
    id: ObjectId;
    sectionItemElement: React.ReactElement;
    checked: boolean;
    isSmallSectionItem?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    withCheckbox?: boolean;
    onClick: (id: ObjectId, checked: boolean, close?: boolean) => void;
}

export const SectionItem = (props: ISectionItemProps) => {
    const {
        id,
        checked,
        sectionItemElement,
        isSmallSectionItem,
        isSelected,
        onClick,
        isDisabled,
        withCheckbox = true,
        sx,
    } = props;

    const onClickCard = (event: React.SyntheticEvent) => {
        onClick(id, !checked, true);
    };

    const onClickCheckbox = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        onClick(id, !checked, false);
    };

    return (
        <ListItem
            selected={isSelected}
            button
            disableRipple
            onClick={onClickCard}
            disabled={isDisabled}
            sx={[
                styles.sectionItemInfo,
                ...(checked ? [styles.sectionItemInfoChecked] : []),
                ...(isSmallSectionItem ? [styles.smallSectionItemInfo] : []),
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {sectionItemElement}

            {withCheckbox && (
                <Checkbox
                    onClick={onClickCheckbox}
                    checked={checked}
                    sx={styles.checkbox}
                />
            )}
        </ListItem>
    );
};

const styles = {
    sectionItemInfo: {
        display: "flex",
        justifyContent: "space-between",
        p: 2,
    },
    smallSectionItemInfo: {
        p: (theme: Theme) => theme.spacing(1, 2),
    },
    sectionItemInfoChecked: {
        backgroundColor: (theme: Theme) =>
            `${alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity,
            )} !important`,
    },
    checkbox: {
        p: 1,
        "&:hover": {
            backgroundColor: (theme: Theme) =>
                alpha(
                    theme.palette.common.black,
                    theme.palette.action.hoverOpacity,
                ),
        },
        "&:focus": {
            backgroundColor: (theme: Theme) =>
                alpha(
                    theme.palette.common.black,
                    theme.palette.action.focusOpacity,
                ),
        },
    },
};
