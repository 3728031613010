import { IUserAttributes, IUserDTO } from "@mrs/webclient-shared-ui-lib";

export class User {
    private readonly _id: string;
    private readonly _email: string;
    private readonly _firstName: string;
    private readonly _lastName: string;
    private readonly _username: string;
    private readonly _attributes: IUserAttributes;
    private readonly _keycloakGroupIds: string[];
    private _accessToken?: string;

    constructor(dto: IUserDTO) {
        this._id = dto.id;
        this._email = dto.email;
        this._firstName = dto.firstName;
        this._lastName = dto.lastName;
        this._username = dto.username;
        this._attributes = dto.attributes;
        this._keycloakGroupIds = dto.keycloakGroupIds;
        this._accessToken = dto.accessToken;
    }

    get id(): string {
        return this._id;
    }

    get email(): string {
        return this._email;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get username(): string {
        return this._username;
    }

    get attributes(): IUserAttributes {
        return this._attributes;
    }

    get keycloakGroupIds(): string[] {
        return this._keycloakGroupIds;
    }

    get fullName(): string {
        return `${this._lastName} ${this._firstName}`.trim();
    }

    get accessToken(): string | undefined {
        return this._accessToken;
    }

    set accessToken(value: string | undefined) {
        this._accessToken = value;
    }

    toDTO(): IUserDTO {
        return {
            id: this._id,
            email: this._email,
            firstName: this._firstName,
            lastName: this._lastName,
            username: this._username,
            fullName: this.fullName,
            attributes: this._attributes,
            keycloakGroupIds: this._keycloakGroupIds,
            accessToken: this._accessToken,
        };
    }
}
