import { ObjectId, IUserDTO } from "@mrs/webclient-shared-ui-lib";
import { appStorage } from "../../../../infrastructure/storage/AppStorage";
import { ACCESS_TOKEN } from "../../auth/service/AuthService";
import { UserUtils } from "@utils/UserUtils";
import { User } from "../model/User";
import cloneDeep from "lodash-es/cloneDeep";
import { Broadcast } from "../../../../infrastructure/broadcast/broadcast";
import { CurrentUserEvents } from "../CurrentUserEvents";

export const USER_LS_NAME = "user";
export const FIELDS_FOR_CLEAR = ["accessToken", "attributes"];

class CurrentUserClass {
    private currentUser: User | null;

    constructor() {
        const user = appStorage.getItem(USER_LS_NAME);
        if (user) {
            this.currentUser = new User(JSON.parse(user));
        }
    }

    // set current user
    setUser(user: User) {
        this.currentUser = user;
        appStorage.setItem(USER_LS_NAME, JSON.stringify(user.toDTO()));
        Broadcast.trig(CurrentUserEvents.onUpdated, null);
    }

    // get current user
    getCurrentUser(): User | null {
        return this.currentUser;
    }

    getDtoWithParsedToken(excludePrivateFields?: boolean): IUserDTO | null {
        const newUser = cloneDeep(this.currentUser);
        if (newUser) {
            newUser.accessToken = UserUtils.parseToken(
                newUser.accessToken || "",
            );

            excludePrivateFields && this.clearPrivateFields(newUser);
        }
        return newUser?.toDTO() || null;
    }

    getUserId(): ObjectId | null {
        return this.currentUser ? this.currentUser.id : null;
    }

    getToken(): string {
        return appStorage.getItem(ACCESS_TOKEN) || "";
    }

    updateToken(token: string): void {
        this.currentUser && (this.currentUser.accessToken = token);
    }

    itsMe(id: ObjectId): boolean {
        return id === this.getUserId();
    }

    // drop current user and logout from system
    dropUser(): void {
        this.currentUser = null;
        appStorage.removeItem(USER_LS_NAME);
    }

    private clearPrivateFields(user: User): void {
        FIELDS_FOR_CLEAR.forEach((field) => {
            const valueForClear = user[field as keyof User];
            valueForClear &&
                Object.keys(valueForClear as object).forEach((key) => {
                    if (key.includes("private"))
                        delete (valueForClear as any)[key];
                });
        });
    }
}

export const CurrentUser = new CurrentUserClass();
