import React from "react";
import {
    FluentUiIconName,
    Icon,
    IWithSx,
    IWithUiTestId,
    TVoidCallback,
} from "@mrs/webclient-shared-ui-lib";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

interface ISearchProps extends IWithUiTestId, IWithSx {
    onChange: TVoidCallback<string>;
    onFocus?: () => void;
    onBlur?: () => void;
    onKeyPress?: TVoidCallback<React.KeyboardEvent<HTMLDivElement>>;
    children?: React.ReactNode;
    placeholder?: string;
    keyword?: string;
    autoFocus?: boolean;
    startIcon?: boolean;
    disabled?: boolean;
    inputRef?: React.Ref<HTMLDivElement>;
    inputBaseProps?: InputBaseProps;
}

const MAX_INPUT_CHARS = 200;

export const Search = (props: ISearchProps) => {
    const {
        sx,
        placeholder,
        keyword,
        children,
        autoFocus,
        startIcon = true,
        uiTestId = "searchInput",
        onKeyPress,
        inputRef,
    } = props;

    const handlerSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    };

    return (
        <Paper
            sx={[
                {
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "background.paper",
                    boxShadow: "none",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {startIcon && (
                <Icon
                    iconName={FluentUiIconName.SearchRegular}
                    sx={{
                        color: "text.secondary",
                        m: (theme) => theme.spacing(0, 2, 0, 0),
                    }}
                />
            )}
            <InputBase
                value={keyword}
                disabled={props.disabled}
                placeholder={placeholder}
                onChange={handlerSearch}
                onKeyPress={onKeyPress}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                autoFocus={autoFocus}
                fullWidth
                inputProps={{
                    maxLength: MAX_INPUT_CHARS,
                    "data-ui-test": uiTestId,
                }}
                inputRef={inputRef}
                {...props.inputBaseProps}
            />
            {children}
        </Paper>
    );
};
