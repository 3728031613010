import { DiContainer } from "../../AppDiContainer";
import { IRestService } from "../../../infrastructure/http/IRestService";
import { ContainerModule } from "inversify";
import { PostApiService } from "./apiService/impl/PostApiService";
import { PostRepository } from "./repository/impl/PostRepository";
import { PostService } from "./service/impl/PostService";
import { ApplicationDiType } from "@ui/diType";
import { EventType } from "@mrs/webclient-shared-ui-lib";
import { EventsBroker } from "../events/EventsBroker";
import { ObjectType } from "../../ObjectType";
import { PostEvents } from "./PostEvents";
import { PostController } from "./controller/impl/PostController";
import { IPostController } from "./controller/IPostController";

export class PostContext {
    start(restService: IRestService): void {
        const postModule = new ContainerModule((bind) => {
            const resourceName = "feed/posts";
            const apiService = new PostApiService(restService, resourceName);
            const repository = new PostRepository(apiService);
            const service = new PostService(repository);
            const controller = new PostController(service);

            bind<IPostController>(
                ApplicationDiType.IPostController,
            ).toConstantValue(controller);
        });
        DiContainer.load(postModule);

        const eventNamesMap: Map<EventType, string> = new Map();
        eventNamesMap.set(EventType.CREATE, PostEvents.onCreated);
        eventNamesMap.set(EventType.UPDATE, PostEvents.onUpdated);
        eventNamesMap.set(EventType.DELETE, PostEvents.onRemove);
        EventsBroker.setEventNames(ObjectType.POST, eventNamesMap);
    }
}
