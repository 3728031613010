import React, { useMemo } from "react";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
    enumOptionsIndexForValue,
    enumOptionsValueForIndex,
} from "@rjsf/utils";
import ExpandMore from "@mui/icons-material/ExpandMore";

interface ISelectWidgetProps extends IFormWidgetWrapped {}

export const SelectWidget = (props: ISelectWidgetProps) => {
    const {
        schema,
        id,
        options,
        required,
        disabled,
        multiple,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        errors = [],
        helperText,
    } = props;
    const { enumOptions, enumDisabled } = options;
    const emptyValue = multiple ? [] : "";

    const hidden = useMemo(() => schema?.hidden || false, [schema]);

    const _onChange = ({
        target: { value },
    }: React.ChangeEvent<{ value: string }>) => {
        const newValue = enumOptionsValueForIndex(
            value,
            enumOptions,
            emptyValue,
        );
        onChange(newValue);
        onBlur(id, newValue);
    };

    const _onBlur = ({
        target: { value },
    }: React.FocusEvent<HTMLInputElement>) => {
        onBlur(id, enumOptionsValueForIndex(value, enumOptions, emptyValue));
    };

    const _onFocus = ({
        target: { value },
    }: React.FocusEvent<HTMLInputElement>) => {
        onFocus(id, enumOptionsValueForIndex(value, enumOptions, emptyValue));
    };

    const selectedIndexes = useMemo(
        () => enumOptionsIndexForValue(props.value, enumOptions, multiple),
        [props.value, enumOptions, multiple],
    );

    return !hidden ? (
        <TextField
            id={id}
            label={props.label || schema.title}
            variant={"outlined"}
            fullWidth
            select
            value={selectedIndexes || emptyValue}
            required={required}
            disabled={disabled}
            autoFocus={autofocus}
            error={errors.length > 0}
            helperText={helperText}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
            placeholder={props.schema.description}
            InputProps={{ notched: false }}
            InputLabelProps={{ shrink: true }}
            SelectProps={{
                multiple: typeof multiple === "undefined" ? false : multiple,
                MenuProps: {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    sx: {
                        "& .MuiPaper-root": {
                            mt: 0.25,
                        },
                    },
                },
                IconComponent: ExpandMore,
            }}
        >
            {(enumOptions as any).map(
                ({ value, label }: any, index: number) => {
                    const itemDisabled =
                        !!enumDisabled &&
                        (enumDisabled as any).indexOf(value) !== -1;
                    return (
                        <MenuItem
                            key={`${label}-${index}`}
                            value={String(index)}
                            disabled={itemDisabled}
                            sx={{
                                "&:before": {
                                    content: '""',
                                    position: "absolute",
                                    width: (theme) =>
                                        `calc(100% - ${theme.spacing(2)})`,
                                    top: "calc(100% - 1px)",
                                },
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                },
            )}
        </TextField>
    ) : (
        <></>
    );
};
