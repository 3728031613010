import {
    IUserDTO,
    IWithId,
    ObjectId,
    HookType,
    MongoId,
} from "@mrs/webclient-shared-ui-lib";
import { Exclude, Expose } from "class-transformer";
import { ObjectType } from "../../ObjectType";
import { Hook } from "../../context/hook/model/Hook";
import { IHookCheckingData } from "../../context/hook/dto/IHookCheckingData";

@Exclude()
export abstract class EntityWithHooks<DTO extends IWithId> {
    protected readonly _user!: IUserDTO;
    protected _hooks: Map<HookType, <D>(args: any) => D> = new Map();
    private readonly _id!: ObjectId;
    private readonly _base!: DTO;

    protected constructor(
        data: Partial<DTO>,
        user: IUserDTO,
        hooks: Hook[] = [],
    ) {
        this._user = user;
        this._id = data.id || MongoId.create();
        hooks.forEach((hook) => {
            this._hooks.set(hook.hookType, hook.hook);
        });
        this.fromDTO(data);
        this._base = this.toDTO();
        this.onChange();
    }

    @Expose()
    get id(): ObjectId {
        return this._id;
    }

    abstract get type(): ObjectType;

    onSave(): DTO {
        const hook = this._hooks.get(HookType.ON_SAVE);
        if (hook) {
            return hook<DTO>({ item: this.toDTO(), user: this._user });
        }
        return this.toDTO();
    }

    canSave(): boolean {
        const hook = this._hooks.get(HookType.CAN_SAVE);
        if (hook) {
            const result = hook<IHookCheckingData>({
                item: this.toDTO(),
                user: this._user,
            });
            if (!result.value) {
                throw new Error(result?.errorMessage);
            }
            return result.value;
        }
        return true;
    }

    onCreate(initData: object | null) {
        const hook = this._hooks.get(HookType.ON_CREATE);
        if (hook) {
            const res = hook<DTO>({
                item: this.toDTO(),
                user: this._user,
                initData,
            });
            this.fromDTO(res);
        }
    }

    canChange(changes: Partial<DTO>): boolean {
        const hook = this._hooks.get(HookType.CAN_CHANGE);
        if (hook) {
            const result = hook<IHookCheckingData>({
                base: this._base,
                item: this.toDTO(),
                user: this._user,
                changes,
            });
            if (!result.value) {
                throw new Error(result?.errorMessage);
            }
            return result.value;
        }
        return true;
    }

    onChange(): void {
        const hook = this._hooks.get(HookType.ON_CHANGE);
        if (hook) {
            try {
                const res = hook<DTO>({
                    base: this._base,
                    item: this.toDTO(),
                    user: this._user,
                });
                this.fromDTO(res);
            } catch (e) {
                console.error(e);
            }
        }
    }

    canDelete(): boolean {
        const hook = this._hooks.get(HookType.CAN_DELETE);
        if (hook) {
            const result = hook<IHookCheckingData>({
                item: this.toDTO(),
                user: this._user,
            });

            if (!result.value) {
                throw new Error(result?.errorMessage);
            }
            return result.value;
        }
        return true;
    }

    get viewUpdate(): any | undefined {
        return this._hooks.get(HookType.VIEW_UPDATE);
    }

    protected abstract fromDTO(data: Partial<DTO>): void;
    protected abstract toDTO(): DTO;
}
