import React from "react";
import {
    ISupersetViewDocument,
    ISupersetViewPresenter,
} from "./ISupersetViewPresenter";
import { injectable } from "inversify";
import { computed } from "mobx";
import { SupersetView } from "../SupersetView";
import { BaseViewPresenterClass } from "@ui/components/view/shared/BaseViewPresenterClass";
import { embedDashboard } from "@lib/superset";
import axios from "axios";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import get from "lodash-es/get";

const USERNAME_KEY = "private_superset_user";
const PASSWORD_KEY = "private_superset_password";

@injectable()
export class SupersetViewPresenter extends BaseViewPresenterClass<any>
    implements ISupersetViewPresenter {
    constructor() {
        super();
    }

    @computed
    public get document(): ISupersetViewDocument | null {
        return this.currentFilterValue
            ? (this.currentFilterValue[0] as ISupersetViewDocument)
            : null;
    }

    onChangeDocument = async (
        doc: ISupersetViewDocument,
        parent: HTMLDivElement,
    ) => {
        await embedDashboard({
            id: doc.dashboardId,
            supersetDomain: doc.src,
            mountPoint: parent,
            fetchGuestToken: () => this.getGuestToken(doc),
            dashboardUiConfig:
                doc.dashboardUiConfig ||
                this.view?.settings?.dashboardUiConfig ||
                undefined,
        });
    };

    render() {
        return <SupersetView presenter={this} key={this.view?.name} />;
    }

    isSwitchable = () => {
        return false;
    };

    private getAccessToken = async (doc: ISupersetViewDocument) => {
        if (!this.username || !this.password) return;
        try {
            const body = {
                username: this.username,
                password: this.password,
                provider: "db",
                refresh: true,
            };

            const response = await axios.post(
                `${doc.src}/api/v1/security/login`,
                body,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            return response.data.access_token;
        } catch (e) {
            console.error(e);
        }
    };

    private getGuestToken = async (doc: ISupersetViewDocument) => {
        const accessToken = await this.getAccessToken(doc);
        try {
            const body = {
                resources: [
                    {
                        type: "dashboard",
                        id: doc.dashboardId,
                    },
                ],
                rls: [],
                user: {
                    username: "guest",
                    first_name: "Superset",
                    last_name: "Admin",
                },
            };
            const response = await axios.post(
                `${doc.src}/api/v1/security/guest_token/`,
                body,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );
            return response.data.token;
        } catch (error) {
            console.error(error);
        }
    };

    private get accessToken() {
        return CurrentUser.getDtoWithParsedToken()?.accessToken;
    }

    private get username() {
        return get(this.accessToken, USERNAME_KEY);
    }

    private get password() {
        return get(this.accessToken, PASSWORD_KEY);
    }
}
