import { Canceler, THttpRequest } from "../IRestService";

export class HttpRequest<T = any> {
    private readonly promise: THttpRequest<T>;
    private readonly canceler: Canceler;

    constructor(promise: THttpRequest<T>, canceler: Canceler) {
        this.promise = promise;
        this.canceler = canceler;
    }

    cancel(reason: string = "cancel request"): void {
        this.canceler && this.canceler(reason);
    }

    then(callback: (data: T) => void): this {
        this.promise.then(callback);
        return this;
    }

    catch(callback: (e: any) => void): this {
        this.promise.catch(callback);
        return this;
    }

    asPromise(): THttpRequest<T> {
        return this.promise;
    }
}
