import React, { useMemo } from "react";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";

interface ICheckboxesWidgetProps extends IFormWidgetWrapped {}

export const CheckboxesWidget = (props: ICheckboxesWidgetProps) => {
    const {
        id,
        schema,
        options,
        value,
        required,
        disabled,
        label,
        onChange,
        onBlur,
        errors,
        helperText,
    } = props;
    const { enumOptions, enumDisabled } = options;

    const hidden = useMemo(() => schema?.hidden || false, [schema]);

    const _onChange = async (option: any) => {
        const itemValue = option.value;
        const newValue = !!value?.includes(itemValue)
            ? value.filter((item: any) => item !== itemValue)
            : [...value, itemValue];
        await onChange(newValue);
        onBlur(id, newValue);
    };

    const _onBlur = (event: React.FocusEvent<HTMLButtonElement>) =>
        onBlur(id, event.target.value);
    const _onFocus = (event: React.FocusEvent<HTMLButtonElement>) =>
        props.onFocus(id, event.target.value);

    return !hidden ? (
        <>
            <FormLabel required={required} htmlFor={id}>
                {label || schema.title}
            </FormLabel>
            <FormGroup row={!!options.inline}>
                {(enumOptions as any).map((option: any, index: number) => {
                    const itemValue = option.value;
                    const itemDisabled =
                        enumDisabled &&
                        (enumDisabled as any).indexOf(itemValue) !== -1;

                    return (
                        <FormControlLabel
                            key={`control-${index}`}
                            control={
                                <Checkbox
                                    checked={!!value?.includes(itemValue)}
                                    onChange={() => _onChange(option)}
                                    onBlur={_onBlur}
                                    onFocus={_onFocus}
                                />
                            }
                            label={`${option.label}`}
                            value={itemValue}
                            disabled={disabled || !!itemDisabled}
                        />
                    );
                })}
            </FormGroup>
            {helperText && (
                <FormHelperText error={!!errors?.length}>
                    {helperText}
                </FormHelperText>
            )}
        </>
    ) : (
        <></>
    );
};
