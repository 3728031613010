import React from "react";
import { injectable } from "inversify";
import { IDataGridViewItem } from "./IDataGridViewItem";
import { IDataGridViewPresenter } from "./IDataGridViewPresenter";
import { DataGridView } from "../DataGridView";
import { IDataGridViewColumn } from "@ui/components/view/DataGridView/presenter/IDataGridViewColumn";
import { BaseViewPresenterClass } from "@ui/components/view/shared/BaseViewPresenterClass";

@injectable()
export class DataGridViewPresenter
    extends BaseViewPresenterClass<IDataGridViewItem>
    implements IDataGridViewPresenter {
    constructor() {
        super();
    }
    public get columns() {
        // @ts-ignore
        return (this.view?.settings?.columns as IDataGridViewColumn[]) || [];
    }

    render() {
        return <DataGridView presenter={this} />;
    }
}
