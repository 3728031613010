import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import { Identifiable } from "../../../sharedKernel/model/Identifiable";
import { dto } from "@mrs/platform-configuration-webclient";
import { classToPlain, Exclude, Expose } from "class-transformer";

@Exclude()
export class ReportTemplate extends Identifiable {
    private _templateName!: string;
    private _fileTitle!: string;
    private _filenameExtension!: string;
    private _formId!: ObjectId;
    private _tags!: ObjectId[];
    constructor(dto: dto.doctemplates.DocTemplate) {
        super(dto);
        this.fromDTO(dto);
    }

    @Expose()
    get templateName(): string {
        return this._templateName;
    }

    set templateName(value) {
        this._templateName = value;
    }

    @Expose()
    get fileTitle(): string {
        return this._fileTitle;
    }

    set fileTitle(value) {
        this._fileTitle = value;
    }

    @Expose()
    get formId(): ObjectId {
        return this._formId;
    }

    set formId(value) {
        this._formId = value;
    }

    @Expose()
    get tags(): ObjectId[] {
        return this._tags;
    }

    set tags(value) {
        this._tags = value;
    }

    @Expose()
    get filenameExtension(): string {
        return this._filenameExtension;
    }

    set filenameExtension(value) {
        this._filenameExtension = value;
    }

    toDTO(): dto.doctemplates.DocTemplate {
        return classToPlain(this) as dto.doctemplates.DocTemplate;
    }

    fromDTO(dto: dto.doctemplates.DocTemplate) {
        this._templateName = dto.templateName || "";
        this._filenameExtension = dto.filenameExtension || "";
        this._fileTitle = dto.fileTitle || "";
        this._formId = dto.formId || "";
        this._tags = dto.tags || [];
    }
}
