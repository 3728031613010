import React from "react";
import { observer } from "mobx-react";
import { INotifierPresenter } from "@ui/components/shared/Notifier/presenter/INotifierPresenter";
import { Toaster } from "sonner";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material";
import { Theme } from "@mui/material/styles";

const DURATION = 3000;

interface INotifier {
    presenter: Pick<INotifierPresenter, "position">;
}

export const Notifier = observer((props: INotifier) => {
    return (
        <Box sx={styles.root}>
            <Toaster
                duration={DURATION}
                position={props.presenter.position}
                closeButton
            />
        </Box>
    );
});

const styles = {
    root: {
        "& > section > ol": {
            "--width": "400px !important",

            "& > li": {
                "&[data-sonner-toast][data-styled=true]": {
                    alignItems: "start",
                    p: (theme: Theme) => theme.spacing(2, 1.75),
                },

                "&[data-sonner-toast][data-styled=true] [data-icon], &[data-sonner-toast] [data-icon]>*": {
                    m: 0,
                    width: 20,
                    height: 20,
                },

                "&[data-sonner-toast][data-styled=true][data-type=success] [data-icon]": {
                    color: "success.main",
                },

                "&[data-sonner-toast] [data-button]": {
                    color: "grey.500",
                    backgroundColor: "transparent",
                    border: (theme: Theme) =>
                        `1px solid ${theme.palette.grey[200]}`,
                    transition:
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },

                "&[data-sonner-toast] [data-button]:hover, &[data-sonner-toast]:hover [data-close-button]:hover": {
                    backgroundColor: (theme: Theme) =>
                        alpha(
                            theme.palette.common.black,
                            theme.palette.action.hoverOpacity,
                        ),
                },

                "&[data-sonner-toast] [data-close-button]": {
                    color: "grey.500",
                    borderColor: "grey.200",
                    transition:
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
            },
        },
    },
};
