import React from "react";
import {
    FluentUiIconName,
    Icon,
    IWithSx,
    TVoidCallback,
} from "@mrs/webclient-shared-ui-lib";
import { alpha } from "@mui/material";
import { Theme } from "@mui/material/styles";
import MuiChip from "@mui/material/Chip";

export enum ChipSize {
    ExtraSmall = 32,
    Small = 40,
    Medium = 48,
    Large = 56,
}

const ICON_SIZE = 24;
const ICON_EXTRA_SMALL_SIZE = 20;

export interface IChipProps<T = any> extends IWithSx {
    label: string;
    item: T;
    size?: ChipSize;
    icon?: React.ReactElement;
    withDeleteIcon?: boolean;
    onClick?: TVoidCallback<T>;
    onDelete?: TVoidCallback<T>;
}

export const Chip = (props: IChipProps) => {
    const {
        label,
        item,
        size = ChipSize.Small,
        icon,
        withDeleteIcon = true,
        sx,
    } = props;
    const chipSize = getChipSize(size);

    const onClick = () => {
        !!props.onClick && props.onClick(item);
    };

    const onDelete = () => {
        !!props.onDelete && props.onDelete(item);
    };

    return (
        <MuiChip
            label={label}
            icon={icon}
            deleteIcon={
                withDeleteIcon ? (
                    <Icon iconName={FluentUiIconName.DismissRegular} />
                ) : (
                    <></>
                )
            }
            sx={[
                styles.shared,
                ...(chipSize.isExtraSmall ? [styles.extraSmall] : []),
                ...(chipSize.isSmall ? [styles.small] : []),
                ...(chipSize.isMedium ? [styles.medium] : []),
                ...(chipSize.isLarge ? [styles.large] : []),
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            onClick={onClick}
            onDelete={onDelete}
        />
    );
};

const getChipSize = (size?: ChipSize) => {
    return {
        isExtraSmall: size === ChipSize.ExtraSmall,
        isSmall: size === ChipSize.Small,
        isMedium: size === ChipSize.Medium,
        isLarge: size === ChipSize.Large,
    };
};

const styles = {
    shared: {
        borderRadius: 1,
        backgroundColor: "common.white",
        "&:hover": {
            backgroundColor: "common.white",
        },

        "& .MuiChip-label": {
            typography: "body1",
        },
        "& .MuiChip-icon": {
            mr: "initial",
            ml: 0,
        },
        "& .MuiChip-deleteIcon": {
            width: ICON_SIZE,
            height: ICON_SIZE,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 0.5,
            m: 0,
            "&:hover": {
                color: "text.secondary",
                backgroundColor: (theme: Theme) =>
                    alpha(
                        theme.palette.common.black,
                        theme.palette.action.hoverOpacity,
                    ),
                opacity: 1,
            },
        },
    },
    extraSmall: {
        p: 0.75,
        height: ChipSize.ExtraSmall,

        "& .MuiChip-label": {
            typography: "body2",
            p: (theme: Theme) => theme.spacing(0, 0.75),
        },
        "& .MuiChip-icon": {
            fontSize: ICON_EXTRA_SMALL_SIZE,
        },
        "& .MuiChip-deleteIcon": {
            width: ICON_EXTRA_SMALL_SIZE,
            height: ICON_EXTRA_SMALL_SIZE,
        },
    },
    small: {
        p: 1,
        height: ChipSize.Small,

        "& .MuiChip-label": {
            p: (theme: Theme) => theme.spacing(0, 1),
        },
    },
    medium: {
        p: 1.5,
        height: ChipSize.Medium,
    },
    large: {
        p: 2,
        height: ChipSize.Large,
    },
};
