import { injectable } from "inversify";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import { User } from "../model/User";
import { IUserCollection } from "./IUserCollection";
import { ConfigAccess } from "@utils/ConfigAccess";
import { IUserService } from "../service/IUserService";
import { Broadcast } from "../../../../infrastructure/broadcast/broadcast";
import { DictionaryEvents } from "../../dictionary/DictionaryEvents";

const pagination = {
    offset: 0,
    limit: 100,
};

@injectable()
export class UserCollection implements IUserCollection {
    private readonly userService: IUserService;
    private _users: Map<ObjectId, User> = new Map();

    constructor(service: IUserService) {
        this.userService = service;
        (async () => await this.requestAll())();

        Broadcast.on(
            [DictionaryEvents.onUpdated],
            async (event: any) => {
                if (event.collection === ConfigAccess.config.userDictionary) {
                    await this.updateById(event.id);
                }
            },
            null,
        );
    }

    getById(id: ObjectId): User | undefined {
        return this._users.get(id);
    }

    private async requestAll(): Promise<void> {
        let hasMore = true;
        while (hasMore) {
            const users = await this.userService.getUsers(pagination);
            if (!users.length || users.length < pagination.limit) {
                hasMore = false;
            }
            if (users.length) {
                users.forEach((user: User) => this._users.set(user.id, user));
                pagination.offset = pagination.offset + users.length;
            }
        }
    }

    private async updateById(id: ObjectId): Promise<void> {
        const user = await this.userService.getById(id);
        if (user) this._users.set(user.id, user);
    }
}
