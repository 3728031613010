import i18next from "i18next";
import { initReactI18next, WithTranslation } from "react-i18next";
import * as intervalPlural from "i18next-intervalplural-postprocessor";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import { en } from "../../app/ui/locale/en";
import { ru } from "../../app/ui/locale/ru";
import { appStorage } from "../../app/infrastructure/storage/AppStorage";

export const DEFAULT_NAMESPACE = "common";
export enum SUPPORTED_LANGUAGES {
    RU = "ru",
    EN = "en",
}

const DEFAULT_LANG = SUPPORTED_LANGUAGES.RU;

export const LOCALE = {
    ru: ruLocale,
    en: enLocale,
};

export interface IWithTranslation extends WithTranslation {}

export function getCurrentLanguage(): string {
    const storageLanguageJson = appStorage.getItem("locale");
    return storageLanguageJson !== null
        ? JSON.parse(storageLanguageJson)
        : DEFAULT_LANG;
}

i18next
    .use(initReactI18next)
    .use(intervalPlural)
    .init({
        fallbackLng: [SUPPORTED_LANGUAGES.RU, SUPPORTED_LANGUAGES.EN],
        lng: getCurrentLanguage(),
        ns: [DEFAULT_NAMESPACE],
        resources: {
            [SUPPORTED_LANGUAGES.EN]: { [DEFAULT_NAMESPACE]: en },
            [SUPPORTED_LANGUAGES.RU]: { [DEFAULT_NAMESPACE]: ru },
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
