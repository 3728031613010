import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";
import {
    FluentUiIconName,
    Icon,
    IconButtonAction,
} from "@mrs/webclient-shared-ui-lib";
import { HEADER_HEIGHT } from "@ui/theme/baseTheme/BaseTheme";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useAppMenu } from "@ui/components/shared/AppMainLayout/hook/AppMenu";

interface IScreenWrapperProps extends BoxProps {}

export const ScreenWrapper = (props: IScreenWrapperProps) => {
    const { t } = useTranslation(DEFAULT_NAMESPACE);
    const { open, onDrawerOpen } = useAppMenu();
    const { sx, children, ...other } = props;

    return (
        <Box
            sx={{
                transition: (theme) =>
                    theme.transitions.create(["margin", "width"], {
                        easing: open
                            ? theme.transitions.easing.easeOut
                            : theme.transitions.easing.sharp,
                        duration: open
                            ? theme.transitions.duration.enteringScreen
                            : theme.transitions.duration.leavingScreen,
                    }),
                ...sx,
            }}
            {...other}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    backgroundColor: "background.paper",
                    pr: 1,
                    pl: 2,
                    boxSizing: "border-box",
                    minHeight: HEADER_HEIGHT,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        m: (theme) => theme.spacing(-1, 0),
                        whiteSpace: "nowrap",
                    }}
                >
                    {!open && (
                        <IconButtonAction
                            key={"menu"}
                            item={{
                                tooltipTitle: t(
                                    "common:common.navigation.show",
                                ),
                                hotKey: "Ctrl + \\",
                                icon: (
                                    <Icon
                                        iconName={
                                            FluentUiIconName.NavigationRegular
                                        }
                                    />
                                ),
                                onClick: onDrawerOpen,
                            }}
                            sx={{
                                m: (theme) => theme.spacing(0, 2, 0, -0.75),
                            }}
                        />
                    )}
                    <Typography
                        variant={"subtitle2"}
                        color={"textPrimary"}
                        noWrap
                    >
                        {t("common:pages.settings")}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            {children}
        </Box>
    );
};
