import React, { useCallback, useEffect, useMemo, useRef } from "react";
import MuiForm from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { IChangeEvent } from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { FieldTemplate } from "./components/FieldTemplate";
import { observer } from "mobx-react";
import { CustomFormPresenter } from "./presenter/CustomFormPresenter";
import { ObjectFieldTemplate } from "./components/ObjectFieldTemplate";
import isEqual from "lodash-es/isEqual";
import { Notify } from "@utils/notify/Notify";
import { Skeletons } from "../Skeletons/Skeletons";
import { Form } from "../../../../core/context/form/model/Form";
import { RJSFValidationError } from "@rjsf/utils";
import { TVoidCallback } from "@mrs/webclient-shared-ui-lib";

const DELAY = 100;

interface ICustomFormProps {
    formName: string;
    formData?: object;
    formSchema?: Form;
    children?: React.JSX.Element;
    onChange?: TVoidCallback<object>;
    onSubmit?: TVoidCallback<object>;
    onLoad?: () => void;
    onError?: TVoidCallback<RJSFValidationError[]>;
    formRef?: React.RefObject<HTMLFormElement>;
    loader?: React.ReactElement;
}

export const CustomForm = observer((props: ICustomFormProps) => {
    const presenter = useMemo(() => new CustomFormPresenter(), []);
    const { isJsonFormReady, init, unmount } = presenter;
    const { formData, formSchema, onLoad, onError, onChange, onSubmit } = props;
    const ref = props.formRef || useRef<HTMLFormElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        init(props.formName, formSchema);
        return () => unmount();
    }, []);

    useEffect(() => {
        if (formSchema && presenter?.isFormChanged(formSchema)) {
            presenter?.updateFormSchema(formSchema);
        }
    }, [formSchema]);

    useEffect(() => {
        isJsonFormReady && onLoad && onLoad();
    }, [isJsonFormReady]);

    const _onChange = useCallback(
        async (event: IChangeEvent) => {
            const { errors, errorSchema } = event;
            await presenter?.setErrors(errors, errorSchema);
            const value = await presenter?.clearEmptyFields(event.formData);
            !isEqual(formData, value) && onChange && onChange(value);
        },
        [presenter, formData],
    );

    const _onError = useCallback(() => {
        const errorData = presenter.validateForm(ref.current);
        console.error("Smth wrong with jsonForm: ", errorData.errors);
        scrollToElementWithError();
        onError && onError(errorData.errors);
    }, [presenter]);

    const scrollToElementWithError = useCallback(() => {
        const el = document.querySelector(".has-error");
        if (el) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        } else {
            Notify.error({ message: t(`common:customForm.checkErrors`) });
        }
    }, []);

    const _onSubmit = useCallback(
        (event: IChangeEvent<object>) => {
            const errorData = presenter.validateForm(ref.current);
            if (!!errorData.errors.length) {
                _onError();
            } else if (event.formData && onSubmit) {
                onSubmit(event.formData);
            }
        },
        [presenter],
    );

    const transformErrors = useCallback(
        (errors: RJSFValidationError[]): RJSFValidationError[] => {
            return presenter ? presenter.transformErrors(errors) : errors;
        },
        [],
    );

    const renderLoader = useCallback((): React.ReactElement => {
        return props?.loader || <Skeletons />;
    }, []);

    const onBlur = useCallback(async (id: string, value: any) => {
        const node = ref.current;
        if (node) {
            const fields = id.split("_");
            fields.shift();
            const path = fields.length > 1 ? fields.join(".") : fields[0];
            setTimeout(() => presenter?.validateField(path, node), DELAY);
        }
    }, []);

    return isJsonFormReady ? (
        <MuiForm
            schema={presenter.schema}
            uiSchema={presenter.uiSchema}
            widgets={presenter.customFormWidgets}
            fields={presenter.customFormFields}
            onError={_onError}
            onChange={_onChange}
            onSubmit={_onSubmit}
            onBlur={onBlur}
            showErrorList={false}
            experimental_defaultFormStateBehavior={{
                emptyObjectFields: "skipDefaults",
            }}
            transformErrors={transformErrors}
            validator={validator}
            templates={{ FieldTemplate, ObjectFieldTemplate }}
            formData={formData}
            noHtml5Validate
            // @ts-ignore
            ref={ref}
        >
            {props.children}
        </MuiForm>
    ) : (
        renderLoader()
    );
});
