import { IFindOptions } from "@utils/IFindOptions";
import { Changes } from "../../types/Changes";
import { EntityMapper } from "../../mapper/EntityMapper";
import { IWithId, ObjectId, TQueryParams } from "@mrs/webclient-shared-ui-lib";
import { ICRUDRepository } from "../ICRUDRepository";
import { IApiService } from "../../apiService/IApiService";
import { RequestUtils } from "@utils/request/RequestUtils";
import { injectable } from "inversify";

//TODO add caching
@injectable()
export abstract class CRUDRepository<
    T extends IWithId,
    TypeDTO extends IWithId,
    CreateDTO
> implements ICRUDRepository<T, TypeDTO, CreateDTO> {
    protected apiService: IApiService<TypeDTO, CreateDTO>;

    protected constructor(apiService: IApiService<TypeDTO, CreateDTO>) {
        this.apiService = apiService;
    }

    public async create(createDto: CreateDTO): Promise<T> {
        const newItem: TypeDTO = await this.apiService.createItem(createDto);

        return this.toEntity(newItem);
    }

    public async update(entity: T, changes: Changes<T>): Promise<T> {
        const changedEntity = EntityMapper.cloneEntity(entity);
        EntityMapper.updateFields(changedEntity, changes);

        const patch = RequestUtils.createPatch(
            EntityMapper.toPlainObject(entity) as TypeDTO,
            EntityMapper.toPlainObject(changedEntity) as TypeDTO,
        );

        await this.apiService.patchItem([entity.id], patch);

        return changedEntity;
    }

    public async remove(ids: ObjectId[]): Promise<boolean> {
        const res = await this.apiService.deleteItem(ids);
        return !!res;
    }

    public async getById(id: ObjectId): Promise<T | undefined> {
        if (!id) return;

        const items = await this.sendGetRequest({ ids: [id], limit: 1 });
        let item: TypeDTO | undefined = items[0];

        if (!item) return;

        return this.toEntity(item);
    }

    public async getByIds(
        ids: ObjectId[],
        options?: IFindOptions,
    ): Promise<T[]> {
        if (!ids || ids.length === 0) return Promise.resolve([]);
        const params = this.mergeQueryParams({ ids }, options);

        let items: TypeDTO[] = await this.sendGetRequest(params);

        return this.toEntities(items);
    }

    public async getAll(options?: IFindOptions): Promise<T[]> {
        const params = this.mergeQueryParams({}, options);
        const items = await this.sendGetRequest(params);

        return this.toEntities(items);
    }

    public abstract toEntity(data: TypeDTO): T;

    public abstract toEntities(data: TypeDTO[]): T[];

    protected getRestoreModeParams(): TQueryParams {
        return { includeArchived: true };
    }

    protected mergeQueryParams(
        queryParams: TQueryParams,
        options?: IFindOptions,
    ): TQueryParams {
        const pagination = options ? options.pagination : {};
        const sort = RequestUtils.createSortOptions(options);
        const includeArchived = options && options.includeArchived;
        const withNumericOrdering = options && options.withNumericOrdering;
        return {
            ...queryParams,
            ...pagination,
            ...sort,
            includeArchived,
            withNumericOrdering,
        };
    }

    private sendGetRequest(params: TQueryParams): Promise<TypeDTO[]> {
        return this.apiService.getItems({
            ...params,
            ...this.getRestoreModeParams(),
        });
    }
}
