import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { ThemeUtils } from "@ui/theme/utils/ThemeUtils";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";

interface IButtonVariantProps extends IFormWidgetWrapped {}

export const ButtonVariant = (props: IButtonVariantProps) => {
    const theme = useTheme();
    const { id, schema, options, value, onBlur } = props;
    const { enumOptions, enumDisabled } = options;

    const onChange = React.useCallback(
        async (val: any) => {
            const valueBySchemaType =
                schema.type === "boolean" ? val !== "false" : val;
            await props.onChange(valueBySchemaType);
            onBlur(id, valueBySchemaType);
        },
        [id, schema, onBlur, props.onChange],
    );

    const toThemeColor = React.useCallback((val?: string): string => {
        return ThemeUtils.toThemeColor(theme, val);
    }, []);

    return (
        <Grid container spacing={1}>
            {(enumOptions as any[]).map((option, index) => {
                const itemDisabled =
                    props.disabled ||
                    (!!enumDisabled &&
                        (enumDisabled as any).indexOf(option.value) !== -1);
                const isSelectedOption = option.value === value;
                return (
                    <Grid
                        key={index}
                        item
                        sm={(enumOptions as any[])?.length % 2 === 0 ? 6 : 4}
                        xs={12}
                    >
                        <Button
                            onClick={() => onChange(option.value)}
                            variant={
                                isSelectedOption ? "contained" : "outlined"
                            }
                            color={isSelectedOption ? "primary" : "secondary"}
                            fullWidth
                            size={"large"}
                            disabled={itemDisabled}
                            style={
                                isSelectedOption
                                    ? {
                                          backgroundColor: toThemeColor(
                                              option.schema?.color || "",
                                          ),
                                      }
                                    : undefined
                            }
                        >
                            {option.label}
                        </Button>
                    </Grid>
                );
            })}
        </Grid>
    );
};
