import React from "react";
import Box from "@mui/material/Box";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { Chip, IChipProps } from "../../../shared/Chip/Chip";

interface IFilterChipProps extends IChipProps {
    tooltipTitle?: string;
    tooltipProps?: TooltipProps;
}

export const FilterChip = (props: IFilterChipProps) => {
    const { tooltipTitle, tooltipProps, ...other } = props;

    return (
        <Tooltip
            title={tooltipTitle || ""}
            placement={"bottom"}
            {...tooltipProps}
        >
            <Box
                component={"span"}
                sx={{
                    mr: 1,
                    "&:last-child": {
                        mr: 0,
                    },
                }}
            >
                <Chip {...other} />
            </Box>
        </Tooltip>
    );
};
