import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";

interface IRadioVariantProps extends IFormWidgetWrapped {}

export const RadioVariant = (props: IRadioVariantProps) => {
    const { id, schema, options, value, onChange, onBlur, onFocus } = props;
    const { enumOptions, enumDisabled } = options;

    const _onChange = React.useCallback(
        async ({}, val: any) => {
            const valueBySchemaType =
                schema.type === "boolean" ? val !== "false" : val;
            await onChange(valueBySchemaType);
            onBlur(id, valueBySchemaType);
        },
        [id, schema, onChange, onBlur],
    );

    const _onBlur = React.useCallback(
        ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
            onBlur(id, value),
        [id, onBlur],
    );

    const _onFocus = React.useCallback(
        ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
            onFocus(id, value),
        [id, onFocus],
    );

    const row = options ? options.inline : false;

    return (
        <RadioGroup
            value={`${value}`}
            row={row as boolean}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
        >
            {(enumOptions as any).map((option: any, i: number) => {
                const itemDisabled =
                    enumDisabled &&
                    (enumDisabled as any).indexOf(option.value) !== -1;

                return (
                    <FormControlLabel
                        control={
                            <Radio color={"primary"} key={i} size={"small"} />
                        }
                        label={`${option.label}`}
                        value={`${option.value}`}
                        key={i}
                        disabled={props.disabled || !!itemDisabled}
                    />
                );
            })}
        </RadioGroup>
    );
};
