import { ObjectType } from "../../../../ObjectType";
import { AbstractApplicationService } from "../../../../sharedKernel/service/impl/AbstractApplicationService";
import { IPostService } from "../IPostService";
import { injectable } from "inversify";
import { IPostRepository } from "../../repository/IPostRepository";
import {
    ObjectId,
    TQueryParams,
    IPostDTO,
    IPostCreateDTO,
} from "@mrs/webclient-shared-ui-lib";
import { Changes } from "../../../../sharedKernel/types/Changes";

@injectable()
export class PostService
    extends AbstractApplicationService<
        IPostDTO,
        IPostDTO,
        IPostCreateDTO,
        IPostRepository
    >
    implements IPostService {
    constructor(repository: IPostRepository) {
        super(repository, ObjectType.POST);
    }

    async getPosts(params: TQueryParams): Promise<IPostDTO[]> {
        return this.repository.getPosts(params);
    }

    async getPostById(id: ObjectId): Promise<IPostDTO> {
        return this.repository.getPostById(id);
    }

    async createPost(post: IPostCreateDTO): Promise<IPostDTO> {
        return this.repository.create(post);
    }

    async updatePost(
        entity: IPostDTO,
        changes: Changes<IPostDTO>,
    ): Promise<IPostDTO> {
        return this.repository.updatePost(entity, changes);
    }
}
