import React from "react";
import Box from "@mui/material/Box";
import { DialogProps } from "@mui/material/Dialog";
import {
    IIconButtonAction,
    UniversalDialog,
} from "@mrs/webclient-shared-ui-lib";

const DIALOG_SPLITTER_TITLE_HEIGHT = 48;
const DIALOG_NAVIGATION_BLOCK_WIDTH = 256;

export interface IDialogWithSplitterProps {
    title: string;
    isOpen: boolean;
    dialogProps?: Partial<DialogProps>;
    onClose: () => void;
    navigationBlockContent: React.ReactNode;
    children?: React.ReactNode;
    toolbarActions?: IIconButtonAction[];
}

export const DialogWithSplitter = (props: IDialogWithSplitterProps) => {
    const {
        isOpen,
        onClose,
        navigationBlockContent,
        children,
        toolbarActions,
    } = props;

    return (
        <UniversalDialog
            isOpen={isOpen}
            title={props.title}
            onClose={onClose}
            toolbarActions={toolbarActions}
            dialogProps={{
                disableEnforceFocus: true,
                sx: {
                    "& .MuiDialog-paper": {
                        overflow: "hidden",
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                    },
                },
                ...props.dialogProps,
            }}
            dialogContentProps={{
                sx: {
                    display: "flex",
                    p: 0,
                    height: `calc(100% - ${DIALOG_SPLITTER_TITLE_HEIGHT}px)`,
                    backgroundColor: "inherit",

                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "background.paper",
                    width: DIALOG_NAVIGATION_BLOCK_WIDTH,
                    position: "inherit",
                    borderRight: (theme) =>
                        `1px solid ${theme.palette.divider}`,
                    boxSizing: "border-box",
                }}
            >
                {navigationBlockContent}
            </Box>
            <Box
                sx={{
                    width: `calc(100% - ${DIALOG_NAVIGATION_BLOCK_WIDTH}px)`,
                    height: "100%",
                }}
                children={children}
            />
        </UniversalDialog>
    );
};
