import { Operation } from "fast-json-patch";
import { Changes } from "../../types/Changes";
import { IRestService } from "../../../../infrastructure/http/IRestService";
import { EntityMapper } from "../../mapper/EntityMapper";
import { IWithId, ObjectId, TQueryParams } from "@mrs/webclient-shared-ui-lib";
import { IApiService } from "../IApiService";
import { injectable } from "inversify";

@injectable()
export class BaseApiService<T extends IWithId, C = any>
    implements IApiService<T, C> {
    restService: IRestService;
    resourcePath: string;

    constructor(restService: IRestService, resourcePath: string) {
        this.restService = restService;
        this.resourcePath = resourcePath;
    }

    async getItems(params: TQueryParams): Promise<T[]> {
        return this.restService
            .get(this.resourcePath, { queryParams: params })
            .asPromise();
    }

    async getItemById(id: ObjectId): Promise<T> {
        return this.restService.get(`${this.resourcePath}/${id}`).asPromise();
    }

    async search(params: TQueryParams): Promise<T[]> {
        return this.restService
            .get(`${this.resourcePath}/search`, {
                queryParams: params,
            })
            .asPromise();
    }

    async createItem(createDTO: C): Promise<T> {
        return this.restService
            .post(this.getPath(), { data: createDTO as Partial<C> })
            .asPromise();
    }

    async updateItem(changes: Changes<T>): Promise<T> {
        const changesObject = EntityMapper.toPlainObject(changes);
        return this.restService
            .put(this.getPath(changes.id), {
                data: changesObject,
            })
            .asPromise();
    }

    async patchItem(id: ObjectId[], patches: Operation[]): Promise<T[]> {
        const payload = {
            ids: id,
            patch: patches,
        };
        return this.restService
            .patch(this.getPath(), { data: payload })
            .asPromise();
    }

    async deleteItem(ids: ObjectId[]): Promise<ObjectId[]> {
        return this.restService
            .delete(this.getPath(), { data: ids })
            .asPromise();
    }

    getPath(id: ObjectId = "") {
        return this.resourcePath + id;
    }
}
