import { DiContainer } from "../../AppDiContainer";
import { IRestService } from "../../../infrastructure/http/IRestService";
import { ContainerModule } from "inversify";
import { DocumentService } from "./service/impl/DocumentService";
import { DocumentRepository } from "./repository/impl/DocumentRepository";
import { DocumentApiService } from "./apiService/impl/DocumentApiService";
import { IDocumentController } from "./controller/IDocumentController";
import { DocumentController } from "./controller/impl/DocumentController";
import { DocumentDiType } from "./diType";
import { DOCUMENT_RESOURCE_NAME } from "@lib/upload/processors/document-attachment";
import { EventType } from "@mrs/webclient-shared-ui-lib";
import { DocumentEvents } from "./DocumentEvents";
import { EventsBroker } from "../events/EventsBroker";
import { ObjectType } from "../../ObjectType";

export class DocumentContext {
    start(restService: IRestService): void {
        const documentModule = new ContainerModule((bind) => {
            const apiService = new DocumentApiService(
                restService,
                DOCUMENT_RESOURCE_NAME,
            );
            const repository = new DocumentRepository(apiService);
            const service = new DocumentService(repository);
            const controller = new DocumentController(service);

            bind<IDocumentController>(
                DocumentDiType.IDocumentController,
            ).toConstantValue(controller);
        });

        DiContainer.load(documentModule);

        const eventNamesMap: Map<EventType, string> = new Map();
        eventNamesMap.set(EventType.CREATE, DocumentEvents.onCreated);
        eventNamesMap.set(EventType.UPDATE, DocumentEvents.onUpdated);
        eventNamesMap.set(EventType.DELETE, DocumentEvents.onRemove);
        EventsBroker.setEventNames(ObjectType.DOCUMENT, eventNamesMap);
    }
}
