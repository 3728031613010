import { CRUDRepository } from "../../../../sharedKernel/repository/impl/CRUDRepository";
import { IDocumentCreateDTO } from "../../dto/IDocumentCreateDTO";
import { IDocumentRepository } from "../IDocumentRepository";
import { IDocumentApiService } from "../../apiService/IDocumentApiService";
import {
    IActivateMultipartUploadCreateDTO,
    IActivateMultipartUploadDTO,
    IDocumentDTO,
    IFinalizeMultipartUploadCreateDTO,
} from "@mrs/webclient-shared-ui-lib";

export class DocumentRepository
    extends CRUDRepository<IDocumentDTO, IDocumentDTO, IDocumentCreateDTO>
    implements IDocumentRepository {
    protected apiService: IDocumentApiService;

    constructor(service: IDocumentApiService) {
        super(service);
        this.apiService = service;
    }

    async download(url: string): Promise<Blob> {
        return this.apiService.download(url);
    }

    async activateMultipartUpload(
        data: IActivateMultipartUploadCreateDTO,
    ): Promise<IActivateMultipartUploadDTO> {
        return this.apiService.activateMultipartUpload(data);
    }

    async finalizeMultipartUpload(
        data: IFinalizeMultipartUploadCreateDTO,
    ): Promise<void> {
        await this.apiService.finalizeMultipartUpload(data);
    }

    toEntities(data: IDocumentDTO[]): IDocumentDTO[] {
        return data;
    }

    toEntity(data: IDocumentDTO): IDocumentDTO {
        return data;
    }
}
