import React from "react";
import { observer } from "mobx-react";
import { LoginContent } from "../loginContent/LoginContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";
import { LoginErrorCodes } from "../../../../../core/context/login/dto/LoginErrorCodes";

interface ILoginErrorProps {
    errorCode: LoginErrorCodes | null;
    tryLogin(): void;
}

export const LoginError = observer(
    ({ errorCode, tryLogin }: ILoginErrorProps) => {
        const { t } = useTranslation(DEFAULT_NAMESPACE);

        return (
            <LoginContent
                content={
                    !!errorCode ? (
                        <Typography
                            variant={"body1"}
                            textAlign={"center"}
                            sx={{
                                letterSpacing: "0.3px",
                                p: (theme) => theme.spacing(0, 4),
                            }}
                        >
                            {t(`login.loginErrorMessages.${errorCode}`)}
                        </Typography>
                    ) : (
                        <div />
                    )
                }
                title={t("login.loginError")}
                buttonLabel={t("login.tryLoginButton")}
                onClickButton={tryLogin}
            />
        );
    },
);
