import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {
    DialogTitleContent,
    FluentUiIconName,
    Icon,
    TVoidCallback,
} from "@mrs/webclient-shared-ui-lib";
import { observer } from "mobx-react";
import { CustomForm } from "../CustomForm/CustomForm";
import { Form } from "../../../../core/context/form/model/Form";
import { HEADER_HEIGHT } from "@ui/theme/baseTheme/BaseTheme";

interface IBaseFormInstanceCreationDialogProps {
    isOpen: boolean;
    isShowSaveButton: boolean;
    isSaving: boolean;
    title: string;
    form: Form | null;
    formData: object;
    onSave: () => void;
    onClose: () => void;
    onChange: TVoidCallback<object>;
    showSaveButton: () => void;
}

export const BaseFormInstanceCreationDialog = observer(
    (props: IBaseFormInstanceCreationDialogProps) => {
        const { t } = useTranslation();
        const { form, formData, onClose } = props;

        return (
            <Dialog
                open={props.isOpen}
                fullScreen
                disableEnforceFocus
                sx={{
                    "& .MuiDialog-paper": {
                        backgroundColor: "background.default",
                    },
                }}
            >
                <DialogTitleContent
                    onBack={onClose}
                    onClose={onClose}
                    withBackIcon
                    withCloseIcon={false}
                    backIcon={
                        <Icon iconName={FluentUiIconName.ArrowLeftRegular} />
                    }
                    dialogTitleChildren={
                        <Typography
                            variant={"subtitle2"}
                            sx={{
                                display: "flex",
                                flex: "1 1 auto",
                                justifyContent: "center",
                            }}
                        >
                            {props.title}
                        </Typography>
                    }
                    sx={{
                        justifyContent: "space-between",
                        alignContent: "flex-end",
                        height: HEADER_HEIGHT,
                        position: "sticky",
                        top: 0,
                        zIndex: 5,
                        p: 2,
                        bgcolor: "background.paper",
                        borderBottom: (theme: any) =>
                            `1px solid ${theme.palette.divider}`,
                        "& > .backButton": {
                            ml: -1,
                        },
                    }}
                />
                <DialogContent sx={{ p: 2 }}>
                    <Box maxWidth={600} m={"0 auto"}>
                        {form && (
                            <CustomForm
                                formName={form.name}
                                formSchema={form}
                                formData={formData}
                                onChange={props.onChange}
                                onSubmit={props.onSave}
                                onLoad={props.showSaveButton}
                            >
                                {props.isShowSaveButton ? (
                                    <Button
                                        type={"submit"}
                                        variant={"contained"}
                                        color={"primary"}
                                        size={"large"}
                                        sx={{ width: "100%", mt: 2 }}
                                        data-ui-test={"applyCreateButton"}
                                    >
                                        {props.isSaving ? (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "background.paper",
                                                }}
                                            />
                                        ) : (
                                            t("common:common.save")
                                        )}
                                    </Button>
                                ) : (
                                    <div />
                                )}
                            </CustomForm>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        );
    },
);
