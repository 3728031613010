import {
    INotification,
    NotifyVariant,
} from "@ui/components/shared/Notifier/INotify";
import { NotifierPresenter } from "@ui/components/shared/Notifier/presenter/NotifierPresenter";

type TNotifyParams = Omit<INotification, "variant">;

export class Notify {
    public static error(params: TNotifyParams): void {
        Notify.showNotification({
            ...params,
            variant: NotifyVariant.Error,
        });
    }

    static success(params: TNotifyParams): void {
        Notify.showNotification({
            ...params,
            variant: NotifyVariant.Success,
        });
    }

    static info(params: TNotifyParams): void {
        Notify.showNotification({
            ...params,
            variant: NotifyVariant.Info,
        });
    }

    private static showNotification(notification: INotification) {
        NotifierPresenter.showNotification(notification);
    }
}
