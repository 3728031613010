import {
    IRequestParams,
    IRestService,
} from "../../../app/infrastructure/http/IRestService";
import { RestService } from "../../../app/infrastructure/http/RestService";
import { RequestUtils } from "@utils/request/RequestUtils";
import { HttpRequest } from "../../../app/infrastructure/http/model/HttpRequest";
import get from "lodash-es/get";
import set from "lodash-es/set";
import cloneDeep from "lodash-es/cloneDeep";
import {
    ObjectToUpload,
    UploadType,
    ArrayUtils,
} from "@mrs/webclient-shared-ui-lib";
import { guid } from "@utils/AppInstanceId";
import { Broadcast } from "../../../app/infrastructure/broadcast/broadcast";
import { UploaderEvents } from "@lib/upload/UploaderEvents";
import { IQueueRequest } from "@lib/queue/model/IQueueRequest";
import { IRequestQueue } from "@lib/queue/IRequestQueue";
import { RequestQueue } from "@lib/queue/RequestQueue";

interface IUploadData {
    id: string;
    data: ObjectToUpload;
}

export class FileAttachClass {
    private readonly uploadPath: string;
    private readonly restService: IRestService;
    private uploadQueue: IRequestQueue;
    private storeHashMap: any;
    private queue: IUploadData[] = [];

    constructor(uploadPath: string) {
        this.uploadPath = uploadPath;
        this.restService = new RestService();
        this.uploadQueue = new RequestQueue();
        this.storeHashMap = {};
    }

    getUploadPathFromUrl() {
        return `${this.uploadPath}/url`;
    }

    upload(data: ObjectToUpload) {
        const item: IUploadData = {
            id: guid(),
            data,
        };

        this.checkData(item);
        this.queue.push(item);

        Broadcast.trig(UploaderEvents.onAdded, cloneDeep(item));
        this.preprocess(item);
    }

    checkData(item: IUploadData) {
        const id = item.id;
        const objectData = item.data.object;
        if (!objectData) return;

        set(this.storeHashMap, id, objectData);
    }

    preprocess(item: IUploadData) {
        const id = item.id;
        const objectData = this.getStoredData(id) || item.data.object;

        set(this.storeHashMap, id, objectData);
        this.startUpload();
    }

    startUpload() {
        for (const item of this.queue) {
            this.startUploadItem(item);
        }
    }

    startUploadItem(item: IUploadData) {
        const id = item.id;
        if (!this.uploadQueue.isRequestExist(id)) {
            const params = {
                onUploadProgress(event: ProgressEvent) {
                    const data = {
                        id: item.data.additionalData.createDocument.id,
                        progressTotal: event.total,
                        progressDone: event.loaded,
                    };
                    Broadcast.trig(UploaderEvents.onProgress, data);
                },
            };
            const reqParam: IQueueRequest = {
                id,
                execute: () => {
                    return this.request(item, params)
                        .then((data: object) => {
                            this.onDone(item, data);
                        })
                        .catch(() => {
                            this.onFail(item);
                        });
                },
            };
            this.uploadQueue.add(reqParam);
        }
        this.uploadQueue.start();
    }

    request(item: IUploadData, params: IRequestParams = {}) {
        const data = item.data;
        const type = data.type;
        let result: HttpRequest;

        const storedData = this.getStoredData(item.id) || data.object;

        params.headers = RequestUtils.getHeaders();

        const sendData = { data: storedData };
        const additionalData = {
            additionalData: data.additionalData,
        };
        const uploadParams = { ...sendData, ...params };

        if (type === UploadType.UPLOAD_FROM_URL) {
            const path = this.getUploadPathFromUrl();
            result = this.restService.uploadFromUrl(path, {
                ...uploadParams,
                ...additionalData,
            });
        } else if (type === UploadType.DOCUMENT_SELECT) {
            result = this.restService.upload(this.uploadPath, {
                ...uploadParams,
                ...additionalData,
            });
        } else {
            result = this.restService.upload(this.uploadPath, uploadParams);
        }

        return result;
    }

    onDone(item: IUploadData, data: object) {
        this.remove(item);

        const event = {
            item: cloneDeep(item),
            data,
        };
        Broadcast.trig(UploaderEvents.onDoneUpload, event);
        setTimeout(() => this.startUpload(), 200);
    }

    onFail(item: IUploadData) {
        this.remove(item);

        Broadcast.trig(UploaderEvents.onFail, item);
    }

    remove(item: IUploadData) {
        const id = item.id;
        if (!id) return;

        this.uploadQueue.removeById(id);
        ArrayUtils.removeItemById(this.queue, id);
        delete this.storeHashMap[id];
    }

    getStoredData(id: string) {
        return get(this.storeHashMap, id);
    }
}
