/*
 * broadcast module
 * just callback sender
 *
 * */

// import { makeLogger } from "./logger";

/*
 *
 * how to use?
 *
 * 1) messages can be with any symbol, except ".", because it is identifier of namespace
 *
 * 2) namespaces separated from namespaces
 *
 * 3) namespace MUST BE start from "." symbol. it's for separate from messages
 *
 * for examle:
 *
 * // will call on each trigger of 'my-event'
 * broadcast.on('my-event', function(params){
 *   console.log(params.params1); //see 'ohh now'
 * });
 *
 * // 'myHandler' will call only once
 * broadcast.one('my-event', '.my-namespace', myHandler);
 *
 * // bind to different messages to 'myHandler'
 * broadcast.on(['my-event1', 'my-event2'], '.my-namespace', myHandler);
 *
 *
 * // unbind all callbacks from MESSAGE
 * broadcast.off('my-event');
 *
 * unbind all callbacks from NAMESPACE
 * broadcast.off('.namespace');
 *
 * // will trigger immedially from current point
 * broadcast.trig('my-event', {param1: 'ohh now', param2: 2}); // or without params
 *
 * // will trigger after current callstack is done
 * broadcast.aTrig('my-event', params);
 *
 *
 * // will create namespace evs with detection of collisions
 * var evs = broadcast.events('my-namespace', {
 *  myEv1: 'myEv1',
 *  myEv2: 'myEv2'
 * })
 *
 * broadcast.trig(evs.myEv1); - will trigger message
 *
 * // will return evs namespace from other places
 * var evs = broadcast.events('my-namespace');
 *
 *
 * */

// split consts
import get from "lodash-es/get";
import set from "lodash-es/set";

const MSG_SPLIT = " ";

class BroadcastClass {
    private map: object;
    private eventsMap: object;
    private broadName: string;
    private created: Date;

    constructor(name: string) {
        this.map = {};

        // map of all registered events
        this.eventsMap = {};
        this.created = new Date();
        this.broadName = name;

        // this._logger = makeLogger('broadcast');
    }

    // create instance of a broadcaster
    //
    // name - just a name for separate different broadcasts

    // Returns instance of broadcast
    instance(name: string) {
        return new BroadcastClass(name);
    }

    // bind to each emit of message
    //
    // msgs - messages

    // callback - method, when messages fires
    on(
        msgs: any,
        namespace: any,
        userCallback: any,
        context: any = null,
        highPriority: any = false,
    ) {
        if (typeof namespace == "function") {
            highPriority = context;
            context = userCallback;
            userCallback = namespace;
            namespace = null;
        }

        if (namespace && namespace[0] !== ".") {
            throw new Error(
                "#" +
                    this.broadName +
                    " - broadcast can't use namespaces without '.' dot",
            );
        }

        var map: any = this.map;

        this._processMessages(msgs, function(msg: any) {
            function callback() {
                //userCallback();
            }

            callback._context = context;
            callback._link = userCallback;
            callback._name = userCallback.name; // to defined, from what callback it was

            if (userCallback._once) {
                //remove from '.one()' case
                callback._once = userCallback._once;
                userCallback["_once"] = null;
                delete userCallback["_once"];
            }

            var targets = !map[msg] ? (map[msg] = []) : map[msg];
            callback._dirty = false;
            namespace && (callback._namespace = namespace);
            highPriority
                ? targets.splice(0, 0, callback)
                : targets.push(callback);
        });
        return this;
    }

    // bind only one emit
    //
    // msgs - messages

    // callback - method, when messages fires
    one(msg: any, namespace: any, callback: any, context = null) {
        if (typeof namespace == "function") {
            context = callback;
            callback = namespace;
            namespace = null;
        }
        if (callback) {
            callback._once = true;
            this.on(msg, namespace, callback, context);
        }
        return this;
    }

    // unbind messages
    //
    // msgs - messages

    // callback - method, when messages fires
    off(msgs: any, namespace: any, userCallback: any) {
        if (!namespace && !userCallback && msgs && msgs[0] === ".") {
            // this is case for off callbacks with one namespace
            namespace = msgs;
            this._cleanByNamespace(this.map, namespace);
        } else {
            // normal way
            if (typeof namespace === "function") {
                userCallback = namespace;
                namespace = "";
            }
            const map: any = this.map;
            const cleanAll = !namespace && !userCallback;
            this._processMessages(msgs, (msg: any) => {
                const targets = map[msg];
                if (targets) {
                    // set all callbacks to dirty and remove them all
                    let canClean = false;
                    for (let i = 0, l = targets.length; i < l; i++) {
                        const tCallback = targets[i];
                        if (
                            cleanAll ||
                            tCallback._namespace == namespace ||
                            tCallback._link == userCallback
                        ) {
                            tCallback._dirty = true;
                            canClean = true;
                        }
                    }

                    // dirty only one callback, or all callbacks defined by namespace, and remove after
                    canClean &&
                        this._clearTargetsOfDirt(
                            targets,
                            msg,
                            map,
                            namespace,
                            userCallback,
                        );
                }
            });
        }
        return this;
    }

    // trigger event with params
    //
    // msg - string message

    // params - event params
    trig(msg: string, param1: any, param2: any = null, param3: any = null) {
        const targets = get(this.map, msg);
        if (targets) {
            for (let i = 0, l = targets.length; i < l; i++) {
                const callback = targets[i];
                if (!callback._dirty) {
                    const handler = callback._link;
                    const context = callback._context;
                    const res = handler.call(context, param1, param2, param3);
                    if (callback._once) {
                        // remove them
                        this._cleanCallback(callback);
                        targets.splice(i, 1);
                        l--;
                        i--;
                    }
                    if (res === false) {
                        break;
                    }
                }
            }

            if (targets.length == 0) {
                // heed check `targets.length`, because when trigger event, handler can bind again new event as cyclic
                // if all callbacks was .one, remove targets from map
                set(this.map, msg, null);
                // @ts-ignore
                delete this.map[msg];
            }
        }
        return this;
    }

    // async trigger call
    aTrig() {
        let args = arguments;
        setTimeout(() => {
            // @ts-ignore
            this.trig(args);
            // @ts-ignore
            args = null;
        }, 0);
        return this;
    }

    // clean all events
    clean() {
        const map = this.map;

        (function() {
            for (let key in map) {
                const targets = get(map, key);
                for (let i = targets.length - 1; i >= 0; i--) {
                    const callback = targets[i];
                    callback._dirty = true;
                }
            }
        })();

        // at second, after callstack done, remove them all
        setTimeout(() => {
            for (let key in map) {
                const targets = get(map, key);
                for (let i = targets.length - 1; i >= 0; i--) {
                    const callback = targets[i];
                    if (callback._dirty) {
                        this._cleanCallback(callback);
                        targets.splice(i, 1);
                    }
                }
                if (targets.length == 0) {
                    set(map, key, null);
                    // @ts-ignore
                    delete map[key];
                }
            }
        }, 0);

        return this;
    }

    // set events
    events(evName: any, events: any) {
        if (events) {
            //setter
            this._addEvents(evName, events);
        }

        return this.getEvents(evName);
    }

    // return events by key
    getEvents(evName: string, opt?: any) {
        const eventsMap = this.eventsMap;
        const map = get(eventsMap, evName);
        if (!map) {
            set(eventsMap, evName, {});
        }
        if (opt !== undefined) {
            // trying to use get as set!
            this._showError("trying to use getEvents as setEvent!");
        }
        return map;
    }

    // return all events map
    getAllEvents() {
        return this.eventsMap;
    }

    // split messages string and call callback for each
    //
    // msgs - array(or string) of messages. can be as ['message1', 'message2'] or 'message1 message2'
    // callback - method for processing single message
    _processMessages(msgs: any, callback: any) {
        typeof msgs == "string" && (msgs = msgs.split(MSG_SPLIT));
        if (msgs instanceof Array) {
            for (let i = 0, l = msgs.length; i < l; i++) {
                const msg = msgs[i];
                if (!msg || msg == "undefined") {
                    this._showWarning(
                        "some event not dispatched, please check it!",
                    );
                } else {
                    callback(msg);
                }
            }
        } else {
            this._showWarning(
                "something wrong with messages, please check it!",
            );
        }
    }

    // just remove flags from original callback for clearify
    _cleanCallback(callback: any) {
        callback._dirty = null;
        callback._once = null;
        callback._namespace = null;
        callback._context = null;
    }

    // clean all targets, what was dirty
    //
    // targets - link to callbacks array
    // msg - msg for targets
    // map - current map of broadcaster
    // uCallback - single dirty clean for this callback only
    // namespace - just namespace
    _clearTargetsOfDirt(
        targets: any,
        msg: any,
        map: any,
        namespace: any,
        uCallback: any,
    ) {
        //setTimeout need for leave callstack as is.

        // !uCallback && namespace - remove all by namespace
        // !namespace && uCallback - remove all from map with single callback
        // !namespace && !uCallback - remove all dirty from targets

        const notCallAndName = !uCallback && !!namespace;
        const notNameAndCall = !namespace && !!uCallback;
        const notAll = !namespace && !uCallback;
        const all = !!namespace && !!uCallback;

        setTimeout(() => {
            // clean all dirty callbacks after callstack done
            for (let i = targets.length - 1; i >= 0; i--) {
                const tarCall = targets[i];
                if (tarCall._dirty) {
                    if (
                        notAll ||
                        (notCallAndName && tarCall._namespace == namespace) ||
                        (notNameAndCall && tarCall._link == uCallback) ||
                        (all &&
                            tarCall._link == uCallback &&
                            tarCall._namespace == namespace)
                    ) {
                        this._cleanCallback(tarCall);
                        targets.splice(i, 1);
                    }
                }
            }
            if (targets.length == 0) {
                map[msg] = null;
                delete map[msg];
            }
        }, 0);
    }

    // drop all namespaced callbacks
    //
    // map - just map of broadcast instance
    // namespace - namespace for clean
    _cleanByNamespace(map: any, namespace: any) {
        // at first, mark all offcallbacks as dirty
        (function() {
            for (let key in map) {
                const targets = map[key];
                for (let i = targets.length - 1; i >= 0; i--) {
                    const callback = targets[i];
                    if (callback._namespace === namespace) {
                        callback._dirty = true;
                    }
                }
            }
        })();

        // at second, after callstack done, remove them all
        setTimeout(() => {
            for (let key in map) {
                const targets = map[key];
                for (let i = targets.length - 1; i >= 0; i--) {
                    const callback = targets[i];
                    if (callback._dirty && callback._namespace === namespace) {
                        this._cleanCallback(callback);
                        targets.splice(i, 1);
                    }
                }
                if (targets.length == 0) {
                    map[key] = null;
                    delete map[key];
                }
            }
        }, 0);
    }

    // checking event exist
    //
    // evName - event name
    // events - where need to check
    _isEventsExists(evName: string, events: any) {
        const map = get(this.eventsMap, evName);
        let haveSame = false;
        if (map) {
            let errorMsg = "";
            for (let key in events) {
                if (haveSame) {
                    break;
                }
                if (map[key]) {
                    haveSame = true;
                    errorMsg = '"' + key + '" already exists';
                    break;
                }
                for (let mk in map) {
                    if (map[mk] == events[key]) {
                        errorMsg = '"' + key + '" already exists';
                        haveSame = true;
                        break;
                    }
                }
            }

            if (haveSame) {
                const msg = 'problems with "' + evName + '". event ' + errorMsg;
                this._showError(msg);
            }
        }

        return haveSame;
    }

    // add new event names
    //
    // evName - event name
    // events - events map
    _addEvents(evName: string, events: any) {
        const eventsMap = this.eventsMap;
        const map = get(eventsMap, evName) || {};
        this._checkNewEvents(evName, events);
        if (!this._isEventsExists(evName, events)) {
            for (let key in events) {
                map[key] = evName + "#" + events[key];
            }
            set(eventsMap, evName, map);
        }
    }

    // checking new event for naming
    _checkNewEvents(evName: any, events: any) {
        let map: object | null = {};
        for (let key in events) {
            const val = events[key];
            if (get(map, val)) {
                const msg =
                    'problems with "' +
                    evName +
                    '". event ' +
                    val +
                    " trying to define already existed event name";
                this._showError(msg);
            }
            set(map, val, true);
        }
        map = null;
    }

    _showWarning(msg: string) {
        // this._logger.warning(msg);
        console.warn(msg);
    }

    _showError(msg: string) {
        // this._logger.error(msg);
        console.error(msg);
    }
}

export const Broadcast = new BroadcastClass("core");
