import React from "react";
import { useTranslation } from "react-i18next";
import { IAddFormInstanceDialogPresenter } from "./presenter/IAddFormInstanceDialogPresenter";
import { observer } from "mobx-react";
import { BaseFormInstanceCreationDialog } from "../../shared/BaseFormInstanceCreationDialog/BaseFormInstanceCreationDialog";

interface IAddFormInstanceDialogProps {
    presenter: IAddFormInstanceDialogPresenter;
}

export const AddFormInstanceDialog = observer(
    (props: IAddFormInstanceDialogProps) => {
        const {
            isOpen,
            isShowSaveButton,
            createdFormInstance,
            form,
            onClickCreate,
            onClickClose,
            onChangeFormData,
            showSaveButton,
        } = props.presenter;
        const { t } = useTranslation();

        return (
            <BaseFormInstanceCreationDialog
                isOpen={isOpen}
                isShowSaveButton={isShowSaveButton}
                isSaving={props.presenter.isSaving}
                title={form?.title || t("common:formInstance.create")}
                form={form || null}
                formData={createdFormInstance?.data || {}}
                onSave={onClickCreate}
                onClose={onClickClose}
                onChange={onChangeFormData}
                showSaveButton={showSaveButton}
            />
        );
    },
);
