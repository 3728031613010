import { IRestService } from "../../../../infrastructure/http/IRestService";
import { IConfigService } from "./IConfigService";
import { injectable } from "inversify";

@injectable()
export class ConfigService implements IConfigService {
    private readonly restService: IRestService;

    constructor(restService: IRestService) {
        this.restService = restService;
    }

    async getConfig(url: string) {
        const prefix = url.startsWith("http") ? "" : "https://";
        return this.restService
            .get(`${prefix}${url}/settings/`, {
                headers: {
                    // TODO откуда-то получить значение psec
                    psec:
                        "b028ee2f237c66b01c44e2ad6cae8932e4f5b2cdd0fa029d6b3fc2f71c6b21c58e6fb80fcee4856d2bfc3a7e83ec2f32dd1ab85b2d567407b57b794fe2222da4",
                },
            })
            .asPromise();
    }
}
