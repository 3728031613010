import { EventType } from "@mrs/webclient-shared-ui-lib";
import { ObjectType } from "../../ObjectType";
import { EventsBroker } from "../events/EventsBroker";
import { ReportEvents } from "./ReportEvents";

export class ReportContext {
    start(): void {
        const eventNamesMap: Map<EventType, string> = new Map();
        eventNamesMap.set(EventType.CREATE, ReportEvents.onCreate);
        eventNamesMap.set(EventType.UPDATE, ReportEvents.onUpdated);
        EventsBroker.setEventNames(ObjectType.REPORT, eventNamesMap);
    }
}
