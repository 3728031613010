import React from "react";
import {
    INotification,
    NotifyVariant,
    TNotifyPosition,
} from "@ui/components/shared/Notifier/INotify";
import { computed, observable } from "mobx";
import { createRoot } from "react-dom/client";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";
import { Notifier } from "@ui/components/shared/Notifier/Notifier";
import { INotifierPresenter } from "@ui/components/shared/Notifier/presenter/INotifierPresenter";
import { toast } from "sonner";
import { injectable } from "inversify";

const style = {
    width: "var(--width)",
    borderRadius: BaseTheme.shape.borderRadius,
    backgroundColor: BaseTheme.palette.common.white,
    border: `1px solid ${BaseTheme.palette.grey["100"]}`,
    boxShadow: BaseTheme.shadows["8"],
};
@injectable()
class NotifierPresenterClass implements INotifierPresenter {
    @observable private _position: TNotifyPosition = "bottom-right";

    constructor() {
        this.render();
    }

    @computed
    public get position(): TNotifyPosition {
        return this._position;
    }

    showNotification = (notification: INotification) => {
        notification?.position && (this._position = notification.position);
        const { variant, message, data } = notification;
        if (variant === NotifyVariant.Warning) return;
        const method = variant === NotifyVariant.Info ? "message" : variant;
        toast[method](message, { ...data, style });
    };

    private render() {
        const root = createRoot(
            document.body.appendChild(document.createElement("div")),
        );
        root.render(
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={BaseTheme}>
                    <Notifier presenter={this} />
                </ThemeProvider>
            </StyledEngineProvider>,
        );
    }
}

export const NotifierPresenter = new NotifierPresenterClass();
