import React from "react";
import { LoginHeader } from "../loginHeader/LoginHeader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextFieldProps } from "@mui/material/TextField";

export const LOGIN_TEXT_FIELD_PROPS: TextFieldProps = {
    fullWidth: true,
    variant: "outlined",
    InputLabelProps: {
        shrink: true,
    },
    InputProps: {
        notched: false,
    },
};

interface ILoginContentProps {
    content: React.JSX.Element;
    title: string;
    buttonLabel: React.JSX.Element | string;
    onClickButton: () => void;
    additionalContent?: React.JSX.Element;
}

export const LoginContent = (props: ILoginContentProps) => {
    const {
        title,
        content,
        buttonLabel,
        onClickButton,
        additionalContent,
    } = props;

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <LoginHeader label={title} />
            {content}
            <Box sx={{ display: "flex", width: "100%", bottom: 0, mt: 5 }}>
                <Button
                    size={"large"}
                    variant={"contained"}
                    color={"primary"}
                    onClick={onClickButton}
                    fullWidth
                    sx={{
                        "& > svg": {
                            color: "inherit",
                            "& > path": {
                                fill: "inherit",
                            },
                        },
                    }}
                >
                    {buttonLabel}
                </Button>
            </Box>
            {additionalContent}
        </Box>
    );
};
