import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { SaveButton } from "./SaveButton/SaveButton";
import { Theme } from "@mui/material/styles";
import { DialogProps } from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import { AppMenuItem } from "@ui/components/shared/AppMainLayout/AppMenuItem/AppMenuItem";
import { DialogWithSplitter } from "@ui/components/shared/DialogWithSplitter/DialogWithSplitter";
import {
    IIconButtonAction,
    IWithId,
    IWithUiTestId,
} from "@mrs/webclient-shared-ui-lib";

const DELAY = 2000;
const SAVE_CHANGES_BUTTON_HEIGHT = 48;

export interface IDialogTab extends IWithUiTestId {
    id: string;
    label: string;
    disabled?: boolean;
    renderElement: JSX.Element;
}

interface IDialogWithSaveButtonProps {
    tabs: IDialogTab[];
    isOpen: boolean;
    isLoading: boolean;
    isChangesSaved: boolean;
    hasChanges: boolean;
    title: string;
    dialogProps?: Partial<DialogProps>;
    toolbarActions?: IIconButtonAction[];
    onClose(): void;
    setIsChangesSaved(value: boolean): void;
    onClickSave?(): void;
    onChangeTab(name: string): void;
}

export const DialogWithSaveButton = observer(
    (props: IDialogWithSaveButtonProps) => {
        const {
            tabs,
            isOpen,
            onClose,
            isLoading,
            isChangesSaved,
            hasChanges,
            toolbarActions,
            setIsChangesSaved,
            onClickSave,
        } = props;
        const [tabValue, setTabValue] = React.useState(tabs[0].id);

        useEffect(() => {
            isChangesSaved && setTimeout(() => setIsChangesSaved(false), DELAY);
        }, [isChangesSaved]);

        useEffect(() => {
            !open && setTabValue(tabs[0].id);
        }, [open]);

        const handleChangeTab = useCallback((item: IWithId) => {
            setTabValue(item.id);
            props.onChangeTab(item.id);
        }, []);

        const currentTub = useMemo(
            () => tabs.find((tab) => tab.id === tabValue),
            [tabs, tabValue],
        );

        return (
            <DialogWithSplitter
                isOpen={isOpen}
                onClose={onClose}
                navigationBlockContent={
                    <MenuList sx={{ width: "inherit", p: 0 }}>
                        {tabs.map((tab) => (
                            <AppMenuItem
                                key={tab.id}
                                item={{ id: tab.id, title: tab.label }}
                                selected={tab.id === tabValue}
                                disabled={tab.disabled}
                                divider={true}
                                onClick={handleChangeTab}
                            />
                        ))}
                    </MenuList>
                }
                dialogProps={props.dialogProps}
                title={props.title}
                toolbarActions={toolbarActions}
            >
                {currentTub?.renderElement && (
                    <Box
                        sx={[
                            styles.dialogContentWrapper,
                            ...(hasChanges
                                ? [styles.dialogContentWrapperWithChanges]
                                : []),
                        ]}
                    >
                        {currentTub.renderElement}
                    </Box>
                )}
                <SaveButton
                    isShow={hasChanges || isChangesSaved}
                    isLoading={isLoading}
                    isChangesSaved={isChangesSaved}
                    onClick={onClickSave}
                />
            </DialogWithSplitter>
        );
    },
);

const styles = {
    dialogContentWrapper: {
        position: "relative",
        p: 2,
        height: "inherit",
        boxSizing: "border-box",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    dialogContentWrapperWithChanges: {
        pb: (theme: Theme) =>
            `calc(${SAVE_CHANGES_BUTTON_HEIGHT}px + ${theme.spacing(2 * 2)})`,
    },
};
