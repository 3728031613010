import React, { useCallback } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
    IconByValue,
    IWithId,
    MRSIcon,
    TVoidCallback,
} from "@mrs/webclient-shared-ui-lib";

interface IAppMenuItem extends IWithId {
    icon?: MRSIcon;
    title: string;
}

interface IAppMenuItemProps<T extends IAppMenuItem = any> {
    item: T;
    selected: boolean;
    disabled?: boolean;
    divider?: boolean;
    onClick: TVoidCallback<T>;
}

export const AppMenuItem = (props: IAppMenuItemProps) => {
    const { item, selected, onClick } = props;

    const onClickItem = useCallback(() => onClick(item), [item, onClick]);

    return (
        <ListItemButton
            selected={selected}
            onClick={onClickItem}
            disabled={props.disabled}
            divider={props.divider}
            sx={{
                p: (theme) => theme.spacing(1.75, 1.25, 1.75, 2),
                minWidth: "auto",
            }}
        >
            {item.icon && (
                <ListItemIcon>
                    <IconByValue
                        value={item.icon}
                        sx={{ color: "text.secondary" }}
                    />
                </ListItemIcon>
            )}
            <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                    variant: "body2",
                    color: "textPrimary",
                    noWrap: true,
                }}
            />
        </ListItemButton>
    );
};
