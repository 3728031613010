import "reflect-metadata";
import { ICustomFormPresenter, IFormErrorData } from "./ICustomFormPresenter";
import { computed, observable, toJS } from "mobx";
import set from "lodash-es/set";
import cloneDeep from "lodash-es/cloneDeep";
import uniqWith from "lodash-es/uniqWith";
import isEqual from "lodash-es/isEqual";
import isEmpty from "lodash-es/isEmpty";
import get from "lodash-es/get";
import omit from "lodash-es/omit";
import isNull from "lodash-es/isNull";
import { JSONSchema7 } from "json-schema";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import { Form } from "../../../../../core/context/form/model/Form";
import { ICancelablePromise, makeCancelable } from "@utils/CancelablePromise";
import { i18next } from "@translator";
import { CustomFormUIController } from "../uiController/CustomFormUIController";
import { ErrorSchema, RJSFValidationError, UiSchema } from "@rjsf/utils";
import {
    ICustomFormFields,
    ICustomFormWidgets,
} from "@ui/components/shared/CustomForm/ICustomForm";

const ERROR_PARAMS = {
    $ref: { ref: "ref" },
    additionalItems: { limit: "count" },
    additionalProperties: [],
    anyOf: [],
    const: [],
    contains: [],
    custom: [],
    dependencies: { depsCount: "count", deeps: "deeps", property: "property" },
    enum: [],
    exclusiveMaximum: { comparison: "comparison", limit: "count" },
    exclusiveMinimum: { comparison: "comparison", limit: "count" },
    "false schema": [],
    format: { format: "format" },
    formatExclusiveMaximum: [],
    formatExclusiveMinimum: [],
    formatMaximum: { comparison: "comparison", limit: "count" },
    formatMinimum: { comparison: "comparison", limit: "count" },
    if: { failingKeyword: "failingKeyword" },
    maximum: { comparison: "comparison", limit: "count" },
    maxItems: { limit: "count" },
    maxLength: { limit: "count" },
    maxProperties: { limit: "count" },
    minimum: { comparison: "comparison", limit: "count" },
    minItems: { limit: "count" },
    minLength: { limit: "count" },
    minProperties: { limit: "count" },
    multipleOf: { multipleOf: "multipleOf" },
    not: [],
    oneOf: [],
    pattern: { pattern: "pattern" },
    patternRequired: { missingPattern: "missingPattern" },
    propertyNames: { propertyName: "propertyName" },
    required: {},
    switch: { caseIndex: "caseIndex" },
    type: { type: "type" },
    uniqueItems: { j: "j", i: "i" },
};

export class CustomFormPresenter implements ICustomFormPresenter {
    @observable private _schema: JSONSchema7 = {};
    @observable private _uiSchema: UiSchema = {};
    @observable private _isJsonFormReady: boolean = false;
    @observable private _customFormWidgets: ICustomFormWidgets = {};
    @observable private _customFormFields: ICustomFormFields = {};
    private _formSchema: Form | null = null;
    private _jsonFormPromise: ICancelablePromise | null = null;
    private _errors: RJSFValidationError[] = [];
    private _errorSchema?: ErrorSchema;

    init = async (formName: string, formSchema?: Form) => {
        await CustomFormUIController.init();
        await this.setCustomComponents();
        const form = formSchema
            ? formSchema
            : CustomFormUIController.getFormByName(formName);
        form && (await this.updateFormSchema(form));
        if (this._schema) {
            this._isJsonFormReady = true;
        }
    };

    unmount = () => {
        this._isJsonFormReady = false;
    };

    async updateFormSchema(formSchema: Form) {
        this._jsonFormPromise && this._jsonFormPromise.cancel();
        this._formSchema = cloneDeep(formSchema);
        this._jsonFormPromise = makeCancelable(
            CustomFormUIController.parseCustomJsonForm(this._formSchema.scheme),
        );
        await this._jsonFormPromise.promise
            .then((res) => {
                if (res) {
                    this._schema = res.schema;
                    this._uiSchema = res.uiSchema;
                    this._jsonFormPromise = null;
                    !this._isJsonFormReady && (this._isJsonFormReady = true);
                }
            })
            .catch((reason) => console.error("Canceled: ", reason));
    }

    isFormChanged(form: Form): boolean {
        return !isEqual(form, this._formSchema);
    }

    @computed
    public get isJsonFormReady(): boolean {
        return this._isJsonFormReady;
    }

    @computed
    public get schema(): JSONSchema7 {
        return toJS(this._schema);
    }

    @computed
    get customFormFields(): ICustomFormFields {
        return toJS(this._customFormFields);
    }

    @computed
    get customFormWidgets(): ICustomFormWidgets {
        return toJS(this._customFormWidgets);
    }

    @computed
    public get uiSchema(): UiSchema {
        return toJS(this._uiSchema);
    }

    async setCustomComponents() {
        this._customFormWidgets = CustomFormUIController.getCustomWidgets();
        this._customFormFields = CustomFormUIController.getCustomFields();
    }

    setErrors(errors: RJSFValidationError[], errorSchema: ErrorSchema) {
        this._errors = errors;
        this._errorSchema = errorSchema;
    }

    transformErrors(errors: RJSFValidationError[]) {
        return errors.map((error) => {
            const options = {};
            const errorName = error.name || "";
            const errorParameter = get(ERROR_PARAMS, errorName);
            for (const key in errorParameter) {
                set(options, errorParameter[key], error.params[key]);
            }
            const isEmailError =
                error.property?.substring(1) === "email" &&
                errorName === "pattern";
            const errorKey = isEmailError ? "emailWrongFormat" : errorName;
            return {
                ...error,
                message: i18next.t(
                    `common:customForm.error.${errorKey}`,
                    options,
                ),
            };
        });
    }

    clearEmptyFields = (formData: object) => {
        const data = { ...formData };
        Object.keys(data).forEach((key) => {
            const value = get(data, key);
            if (typeof value === "object" && !isNull(value)) {
                Object.keys(value).forEach((subKey) => {
                    this.clearEmptyField(value, subKey, `${key}.${subKey}`);
                });
            }
            this.clearEmptyField(data, key, key);
        });
        return data;
    };

    validateForm = (node: HTMLFormElement | null): IFormErrorData => {
        const errorData = this.validateNode(node);
        this.setErrorData(node, errorData);
        return errorData;
    };

    validateNode = (node: HTMLFormElement | null): IFormErrorData => {
        const formData = node?.state?.formData || {};
        const errorData: IFormErrorData = node?.validate(
            this.clearEmptyFields(formData),
        );
        return {
            errors: errorData?.errors || [],
            errorSchema: errorData?.errorSchema || {},
        };
    };

    validateField(field: string, node: HTMLFormElement | null): void {
        const { uiSchema, errorSchema: stateErrorSchema } = node?.state;

        if (node && get(uiSchema, field)) {
            const { errors, errorSchema } = this.validateNode(node);
            const prevOtherFieldErrors = this._errors.filter(
                (error) => !error.property?.includes(field),
            );

            const fieldErrors = errors.filter((error) =>
                error.property?.includes(field),
            );
            const fieldErrorSchema = get(errorSchema, field);

            const newErrors = uniqWith(
                [...prevOtherFieldErrors, ...fieldErrors],
                isEqual,
            );
            let newErrorSchema = fieldErrorSchema
                ? set(
                      { ...(this._errorSchema || stateErrorSchema) },
                      field,
                      fieldErrorSchema,
                  )
                : omit({ ...(this._errorSchema || stateErrorSchema) }, [field]);
            newErrorSchema = this.clearEmptyFields(newErrorSchema);

            this.setErrorData(node, {
                errors: newErrors,
                errorSchema: newErrorSchema,
            });
        }
    }

    private setErrorData = (
        node: HTMLFormElement | null,
        errorData: IFormErrorData,
    ) => {
        if (!node) return;
        const { errors, errorSchema } = errorData;

        node.setState({
            errors,
            errorSchema,
            schemaValidationErrors: errors,
            schemaValidationErrorSchema: errorSchema,
        });
        this.setErrors(errors, errorSchema);
    };

    private clearEmptyField = (data: any, key: string, path: string) => {
        const fieldData = data[key];
        if (isEmpty(fieldData) && typeof fieldData !== "number") {
            if (Array.isArray(fieldData) && this.isViewDisplayField(path))
                return;
            delete data[key];
        }
    };

    private isViewDisplayField = (path: string) => {
        const field = get(this.uiSchema, path);
        if (!field) return false;
        const uiField = get(field, "ui:field");
        return uiField ? uiField === "viewDisplay" : false;
    };
}
