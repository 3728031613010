import React, { useState } from "react";
import { observer } from "mobx-react";
import {
    LoginContent,
    LOGIN_TEXT_FIELD_PROPS,
} from "../loginContent/LoginContent";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";
import {
    FluentUiIconName,
    Icon,
    KeyCode,
    TVoidCallback,
} from "@mrs/webclient-shared-ui-lib";

interface ISubnetLoginProps {
    orgUrl: string;
    onChangeOrgUrl: TVoidCallback<string>;
    subnetLogin(): void;
}

export const SubnetLogin = observer(
    ({ orgUrl, onChangeOrgUrl, subnetLogin }: ISubnetLoginProps) => {
        const { t } = useTranslation(DEFAULT_NAMESPACE);
        const [error, setError] = useState<boolean>(false);

        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setError(false);
            onChangeOrgUrl(event.target.value);
        };

        const onClickSubnetLogin = () => {
            !orgUrl && setError(true);
            subnetLogin();
        };

        const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
            event.key === KeyCode.ENTER && subnetLogin();
        };

        return (
            <LoginContent
                content={
                    <TextField
                        label={t("login.subnet.orgAddress")}
                        value={orgUrl}
                        onChange={onChange}
                        placeholder={t("login.subnetPlaceholder")}
                        error={error}
                        onKeyPress={onKeyPress}
                        helperText={t("login.subnet.hint")}
                        {...LOGIN_TEXT_FIELD_PROPS}
                    />
                }
                title={t("login.subnet.orgEnterTitle")}
                buttonLabel={
                    <>
                        <span>{t("login.subnet.continue")}</span>
                        <Icon
                            iconName={FluentUiIconName.ArrowRightRegular}
                            sx={{
                                m: 1,
                            }}
                        />
                    </>
                }
                onClickButton={onClickSubnetLogin}
            />
        );
    },
);
