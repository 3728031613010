import { BaseApiService } from "../../../../sharedKernel/apiService/impl/BaseApiService";
import { IDocumentApiService } from "../IDocumentApiService";
import { IRestService } from "../../../../../infrastructure/http/IRestService";
import { IDocumentCreateDTO } from "../../dto/IDocumentCreateDTO";
import {
    IActivateMultipartUploadCreateDTO,
    IActivateMultipartUploadDTO,
    IDocumentDTO,
    IFinalizeMultipartUploadCreateDTO,
} from "@mrs/webclient-shared-ui-lib";

export class DocumentApiService
    extends BaseApiService<IDocumentDTO, IDocumentCreateDTO>
    implements IDocumentApiService {
    constructor(service: IRestService, resourceName: string) {
        super(service, resourceName);
    }

    async download(url: string): Promise<Blob> {
        return this.restService.download(url).asPromise();
    }

    async activateMultipartUpload(
        data: IActivateMultipartUploadCreateDTO,
    ): Promise<IActivateMultipartUploadDTO> {
        const path = `${this.resourcePath}/multipart/activate`;
        return this.restService.post(path, { data }).asPromise();
    }

    async finalizeMultipartUpload(
        data: IFinalizeMultipartUploadCreateDTO,
    ): Promise<void> {
        const path = `${this.resourcePath}/multipart/finalize`;
        await this.restService.post(path, { data }).asPromise();
    }
}
