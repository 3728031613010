import { initPlasmicLoader } from "@plasmicapp/loader-react";
import { PlasmicComponentLoader } from "@plasmicapp/loader-react/dist/loader";
import { registrationComponents } from "./RegistrationComponents";

const projectId = "vFE8ANxWXhD4o6RM2gRogM";
const projectToken = "ECbv1OOuNny0wsP4zKIXprh59w3C8nOYDkvhe0xWanpSoEBiawFtRXduYipvaUB0cjkCgkKiuZsRG7O8CthPA";

class PlasmicServiceClass {
    private _loader: PlasmicComponentLoader;

    init(): PlasmicComponentLoader {
        const plasmic = initPlasmicLoader({
            projects: [
                {
                    id: projectId,
                    token: projectToken,
                },
            ],
            preview: true,
        });
        this._loader = plasmic;
        return plasmic;
    }

    registerComponents(): void {
        registrationComponents.forEach(component => this._loader.registerComponent(component.component, component.meta));
    }
}

export const PlasmicService = new PlasmicServiceClass();
