import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import { AppMainLayoutPresenter } from "./presenter/AppMainLayoutPresenter";
import { observer } from "mobx-react";
import { AppMenuItem } from "./AppMenuItem/AppMenuItem";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventType,
    AvatarSize,
    ConfirmDialog,
    FluentUiIconName,
    Icon,
    IconButtonAction,
    KeyCode,
    ListItemWithAvatar,
    MRSIcon,
} from "@mrs/webclient-shared-ui-lib";
import { IPageInfo } from "./presenter/IAppMainLayoutPresenter";
import {
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import {
    BaseTheme,
    DIVIDER_HEIGHT,
    MENU_WIDTH,
} from "@ui/theme/baseTheme/BaseTheme";
import { MRSLogo } from "../MRSLogo/MRSLogo";
import { useTranslation } from "react-i18next";
import { ConfigAccess } from "@utils/ConfigAccess";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import Favicon from "react-favicon";
import { useAppMenu } from "@ui/components/shared/AppMainLayout/hook/AppMenu";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";

const USER_CARD_HEIGHT = 56;
const LOGO_SIZE = 20;
const BOTTOM_MENU_WIDTH = 260;

export const AppMainLayout = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { open, onDrawerClose, onDrawerOpen } = useAppMenu();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    );

    const presenter = useMemo(() => new AppMainLayoutPresenter(), []);
    const { init, systemPages, customPages, currentUser, logo } = presenter;

    useLayoutEffect(() => {
        init();
    }, []);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.ctrlKey && event.code === KeyCode.Backslash)
                open ? onDrawerClose() : onDrawerOpen();
        },
        [open],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const onClickMenuListItem = useCallback(
        (page: IPageInfo) => {
            navigate(page.link);
            AnalyticsService.logEvent({
                type: AnalyticsEventType.System,
                location: AnalyticsEventLocation.Navigation,
                component: AnalyticsEventComponent.Item,
                action: AnalyticsEventAction.Click,
                params: {
                    target: page.viewId,
                },
            });
        },
        [navigate],
    );

    const isPageSelected = useCallback(
        (baseUrl: string) => {
            return location.pathname.indexOf(baseUrl) != -1;
        },
        [location],
    );

    const onOpenBottomMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [],
    );

    const onCloseBottomMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onLogout = useCallback(async () => {
        const message = t("common:settings.logoutConfirm");
        await ConfirmDialog("", message, {
            confirmLabel: t("common:settings.exit"),
            muiTheme: BaseTheme,
        });
        presenter.logout();
    }, []);

    return (
        <Box display={"flex"}>
            <Favicon url={presenter.favicon} />
            <Drawer
                variant={"persistent"}
                anchor={"left"}
                open={open}
                sx={styles.drawer}
            >
                <Box sx={styles.menuWrapper}>
                    <Box sx={styles.menuListWrapper}>
                        <Box sx={styles.logoContainer}>
                            {logo?.url ? (
                                <img src={logo?.url} alt={logo.alt} />
                            ) : (
                                <MRSLogo />
                            )}
                            <Typography
                                variant={"subtitle2"}
                                color={"textPrimary"}
                            >
                                {presenter.menuTitle || t("common:menu.title")}
                            </Typography>
                            <IconButtonAction
                                item={{
                                    tooltipTitle: t(
                                        "common:common.navigation.hide",
                                    ),
                                    hotKey: "Ctrl + \\",
                                    icon: (
                                        <Icon
                                            iconName={
                                                FluentUiIconName.ArrowPreviousRegular
                                            }
                                        />
                                    ),
                                    onClick: onDrawerClose,
                                }}
                                sx={styles.hideIcon}
                            />
                        </Box>
                        {!!customPages.length && (
                            <>
                                <MenuList sx={{ p: 0 }}>
                                    {customPages.map((page, index) => (
                                        <AppMenuItem
                                            key={`${page.id}-${index}`}
                                            item={page}
                                            selected={isPageSelected(page.link)}
                                            onClick={onClickMenuListItem}
                                        />
                                    ))}
                                </MenuList>
                            </>
                        )}
                    </Box>
                    <Box sx={styles.bottomPages}>
                        <Divider />
                        {currentUser && (
                            <ListItemWithAvatar
                                title={currentUser.fullName}
                                placeholder={t("common:menu.userPlaceholder")}
                                subTitle={currentUser.email}
                                variant={"s"}
                                avatarSize={AvatarSize.ExtraSmall}
                                secondaryAction={
                                    <IconButton onClick={onOpenBottomMenu}>
                                        <Icon
                                            iconName={
                                                FluentUiIconName.MoreHorizontalRegular
                                            }
                                        />
                                    </IconButton>
                                }
                                sx={styles.user}
                            />
                        )}
                    </Box>
                </Box>
            </Drawer>
            <Box
                sx={{
                    width: open ? `calc(100% - ${MENU_WIDTH}px)` : "100%",
                    height: "100%",
                    ml: open ? 0 : `-${MENU_WIDTH}px`,
                }}
            >
                <Routes>
                    {systemPages.map((page) => (
                        <Route
                            key={`page - ${page.id}`}
                            path={page.urlTemplate}
                            element={page.component}
                        />
                    ))}
                    {!!customPages.length && (
                        <>
                            {customPages.map((page) => (
                                <Route
                                    key={`page - ${page.id}`}
                                    path={page.urlTemplate}
                                    element={<Outlet />}
                                >
                                    <Route
                                        path={"view-item-id=:viewItemId"}
                                        element={page.component}
                                    />
                                    <Route path={""} element={page.component} />
                                </Route>
                            ))}
                            <Route
                                path={"/"}
                                element={
                                    <Navigate
                                        to={
                                            ConfigAccess.config.defaultPage ||
                                            customPages[0]?.link
                                        }
                                        replace
                                    />
                                }
                            />
                        </>
                    )}
                    <Route path={"*"} element={<div />} />
                </Routes>
            </Box>
            <Popover
                anchorEl={anchorEl}
                open={!!anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                sx={styles.bottomMenu}
                onClose={onCloseBottomMenu}
            >
                <MenuList
                    sx={styles.bottomMenuList}
                    onClick={onCloseBottomMenu}
                >
                    {systemPages.map((page, index) => (
                        <AppMenuItem
                            key={`${page.id}-${index}`}
                            item={page}
                            selected={isPageSelected(page.link)}
                            onClick={onClickMenuListItem}
                        />
                    ))}
                    <AppMenuItem
                        key={"exit"}
                        item={
                            {
                                id: "exit",
                                icon: MRSIcon.ArrowExitRegular,
                                title: t("common:settings.exit"),
                            } as IPageInfo
                        }
                        selected={false}
                        onClick={onLogout}
                    />
                </MenuList>
            </Popover>
        </Box>
    );
});

const styles = {
    drawer: {
        width: MENU_WIDTH,
        "& .MuiDrawer-paper": {
            width: "inherit",
            backgroundColor: "background.paper",
            boxSizing: "border-box",
        },
    },
    menuWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        position: "relative",
    },
    menuListWrapper: {
        display: "inherit",
        flexDirection: "inherit",
        height: `calc(100% - ${USER_CARD_HEIGHT}px - ${DIVIDER_HEIGHT}px)`,
        overflowY: "auto",
        scrollBehavior: "smooth",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 5,
        backgroundColor: "background.paper",
        p: (theme: Theme) => theme.spacing(1.75, 2),
        borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
        "& > img": {
            height: LOGO_SIZE,
            width: LOGO_SIZE,
            boxSizing: "border-box",
            mr: 2,
        },
    },
    hideIcon: {
        m: -0.75,
        ml: "auto",
    },
    bottomPages: {
        position: "sticky",
        bottom: 0,
        width: "100%",
        zIndex: 5,
        backgroundColor: "background.paper",
    },
    user: {
        p: (theme: Theme) => theme.spacing(0.75, 6, 0.75, 1),
        "& .MuiListItemSecondaryAction-root": {
            right: 8,
        },
    },
    bottomMenu: {
        "& .MuiPopover-paper": {
            bgcolor: "background.paper",
            border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
            boxShadow: 4,
            width: BOTTOM_MENU_WIDTH,
        },
    },
    bottomMenuList: { p: 0 },
};
