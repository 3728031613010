import React, { useMemo } from "react";
import { Search } from "@ui/components/shared/Search/Search";
import {
    FluentUiIconName,
    Icon,
    IconButtonAction,
    IIconButtonAction,
    IWithDisabled,
    IWithSx,
} from "@mrs/webclient-shared-ui-lib";
import { useTranslation } from "react-i18next";
import { SEARCH_HEIGHT } from "@ui/theme/baseTheme/BaseTheme";

interface IViewSearchProps extends IWithDisabled, IWithSx {
    searchValue: string;
    autoFocus?: boolean;
    onChange: (value: string) => Promise<void>;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const ViewSearch = (props: IViewSearchProps) => {
    const { sx, searchValue } = props;
    const { t } = useTranslation();

    const searchActions = useMemo(() => {
        return [
            {
                tooltipTitle: t("common:common.close"),
                disabled: false,
                hidden: !searchValue.trim(),
                icon: <Icon iconName={FluentUiIconName.DismissRegular} />,
                onClick: () => props.onChange(""),
            },
        ];
    }, [searchValue]);

    return (
        <Search
            onChange={props.onChange}
            placeholder={t("common:common.search")}
            keyword={props.searchValue}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            sx={[
                {
                    width: "100%",
                    pl: 2,
                    pr: 1,
                    minHeight: SEARCH_HEIGHT,
                    boxSizing: "border-box",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {searchActions.map(
                (item: IIconButtonAction, index: number) =>
                    !item.hidden && (
                        <IconButtonAction
                            key={`search - ${index}`}
                            item={item}
                        />
                    ),
            )}
        </Search>
    );
};
