import { classToPlain, Exclude, Expose } from "class-transformer";
import { IFormInstanceDTO } from "../dto/IFormInstanceDTO";
import { IRelationsData } from "../../relation/dto/IRelationsData";
import { IUserDTO, ObjectId } from "@mrs/webclient-shared-ui-lib";
import { ObjectType } from "../../../ObjectType";
import { EntityWithHooks } from "../../../sharedKernel/model/EntityWithHooks";
import set from "lodash-es/set";
import { Hook } from "../../hook/model/Hook";

@Exclude()
export class FormInstance extends EntityWithHooks<IFormInstanceDTO> {
    private _formId!: string;
    private _parentId!: string;
    private _data!: object;
    private _metadata!: object;
    private _title!: string;
    private _createdDate!: number;
    private _updatedDate!: number;
    private _owner!: ObjectId;
    private _reviewers!: ObjectId[];
    private _isArchived!: boolean;
    private _relationsData!: IRelationsData;

    constructor(
        data: Partial<IFormInstanceDTO>,
        user: IUserDTO,
        hooks: Hook[],
    ) {
        super(data, user, hooks);
    }

    get type(): ObjectType {
        return ObjectType.FORM_INSTANCE;
    }

    @Expose()
    get formId(): ObjectId {
        return this._formId;
    }

    set formId(value: ObjectId) {
        this.setValueByField("formId", value);
    }

    @Expose()
    get parentId(): ObjectId {
        return this._parentId;
    }

    set parentId(value: ObjectId) {
        this.setValueByField("parentId", value);
    }

    @Expose()
    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this.setValueByField("title", value);
    }

    @Expose()
    get data(): object {
        return this._data;
    }

    set data(value: object) {
        this.setValueByField("data", value);
    }

    @Expose()
    get metadata(): object {
        return this._metadata;
    }

    set metadata(value: object) {
        this.setValueByField("metadata", value);
    }

    @Expose()
    get createdDate(): number {
        return this._createdDate;
    }

    set createdDate(value: number) {
        this.setValueByField("_createdDate", value);
    }

    @Expose()
    get updatedDate(): number {
        return this._updatedDate;
    }

    set updatedDate(value: number) {
        this.setValueByField("updatedDate", value);
    }

    @Expose()
    get owner(): ObjectId {
        return this._owner;
    }

    set owner(value: ObjectId) {
        this.setValueByField("owner", value);
    }

    @Expose()
    get reviewers(): ObjectId[] {
        return this._reviewers;
    }

    set reviewers(value: ObjectId[]) {
        this.setValueByField("reviewers", value);
    }

    @Expose()
    get isArchived(): boolean {
        return this._isArchived;
    }

    set isArchived(value: boolean) {
        this.setValueByField("_isArchived", value);
    }

    @Expose()
    get relationsData(): IRelationsData {
        return this._relationsData;
    }

    set relationsData(value: IRelationsData) {
        this._relationsData = value;
    }

    toDTO(): IFormInstanceDTO {
        return classToPlain(this) as IFormInstanceDTO;
    }

    fromDTO(dto: IFormInstanceDTO) {
        this._formId = dto.formId;
        this._parentId = dto.parentId;
        this._data = dto.data || {};
        this._metadata = dto.metadata;
        this._title = dto.title;
        this._createdDate = dto.createdDate || new Date().getTime();
        this._owner = dto.owner || this._user.id;
        this._reviewers = dto.reviewers || [];
        this._isArchived = dto.isArchived || false;
        this._relationsData = dto.relationsData || {};
    }

    private setValueByField(field: string, value: any): void {
        const changes = { [field]: value };
        if (this.canChange(changes)) {
            set(this, `_${field}`, value);
            this.onChange();
        }
    }
}
