import { IPagination, ObjectId } from "@mrs/webclient-shared-ui-lib";

export class PaginationUtils {
    public static create(limit?: number, offset?: number): IPagination {
        return { limit, offset };
    }

    public static makeStep(pagination: IPagination, step: number): IPagination {
        if (pagination.offset) {
            pagination.offset += step;
        } else {
            pagination.offset = step;
        }
        return pagination;
    }

    public static async requestWithPagination<T>(
        ids: ObjectId[],
        request: (requestIds: ObjectId[]) => Promise<T[]>,
        limit: number = 100,
    ) {
        const elements: T[] = [];

        if (ids.length === 0) return elements;

        while (ids.length !== 0) {
            const requestedIds = ids.splice(0, limit);
            const response = await request(requestedIds);
            elements.push(...response);
        }

        return elements;
    }
}
