import {
    captureEvent,
    captureException,
    captureMessage,
    init,
    setUser,
} from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import {
    CaptureContext,
    Event,
    EventHint,
    Severity,
    SeverityLevel,
    User,
} from "@sentry/types";
import { ConfigAccess } from "@utils/ConfigAccess";

class LoggerServiceClass {
    init() {
        init({
            dsn: ConfigAccess.config.glitchtipDSN || "",
            integrations: [
                new BrowserTracing(),
                new CaptureConsole({ levels: ["error"] }),
            ],
            enabled: process.env.NODE_ENV === "production",
            // release: "constructor-webclient@" + (process.env.BUILD_INFO as any).version,
        });
    }

    setUser(user: User | null) {
        setUser(user);
    }

    captureException(exception: any, captureContext?: CaptureContext) {
        captureException(exception, captureContext);
    }

    captureMessage(
        message: string,
        captureContext?: CaptureContext | Severity | SeverityLevel,
    ) {
        captureMessage(message, captureContext);
    }

    captureEvent(event: Event, hint?: EventHint) {
        captureEvent(event, hint);
    }
}

export const LoggerService = new LoggerServiceClass();
