import React from "react";
import { CodeComponentMeta } from "@plasmicapp/loader-react/dist/loader";
import { AvatarRegistration } from "./registration/AvatarRegistration";
import { LetterUserAvatarRegistration } from "./registration/LetterUserAvatarRegistration";

export interface IRegistrationComponent<T extends React.ComponentType<any>> {
    component: T,
    meta: CodeComponentMeta<React.ComponentProps<T>>,
}

export const registrationComponents: IRegistrationComponent<React.FC>[] = [
    AvatarRegistration, LetterUserAvatarRegistration,
];
