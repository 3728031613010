import { ObjectType } from "../../../../ObjectType";
import { AbstractApplicationService } from "../../../../sharedKernel/service/impl/AbstractApplicationService";
import { IDocumentRepository } from "../../repository/IDocumentRepository";
import { IDocumentService } from "../IDocumentService";
import { IDocumentCreateDTO } from "../../dto/IDocumentCreateDTO";
import {
    IActivateMultipartUploadCreateDTO,
    IActivateMultipartUploadDTO,
    IDocumentDTO,
    IFinalizeMultipartUploadCreateDTO,
} from "@mrs/webclient-shared-ui-lib";

export class DocumentService
    extends AbstractApplicationService<
        IDocumentDTO,
        IDocumentDTO,
        IDocumentCreateDTO,
        IDocumentRepository
    >
    implements IDocumentService {
    constructor(repository: IDocumentRepository) {
        super(repository, ObjectType.DOCUMENT);
    }

    async download(url: string): Promise<Blob> {
        return this.repository.download(url);
    }

    async activateMultipartUpload(
        data: IActivateMultipartUploadCreateDTO,
    ): Promise<IActivateMultipartUploadDTO> {
        return this.repository.activateMultipartUpload(data);
    }

    async finalizeMultipartUpload(
        data: IFinalizeMultipartUploadCreateDTO,
    ): Promise<void> {
        await this.repository.finalizeMultipartUpload(data);
    }

    toEntity(data: any) {
        return data;
    }

    toEntities(data: any[]) {
        return data;
    }
}
