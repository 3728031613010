import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { ViewWithSearchManagerPresenter } from "./presenter/ViewWithSearchManagerPresenter";
import { IWithDisabled, TVoidCallback } from "@mrs/webclient-shared-ui-lib";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ViewSearch } from "@ui/components/shared/ViewSearch/ViewSearch";

interface IViewWithSearchManagerProps extends IWithDisabled {
    viewName: string;
    values: any[];
    multiple?: boolean;
    autoFocus?: boolean;
    parentName?: string;
    scrollableTarget: HTMLDivElement;
    onChange: TVoidCallback<any[]>;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const ViewWithSearchManager = observer(
    (props: IViewWithSearchManagerProps) => {
        const {
            viewName,
            values,
            disabled,
            multiple,
            scrollableTarget,
            onChange,
        } = props;

        const presenter = useMemo(
            () => new ViewWithSearchManagerPresenter({ multiple }),
            [],
        );
        const {
            isSearchEnabled,
            searchValue,
            init,
            unmount,
            onChangeSearchValue,
        } = presenter;

        useLayoutEffect(() => {
            init({ viewName, values, scrollableTarget, onChange });
            return () => unmount();
        }, [viewName, props.parentName]);

        useEffect(() => {
            presenter.setDisabled(!!disabled);
        }, [disabled]);

        return (
            <Box sx={{ height: "100%", p: 0 }}>
                {isSearchEnabled && (
                    <>
                        <ViewSearch
                            searchValue={searchValue}
                            autoFocus={props.autoFocus}
                            disabled={disabled}
                            onChange={onChangeSearchValue}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                        />
                        <Divider />
                    </>
                )}
                {presenter.renderView}
            </Box>
        );
    },
);
