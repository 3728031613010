import { compare, Operation } from "fast-json-patch";
import { TErrorResponse } from "../../infrastructure/http/IRestService";
import { NO_CONNECTION_CODE } from "../../infrastructure/http/RestService";
import { IFindOptions } from "../IFindOptions";
import { SortOrder } from "../SortOrder";
import { IWithId, TQueryParams } from "@mrs/webclient-shared-ui-lib";
import { Sort } from "../Sort";
import { Notify } from "../notify/Notify";
import { i18next } from "../../../lib/translator";
import { CurrentUser } from "../../core/context/user/currentUser/CurrentUser";

export type TPatchOperation = Operation;

const BAD_REQUEST_HTTP_RESPONSE_CODE = 400;
const NO_AUTH_HTTP_RESPONSE_CODE = 401;
const FORBIDDEN_HTTP_RESPONSE_CODE = 403;

const REFRESH_TOKEN_ERROR = "invalid_grant";

export class RequestUtils {
    static getHeaders() {
        const headers: any = {};
        const token = CurrentUser.getToken();
        if (token) {
            headers.authorization = `Bearer ${token}`;
        }
        return headers;
    }

    static isOkStatus(status: number): boolean {
        return (
            !isNaN(status) && isFinite(status) && status < 400 && status >= 100
        );
    }

    static isRemoteServiceErrorStatus(status: number): boolean {
        return (
            !isNaN(status) && isFinite(status) && status < 600 && status >= 500
        );
    }

    static isUnauthorizedStatus(status: number): boolean {
        return status === NO_AUTH_HTTP_RESPONSE_CODE;
    }

    static isForbiddenStatus(status: number): boolean {
        return status === FORBIDDEN_HTTP_RESPONSE_CODE;
    }

    static isBadRequestStatus(status: number): boolean {
        return status === BAD_REQUEST_HTTP_RESPONSE_CODE;
    }

    static isTokenError(data: any): boolean {
        return (
            this.isUnauthorizedStatus(data.status) ||
            this.isForbiddenStatus(data.status)
        );
    }

    static isRefreshTokenError(response: any): boolean {
        return (
            this.isBadRequestStatus(response.status) &&
            response.data.error === REFRESH_TOKEN_ERROR
        );
    }

    static createPatch<T extends Object | Array<any>>(
        sourceItem: T,
        changedItem: T,
    ): TPatchOperation[] {
        return compare(sourceItem, changedItem);
    }

    static isLostConnectionError(error: TErrorResponse): boolean {
        return error && error.code === NO_CONNECTION_CODE;
    }

    static showRequestError(error: TErrorResponse, message: string): void {
        this.isLostConnectionError(error)
            ? Notify.error(i18next.t("common:system.checkServConn"))
            : message && Notify.error({ message });
    }

    static encodeRequestValue(value: string | number | boolean) {
        try {
            return value ? encodeURIComponent(value) : value;
        } catch (e) {
            return value;
        }
    }

    static decodeRequestValue(value: string) {
        try {
            return value ? decodeURIComponent(value) : value;
        } catch (e) {
            return value;
        }
    }

    static createSortOptions(options?: IFindOptions): TQueryParams {
        if (!options || !options.sort) return {};
        const sortArr = Array.isArray(options.sort)
            ? options.sort
            : [options.sort];
        return this.getSortFromArray(sortArr);
    }

    private static sortOrderMap: Map<SortOrder, string> = new Map<
        SortOrder,
        string
    >([
        [SortOrder.ASCENDING, "abc"],
        [SortOrder.DESCENDING, "cba"],
    ]);

    private static getSortFromArray<T extends IWithId>(
        sortArr: Sort<T>[],
    ): TQueryParams {
        let sort = "";
        sortArr.forEach((sortItem, index) => {
            const sortField = sortItem.field || "id";
            const sortOrder = this.sortOrderMap.get(
                sortItem.order || SortOrder.ASCENDING,
            );
            sort +=
                `${String(sortField)}:${sortOrder}` +
                (index === sortArr.length - 1 ? "" : "&sort=");
        });
        return { sort };
    }
}
