import { observer } from "mobx-react";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ISupersetViewPresenter } from "./presenter/ISupersetViewPresenter";
import { ViewContent, ViewLoader } from "@mrs/webclient-shared-ui-lib";
import Box from "@mui/material/Box";

interface ISupersetViewProps {
    presenter: ISupersetViewPresenter;
}

export const SupersetView = observer((props: ISupersetViewProps) => {
    const { presenter } = props;
    const { view, document, fullScreen } = presenter;
    const [isIframeLoaded, setIsIframeLoaded] = useState<boolean>(false);
    const parentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsIframeLoaded(false);
        const parent = parentRef.current;
        if (document && parent) {
            presenter.onChangeDocument(document, parent);
            parent.querySelector("iframe")?.addEventListener("load", onLoad);
        }
    }, [document]);

    const onLoad = useCallback(() => {
        setIsIframeLoaded(true);
    }, []);

    const height = useMemo(
        () =>
            fullScreen || !view?.settings?.height
                ? "100%"
                : view?.settings?.height,
        [view, fullScreen],
    );

    return (
        <ViewContent
            isLoading={false}
            isShow={!!document}
            settings={view?.settings}
        >
            {document && (
                <Box
                    ref={parentRef}
                    width={"inherit"}
                    overflow={"hidden"}
                    bgcolor={"background.paper"}
                    sx={{
                        display: !isIframeLoaded ? "none" : undefined,
                        height,
                        "& > iframe": {
                            height: "inherit",
                            width: "100%",
                            border: 0,
                            overflow: "hidden",
                        },
                    }}
                />
            )}
            {!isIframeLoaded && document && (
                <Box height={height}>
                    <ViewLoader />
                </Box>
            )}
        </ViewContent>
    );
});
