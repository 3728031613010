import { FieldProps, IdSchema } from "@rjsf/utils";
import { FormWrapped } from "./FormWrapped";
import get from "lodash-es/get";
import { IFormWrapped } from "@mrs/webclient-shared-ui-lib";

export class FormFieldWrapped<T extends IFormWrapped> extends FormWrapped<T>
    implements FieldProps {
    constructor(wrapped: T) {
        super(wrapped);
    }

    get id(): string {
        return this.idSchema.$id;
    }

    get value() {
        return get(this._wrapped, "formData");
    }

    get idSchema(): IdSchema {
        return get(this._wrapped, "idSchema") as IdSchema;
    }
}
