import { ObjectType } from "../../core/ObjectType";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";

export class PathPart {
    private _type: ObjectType | null;
    private _id: ObjectId | null;

    constructor(id: ObjectId | null, type: ObjectType | null) {
        this._id = id;
        this._type = type;
    }

    get type(): ObjectType | null {
        return this._type;
    }

    set type(value: ObjectType | null) {
        this._type = value;
    }

    get id(): ObjectId | null {
        return this._id;
    }

    set id(value: ObjectId | null) {
        this._id = value;
    }
}
