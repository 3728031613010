import React, { useCallback, useMemo } from "react";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import get from "lodash-es/get";
import { DEFAULT_NAMESPACE, LOCALE } from "@lib/translator/i18next";
import { MuiTextFieldProps } from "@mui/lab/internal/pickers/PureDateInput";
import { useTranslation } from "react-i18next";

interface IDateWidgetProps extends IFormWidgetWrapped {}

export const DateWidget = (props: IDateWidgetProps) => {
    const { t, i18n } = useTranslation(DEFAULT_NAMESPACE);
    const currentLocale = get(LOCALE, i18n.language || "en");
    const { schema, helperText, errors, required } = props;

    const hidden = useMemo(() => schema?.hidden || false, [schema]);

    const onChange = async (date: Date | null) => {
        const val = date ? new Date(date).getTime() : null;
        await props.onChange(val);
        props.onBlur(props.id, val);
    };
    const onClose = () => {
        !props.value && props.onBlur(props.id, props.value);
    };

    const renderTextField = useCallback(
        (params: MuiTextFieldProps) => {
            const { inputProps, ...other } = params;
            return (
                <TextField
                    {...other}
                    InputProps={{
                        notched: false,
                    }}
                    inputProps={{
                        ...inputProps,
                        placeholder: schema.description || "",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant={"outlined"}
                    fullWidth
                    required={required}
                    helperText={helperText}
                    error={!!errors?.length}
                />
            );
        },
        [schema, helperText, errors, required],
    );

    return !hidden ? (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={currentLocale}
        >
            <MobileDatePicker
                label={props.label}
                value={props.value || null}
                onChange={onChange}
                onClose={onClose}
                disabled={props.disabled}
                renderInput={renderTextField}
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
            />
        </LocalizationProvider>
    ) : (
        <></>
    );
};
