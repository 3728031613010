import React from "react";
import IconButton from "@mui/material/IconButton";
import { Search } from "../Search/Search";
import {
    FluentUiIconName,
    Icon,
    IWithSx,
    TVoidCallback,
} from "@mrs/webclient-shared-ui-lib";
import { SEARCH_HEIGHT } from "@ui/theme/baseTheme/BaseTheme";

const INPUT_HEIGHT = 20;

interface ISearchWithCloseButtonProps extends IWithSx {
    placeholder?: string;
    value?: string;
    autoFocus?: boolean;
    startIcon?: boolean;
    disabled?: boolean;
    onChange: TVoidCallback<string>;
    onKeyPress?: TVoidCallback<React.KeyboardEvent<HTMLDivElement>>;
    onClose?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const SearchWithCloseButton = (props: ISearchWithCloseButtonProps) => {
    const {
        placeholder,
        value,
        autoFocus,
        startIcon,
        sx,
        onClose,
        onChange,
        onKeyPress,
    } = props;

    return (
        <Search
            onChange={onChange}
            onKeyPress={onKeyPress}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            placeholder={placeholder}
            keyword={value}
            autoFocus={autoFocus}
            sx={[
                {
                    p: (theme) => theme.spacing(1.75, 2),
                    height: SEARCH_HEIGHT,
                    boxSizing: "border-box",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            startIcon={startIcon}
            disabled={props.disabled}
            inputBaseProps={{
                sx: {
                    color: "text.primary",
                    height: INPUT_HEIGHT,
                    lineHeight: `${INPUT_HEIGHT}px`,
                },
            }}
        >
            {!!onClose && !value && (
                <IconButton onClick={onClose} data-ui-test={"closeButton"}>
                    <Icon iconName={FluentUiIconName.DismissRegular} />
                </IconButton>
            )}
        </Search>
    );
};
