import { ObjectId, HookType } from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-webclient";
import { Identifiable } from "../../../sharedKernel/model/Identifiable";

export class Hook extends Identifiable {
    private readonly _title: string;
    private readonly _function: string;
    private readonly _hookType: HookType;
    private readonly _form: ObjectId;

    constructor(dto: dto.hook.FormHook) {
        super(dto);
        this._title = dto.title;
        this._function = dto.function;
        this._hookType = dto.hookType.code as HookType;
        this._form = dto.form;
    }

    get title(): string {
        return this._title;
    }

    get function(): string {
        return this._function;
    }

    get hook() {
        return new Function(`return (${this._function})`)();
    }

    get hookType(): HookType {
        return this._hookType;
    }

    get form(): ObjectId {
        return this._form;
    }
}
