import { ObjectType } from "../../ObjectType";
import { EventType } from "@mrs/webclient-shared-ui-lib";
import { Broadcast } from "../../../infrastructure/broadcast/broadcast";
import { IEventDTO } from "./dto/IEventDTO";
import { IEventParser } from "./parser/IEventParser";
import { BaseEventParser } from "./parser/EventParser";

class EventsBrokerClass {
    private _eventNamesMap: Map<ObjectType, Map<EventType, string>> = new Map();
    private readonly _baseEventParser: IEventParser = new BaseEventParser();

    constructor() {
        this.onMessage = this.onMessage.bind(this);
    }

    setEventNames(type: ObjectType, eventNames: Map<EventType, string>) {
        this._eventNamesMap.set(type, eventNames);
    }

    onMessage(eventMessage: MessageEvent) {
        try {
            const eventData: IEventDTO[] = JSON.parse(eventMessage.data);
            if (Array.isArray(eventData)) {
                eventData.forEach((event) => {
                    const parsedEvent = this._baseEventParser.parseEvent(event);
                    this.processSingleEvent(parsedEvent);
                });
            }
        } catch (e) {
            console.error(e);
            // parse error, or not valid event
        }
    }

    private processSingleEvent(event: any) {
        const eventType: EventType = event.eventType;
        const contextName = event.context;
        const domainEventNamesMap = this._eventNamesMap.get(contextName);
        if (!domainEventNamesMap) {
            return console.warn(
                `not found domain event names for ${contextName}`,
            );
        }

        const domainEventName = domainEventNamesMap.get(eventType);

        if (!domainEventName) {
            return console.warn(
                `domain event not exist for ${contextName}, event type: ${eventType}`,
            );
        }

        Broadcast.trig(domainEventName, event.data);
    }
}
export const EventsBroker = new EventsBrokerClass();
