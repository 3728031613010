import i18next, { DEFAULT_NAMESPACE } from "./i18next";
import { appStorage } from "../../app/infrastructure/storage/AppStorage";

export interface ITranslator {
    translate: (namespace: string, id: string, params?: object) => string;
    addResource: (lng: string, resource: IResource) => void;
}

export interface IResource {
    [key: string]: ResourceKey | IResource;
}

type ResourceKey = string;

export class Translator implements ITranslator {
    public static getInstance() {
        if (!Translator.instance) {
            Translator.instance = new Translator();
        }

        return Translator.instance;
    }

    private static instance: Translator;
    private constructor() {}

    public translate(
        namespace: string,
        id: string,
        params: object = {},
    ): string {
        return i18next.getResource(i18next.language, namespace, id, params);
    }

    public addResource(lng: string, resource: IResource): void {
        i18next.addResourceBundle(
            lng,
            DEFAULT_NAMESPACE,
            resource,
            true,
            false,
        );
    }

    public setLanguage(value: string) {
        i18next.changeLanguage(value);
        appStorage.setItem("locale", JSON.stringify(value));
    }
}
