import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { ObjectFieldTemplatePropertyType } from "@rjsf/utils/src/types";
import get from "lodash-es/get";

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
    const {
        description,
        title,
        properties,
        uiSchema,
        idSchema,
        schema,
    } = props;
    const showTitle = useMemo(() => uiSchema?.["ui:title"] || schema.title, [
        idSchema,
        schema,
    ]);

    return schema?.hidden ? (
        <></>
    ) : (
        <Box height={"inherit"}>
            {(showTitle || description) && (
                <Box mb={2}>
                    {showTitle && (
                        <Typography variant={"h6"} mb={1}>
                            {title}
                        </Typography>
                    )}
                    {description && (
                        <Typography
                            variant={"body1"}
                            color={"textSecondary"}
                            mb={1}
                        >
                            {description}
                        </Typography>
                    )}
                </Box>
            )}
            {properties.map(
                (element: ObjectFieldTemplatePropertyType, index: number) => {
                    const fullScreen =
                        (get(schema.properties, element.name) as any)
                            ?.fullScreen || false;
                    return (
                        <Box
                            key={index}
                            sx={
                                fullScreen
                                    ? {
                                          height: "inherit",
                                          mt: -2,
                                          "& > div": {
                                              height: "inherit",
                                          },
                                      }
                                    : {
                                          mb: 2,
                                          "&:last-child": {
                                              mb: 0,
                                          },
                                      }
                            }
                        >
                            {element.content}
                        </Box>
                    );
                },
            )}
        </Box>
    );
};
