import { ConfigAccess } from "./ConfigAccess";

const IS_ABSOLUTE = /^http/i;

class StorageUtilsClass {
    toStorageUrl(path: string): string {
        return this.isRelative(path) ? this.addStorageUrl(path) : path;
    }

    getStorageUrl(): string {
        return ConfigAccess.config.server?.storageUrl || "";
    }

    private isRelative(path: string): boolean {
        return !!path && !IS_ABSOLUTE.test(path);
    }

    private addStorageUrl(relativePath: string): string {
        const storageUrl = this.getStorageUrl();
        return !!storageUrl ? `${storageUrl}/${relativePath}` : "";
    }
}

export const StorageUtils = new StorageUtilsClass();
