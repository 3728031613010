import {
    IdentifiableUtils,
    IWithId,
    ObjectId,
    ArrayUtils,
} from "@mrs/webclient-shared-ui-lib";
import { IRequestQueue } from "./IRequestQueue";
import { IExecutingRequest } from "./model/IExecutingRequest";
import { IQueueRequest } from "./model/IQueueRequest";
import { Broadcast } from "../../app/infrastructure/broadcast/broadcast";

export class RequestQueue implements IRequestQueue {
    private maxSlots: number = 1;
    private readonly queue: IQueueRequest[] = [];
    private readonly slots: IExecutingRequest[] = [];
    private allRequestsDoneEvents: string | null = null;

    public setAllRequestsDoneEvent(allRequestsDoneEvents: string | null) {
        this.allRequestsDoneEvents = allRequestsDoneEvents;
    }

    public setMaxRequestSlots(maxSlots: number) {
        this.maxSlots = maxSlots;
    }

    public add(request: IQueueRequest): void {
        if (this.isRequestExist(request.id)) return;

        this.queue.push(request);
        this.start();
    }

    public abort(id: ObjectId): void {
        this.abortIfExists(id);
        this.removeById(id);
    }

    public clear(): void {
        this.slots.forEach((slot) => slot.request.cancel());
        ArrayUtils.clear(this.slots);
        ArrayUtils.clear(this.queue);
    }

    public start() {
        this.fillSlots();
    }

    public isRequestExist(id: ObjectId): boolean {
        return this.requestIsExecuting(id) || this.requestIsAwait(id);
    }

    public requestIsAwait(id: ObjectId): boolean {
        return this.findRequestIndex(this.queue, id) >= 0;
    }

    public requestIsExecuting(id: ObjectId): boolean {
        return this.findRequestIndex(this.slots, id) >= 0;
    }

    public trigOnAllRequestsDone(): void {
        if (this.allRequestsDoneEvents) {
            Broadcast.trig(this.allRequestsDoneEvents, null);
            this.setAllRequestsDoneEvent(null);
        }
    }

    public removeById(id: ObjectId): boolean {
        const sIndex = this.findRequestIndex(this.slots, id);
        if (sIndex >= 0) {
            this.slots.splice(sIndex, 1);
            this.fillSlots();
            return true;
        }

        const qIndex = this.findRequestIndex(this.queue, id);
        if (qIndex >= 0) {
            this.queue.splice(qIndex, 1);
            return true;
        }

        return false;
    }

    private fillSlots() {
        const slots = this.slots;
        const queue = this.queue;

        while (queue.length && slots.length < this.maxSlots) {
            this.startNextRequest();
        }

        if (!queue.length && !slots.length) {
            this.trigOnAllRequestsDone();
        }
    }

    private startNextRequest() {
        const currentRequest = this.queue.shift() as IQueueRequest;

        const id = currentRequest.id;
        const httpRequest = currentRequest.execute();
        httpRequest.then(() => {
            this.removeById(id);
        });

        const executingRequest: IExecutingRequest = {
            id,
            request: httpRequest,
        };

        this.slots.push(executingRequest);
    }

    private abortIfExists(id: ObjectId): void {
        const sIndex = this.findRequestIndex(this.slots, id);
        if (sIndex >= 0) {
            const slot = this.slots[sIndex];
            slot.request.cancel();
        }
    }

    private findRequestIndex(array: IWithId[], id: ObjectId): number {
        return IdentifiableUtils.getIndexById(array, id);
    }
}
