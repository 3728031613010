import { DeviceType } from "./DeviceType";

export class DeviceOs {
    static os() {
        return (
            Object.values(DeviceType).find((value) =>
                navigator.userAgent.toLowerCase().includes(value),
            ) || "not-defined"
        );
    }
}
