export const WARNING_COLOR_HUE_MAIN = 500;

export const WARNING_COLOR_HUES = {
    0: "#FFFFFF",
    50: "#FFF8E0",
    100: "#FFEDB0",
    200: "#FFE17C",
    300: "#FFD743",
    400: "#FFCC00",
    500: "#FFC300",
    600: "#FFB500",
    700: "#FFA100",
    800: "#FF8F00",
    900: "#FF6D00",
};
