import React, { useCallback, useState, useEffect } from "react";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import {
    DateUtils,
    IWithSx,
    TVoidCallback,
    VIEW_FILTER_TYPE,
    ICheckedFilter,
} from "@mrs/webclient-shared-ui-lib";
import { DateRange } from "@mui/lab";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ViewWithSearchManager } from "../../../managers/ViewWithSearchManager/ViewWithSearchManager";
import { DateRangeSelect } from "../../../shared/DateRangeSelect/DateRangeSelect";
import { useTranslation } from "react-i18next";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";

interface IViewFilterProps extends IWithSx {
    currentFilter: ICheckedFilter | null;
    parentName?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
    contentRef: React.RefObject<HTMLDivElement>;
    onChange: TVoidCallback<ICheckedFilter>;
    onFocus?: () => void;
    onBlur?: () => void;
    onDataValidation?: TVoidCallback<boolean>;
}

export const ViewFilter = (props: IViewFilterProps) => {
    const {
        currentFilter,
        disabled,
        parentName,
        contentRef,
        containerRef,
        onDataValidation,
    } = props;
    const { t } = useTranslation(DEFAULT_NAMESPACE);

    const [
        scrollableTarget,
        setScrollableTarget,
    ] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        setScrollableTarget(contentRef.current);
    }, [contentRef]);

    const _onChange = (item: ICheckedFilter, checked: boolean, value: any) => {
        const filterValue = { ...item.value };
        filterValue.params[0].value = value;
        props.onChange({ ...item, checked, value: filterValue });
    };

    const onChangeValue = (item: ICheckedFilter, value: string | any[]) => {
        _onChange(item, !!value.length, value);
    };

    const onChangeDates = (item: ICheckedFilter, dates: DateRange<Date>) => {
        const start = dates[0] as Date | null;
        const end = dates[1] as Date | null;
        const isStartValid = DateUtils.isValidDate(start);
        const isEndValid = DateUtils.isValidDate(end);
        const startTime = isStartValid
            ? startOfDay(start as Date).getTime()
            : start;
        const endTime = isEndValid ? endOfDay(end as Date).getTime() : end;

        onDataValidation && onDataValidation(isStartValid && isEndValid);
        _onChange(item, !!(startTime && endTime), {
            start: startTime,
            end: endTime,
        });
    };

    const getComponentByFilter = useCallback(
        (filter: ICheckedFilter): React.ReactNode => {
            const value = filter.value.params[0].value;
            switch (filter.value.type) {
                case VIEW_FILTER_TYPE.TEXT:
                    return (
                        <TextField
                            autoFocus={props.autoFocus}
                            value={value || ""}
                            onChange={({ target }) =>
                                onChangeValue(filter, target.value)
                            }
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            fullWidth
                            InputProps={{
                                notched: false,
                                sx: { mt: 0 },
                            }}
                            sx={styles.filter}
                            InputLabelProps={{ shrink: true }}
                            placeholder={filter.value.placeholder || ""}
                            variant={"outlined"}
                            disabled={disabled}
                        />
                    );
                case VIEW_FILTER_TYPE.DATE:
                    return (
                        <DateRangeSelect
                            startText={t("common.start")}
                            endText={t("common.end")}
                            fullWidth
                            disabled={disabled}
                            onChange={(dates: DateRange<Date>) =>
                                onChangeDates(filter, dates)
                            }
                            sx={styles.filter}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            value={[
                                (value as any)?.start || null,
                                (value as any)?.end || null,
                            ]}
                        />
                    );
                case VIEW_FILTER_TYPE.VIEW:
                    if (!scrollableTarget) return;
                    return (
                        <ViewWithSearchManager
                            viewName={filter.value?.viewId || ""}
                            scrollableTarget={scrollableTarget}
                            values={(value as object[]) || []}
                            disabled={disabled}
                            autoFocus={props.autoFocus}
                            multiple={filter.value.multiple}
                            parentName={parentName}
                            onChange={(els) => onChangeValue(filter, els)}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                        />
                    );
                default:
                    return <div />;
            }
        },
        [scrollableTarget, disabled, parentName],
    );

    return (
        <Box sx={props.sx} ref={containerRef}>
            {!!currentFilter && getComponentByFilter(currentFilter)}
        </Box>
    );
};

const styles = {
    filter: {
        p: 2,
        boxSizing: "border-box",
    },
};
