import React, { useCallback, useEffect, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, i18n as dhtmlxi18n } from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import "../../../../../lib/dhtmlxTable/fonts.scss";
import { DataGridViewPresenter } from "./presenter/DataGridViewPresenter";
import { dhtmlxCalendarLocales } from "@ui/locale/dhtmlxCalendar";
import { IDataGridViewColumn } from "./presenter/IDataGridViewColumn";
import { IDataGridViewRow } from "./presenter/IDataGridViewRow";

interface IDataGridViewProps {
    presenter: DataGridViewPresenter;
}

export const DataGridView = observer(({ presenter }: IDataGridViewProps) => {
    const theme = useTheme();
    const { i18n, t } = useTranslation();
    const elementRef = useRef<HTMLDivElement>(null);

    const renderCheckbox = useCallback((
        value?: boolean,
        //@ts-ignore
        row: IDataGridViewRow,
        column: IDataGridViewColumn,
    ) => {
        if (value == null) return "";

        const component = <Checkbox checked={value} disabled />;
        return renderToStaticMarkup(component);
    }, []);

    const renderFileButton = useCallback(
        (value: string, row: IDataGridViewRow, column: IDataGridViewColumn) => {
            if (value == null) return "";

            const component = (
                <Link
                    href={value}
                    download={"File"}
                    target={"_blank"}
                    variant={"caption"}
                    style={{
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(0.5, 1),
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.grey[500],
                    }}
                >
                    {t("common:common.open")}
                </Link>
            );

            return renderToStaticMarkup(component);
        },
        [],
    );

    const modifyColumns = useCallback(
        (columns: IDataGridViewColumn[]) => {
            return columns.map((column) => {
                const { type, ...other } = column;

                if (column.type === "checkbox") {
                    return {
                        ...other,
                        htmlEnable: true,
                        template: renderCheckbox,
                    };
                }

                if (column.type === "file") {
                    return {
                        ...other,
                        htmlEnable: true,
                        template: renderFileButton,
                    };
                }

                return column;
            });
        },
        [renderCheckbox, renderFileButton],
    );

    useEffect(() => {
        dhtmlxi18n.setLocale("calendar", dhtmlxCalendarLocales[i18n.language]);

        if (!elementRef.current) {
            throw new Error("Container not found");
        }

        const items = presenter.renderItems;

        const grid = new Grid(elementRef.current, {
            autoWidth: true,
            columns: modifyColumns(presenter.columns),
            data: items,
            resizable: true,
            css: "styled_grid",
        });

        grid.events.on("cellClick", (row, column, e) => {
            if (column.id === "report") return;
            //@ts-ignore
            presenter.onClickItem(row.id);
        });

        return () => grid.destructor();
    }, [presenter.renderItems, presenter.columns, modifyColumns]);

    return <Box height={"100%"} ref={elementRef} />;
});
