import { classToClass, classToPlain, plainToClass } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import assignWith from "lodash-es/assignWith";
import { IWithId } from "@mrs/webclient-shared-ui-lib";

export class EntityMapper {
    public static toDomainEntities<DomainEntity>(
        domainClass: ClassType<DomainEntity>,
        rawObjects: object[] = [],
    ): DomainEntity[] {
        return rawObjects.map((raw) =>
            plainToClass(domainClass, raw, { excludeExtraneousValues: true }),
        );
    }

    public static toDomainEntity<DomainEntity>(
        domainClass: ClassType<DomainEntity>,
        raw: object,
    ): DomainEntity {
        return plainToClass(domainClass, raw, {
            excludeExtraneousValues: true,
        });
    }

    public static toPlainObject<T extends IWithId>(entity: T): object {
        return classToPlain(entity);
    }

    public static toPlainObjects<T>(items: T[]) {
        return items.map((item: T) => classToPlain(item));
    }

    public static cloneEntity<DomainEntity>(
        entity: DomainEntity,
    ): DomainEntity {
        return classToClass(entity);
    }

    public static updateFields<T, C = object>(oldItem: T, changes: C): T {
        return assignWith(oldItem, changes, customizer);
    }
}

function customizer(oldValue: any, newValue: any): any {
    return newValue === undefined ? oldValue : newValue;
}
