import React from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const BASIC_INFORMATION_TITLE_WIDTH = 106;

interface IInformationTabProps {
    data: IInformationData[];
}

interface IInformationData {
    title: string;
    value: string;
}

export const InformationTab = observer((props: IInformationTabProps) => {
    const { data } = props;

    return (
        <Box
            sx={{
                p: 2,
                borderRadius: 1,
                backgroundColor: "background.paper",
                border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
        >
            {!!data.length &&
                data.map((item: IInformationData, index: number) => {
                    return (
                        !!item.value && (
                            <Box
                                key={`${item.title}-${index}`}
                                sx={{
                                    display: "flex",
                                    mb: 1.5,
                                    "&:last-child": {
                                        mb: 0,
                                    },
                                }}
                            >
                                <Typography
                                    variant={"body2"}
                                    color={"textSecondary"}
                                    sx={{
                                        mr: 2,
                                        minWidth: BASIC_INFORMATION_TITLE_WIDTH,
                                    }}
                                >
                                    {`${item.title}:`}
                                </Typography>
                                <Typography variant={"body2"}>
                                    {item.value}
                                </Typography>
                            </Box>
                        )
                    );
                })}
        </Box>
    );
});
