import { IWithId, ObjectId } from "@mrs/webclient-shared-ui-lib";
import { IRestService } from "../../../../infrastructure/http/IRestService";
import { RestService } from "../../../../infrastructure/http/RestService";
import { IGenerateReportParams } from "../model/IGenerateReportParams";

class ReportTemplateServiceClass {
    private readonly restService: IRestService = new RestService();
    private readonly resourcePath: string = "reports";

    async generateReport(data: IGenerateReportParams): Promise<ObjectId> {
        const res = await this.restService
            .post(`${this.resourcePath}/generate`, {
                data,
            })
            .asPromise();
        return (res as IWithId).id;
    }
}
export const ReportTemplateService = new ReportTemplateServiceClass();
