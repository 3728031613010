export enum Routes {
    SETTINGS = "/app-settings",
    VIEW = "/view",
    LOGIN = "/login",
    SUCCESS_LOGIN = "/successLogin",
    PLASMIC = "/plasmic-host",
}

export function getViewPathTemplate() {
    return `${Routes.VIEW}/view-name=:viewName`;
}
export function getViewPath(viewName: string = "") {
    return `${Routes.VIEW}/view-name=${viewName}`;
}
