import { injectable } from "inversify";
import { IQueryService } from "../../../../infrastructure/queryService/IQueryService";
import { ObjectId, TQueryParams, IUserDTO } from "@mrs/webclient-shared-ui-lib";
import { User } from "../model/User";
import { UserUtils } from "@utils/UserUtils";
import { IUserService } from "./IUserService";
import { CurrentUser } from "../currentUser/CurrentUser";
import { ConfigAccess } from "@utils/ConfigAccess";

@injectable()
export class UserService implements IUserService {
    private readonly queryService: IQueryService<any>;

    constructor(service: IQueryService<any>) {
        this.queryService = service;
    }

    async getByToken(token: string): Promise<User> {
        const queryParams: TQueryParams = {
            currentUser: this.getEncodeComponent({
                accessToken: UserUtils.parseToken(token),
            }),
        };
        const user = (await this.getUsers(queryParams))[0];
        user.accessToken = token;
        return user;
    }

    async getById(id: ObjectId): Promise<User> {
        return (await this.getByIds([id]))[0];
    }

    async getByIds(ids: ObjectId[]): Promise<User[]> {
        return this.getUsers({
            filters: this.getEncodeComponent({ ids }),
            limit: ids.length,
        });
    }

    async getUsers(params: TQueryParams): Promise<User[]> {
        const items = await this.queryService.getViewItems(this.userViewId, {
            currentUser: this.getEncodeComponent(
                CurrentUser.getDtoWithParsedToken(true),
            ),
            ...params,
        });
        return this.toEntities(items);
    }

    private toEntities(data: IUserDTO[]): User[] {
        return data.map((el) => new User(el));
    }

    private get userViewId(): string {
        return ConfigAccess.config.userViewId || "";
    }

    private getEncodeComponent(variables: any): string {
        return encodeURIComponent(JSON.stringify(variables));
    }
}
