import { EntityMapper } from "../../../../sharedKernel/mapper/EntityMapper";
import { CRUDRepository } from "../../../../sharedKernel/repository/impl/CRUDRepository";
import { IPostRepository } from "../IPostRepository";
import { IPostApiService } from "../../apiService/IPostApiService";
import {
    ObjectId,
    TQueryParams,
    IPostDTO,
    IPostCreateDTO,
} from "@mrs/webclient-shared-ui-lib";
import { Changes } from "../../../../sharedKernel/types/Changes";
import { RequestUtils } from "@utils/request/RequestUtils";
import cloneDeep from "lodash-es/cloneDeep";

export class PostRepository
    extends CRUDRepository<IPostDTO, IPostDTO, IPostCreateDTO>
    implements IPostRepository {
    protected apiService: IPostApiService;

    constructor(service: IPostApiService) {
        super(service);
        this.apiService = service;
    }

    async getPosts(params: TQueryParams): Promise<IPostDTO[]> {
        return this.apiService.getItems(params);
    }

    async getPostById(id: ObjectId): Promise<IPostDTO> {
        return this.apiService.getItemById(id);
    }

    async updatePost(
        entity: IPostDTO,
        changes: Changes<IPostDTO>,
    ): Promise<IPostDTO> {
        const changedEntity = cloneDeep(entity);
        EntityMapper.updateFields(changedEntity, changes);

        const patch = RequestUtils.createPatch(entity, changedEntity);

        await this.apiService.patchItem([entity.id], patch);

        return changedEntity;
    }

    toEntities(data: IPostDTO[]): IPostDTO[] {
        return data;
    }

    toEntity(data: IPostDTO): IPostDTO {
        return data;
    }
}
