import React from "react";
import { IRegistrationComponent } from "../RegistrationComponents";
import { LetterUserAvatar } from "@mrs/webclient-shared-ui-lib";

export const LetterUserAvatarRegistration: IRegistrationComponent<React.FC> = {
    component: LetterUserAvatar,
    meta: {
        name: "LetterUserAvatar",
        props: {
            size: "number",
            name: "string",
        },
    },
};
