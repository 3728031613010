// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Inter-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Inter\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: 400;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Inter\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  font-weight: 500;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Inter\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n  font-weight: 600;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Inter\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n  font-weight: 700;\n  font-style: normal;\n}", "",{"version":3,"sources":["webpack://./src/app/ui/theme/baseTheme/fonts/inter.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACA;EACI,oBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACA;EACI,oBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACA;EACI,oBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":["@font-face {\n    font-family: \"Inter\";\n    src: url(\"./Inter-Regular.ttf\") format(\"truetype\");\n    font-weight: 400;\n    font-style: normal;\n}\n@font-face {\n    font-family: \"Inter\";\n    src: url(\"./Inter-Medium.ttf\") format(\"truetype\");\n    font-weight: 500;\n    font-style: normal;\n}\n@font-face {\n    font-family: \"Inter\";\n    src: url(\"./Inter-Bold.ttf\") format(\"truetype\");\n    font-weight: 600;\n    font-style: normal;\n}\n@font-face {\n    font-family: \"Inter\";\n    src: url(\"./Inter-SemiBold.ttf\") format(\"truetype\");\n    font-weight: 700;\n    font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
