import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import isNull from "lodash-es/isNull";
import { CreateReportDialogPresenter } from "./presenter/CreateReportDialogPresenter";
import { observer } from "mobx-react";
import { ReportTemplate } from "../../../../core/context/reportTemplate/model/ReportTemplate";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CustomForm } from "../CustomForm/CustomForm";
import { Skeletons } from "../Skeletons/Skeletons";
import { DEFAULT_NAMESPACE } from "@lib/translator/i18next";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    FluentUiIconName,
    IActionButtonProps,
    Icon,
    UniversalDialog,
} from "@mrs/webclient-shared-ui-lib";

const ICON_WIDTH = 24;

interface ICreateReportDialogProps {
    isOpen: boolean;
    templates: ReportTemplate[];
    reportParams: Record<string, any>;
    onClose: () => void;
}

export const CreateReportDialog = observer(
    (props: ICreateReportDialogProps) => {
        const { isOpen, templates, reportParams, onClose } = props;
        const { t } = useTranslation(DEFAULT_NAMESPACE);
        const formRef = useRef<HTMLFormElement>(null);
        const presenter = useMemo(() => new CreateReportDialogPresenter(), []);
        const {
            formInstance,
            form,
            currentTemplate,
            onSubmitForm,
            onChangeFormData,
            logEvent,
            onChangeTemplate,
        } = presenter;

        const onCloseDialog = useCallback((isEscapeKeyDown?: boolean) => {
            onClose();
            onChangeTemplate(null, reportParams);
            logEvent({
                component: AnalyticsEventComponent.Close,
                action: isEscapeKeyDown
                    ? AnalyticsEventAction.HotKey
                    : AnalyticsEventAction.Click,
            });
        }, []);

        const onClickBack = useCallback(() => {
            onChangeTemplate(null, reportParams);
            logEvent({
                component: AnalyticsEventComponent.Back,
                action: AnalyticsEventAction.Click,
            });
        }, []);

        const onClickGenerate = () => {
            formRef.current?.submit();
            logEvent({
                action: AnalyticsEventAction.Click,
                component: AnalyticsEventComponent.Create,
                params: { target: currentTemplate?.templateName },
            });
        };

        const onSubmit = React.useCallback(
            async (data: object) => {
                await onSubmitForm(reportParams, onClose);
            },
            [reportParams],
        );

        const actions: IActionButtonProps[] = useMemo(() => {
            return [
                {
                    title: t("common.cancel"),
                    onClick: () => onCloseDialog(),
                    buttonVariant: "outlined",
                    color: "secondary",
                    fullWidth: false,
                    size: "medium",
                },
                {
                    title: t("common.generate"),
                    onClick: () => onClickGenerate(),
                    buttonVariant: "contained",
                    color: "primary",
                    fullWidth: false,
                    size: "medium",
                },
            ];
        }, []);

        return (
            <UniversalDialog
                isOpen={isOpen}
                title={t(
                    currentTemplate
                        ? "reportDialog.titleForm"
                        : "reportDialog.title",
                )}
                actions={currentTemplate ? actions : undefined}
                withBackIcon={!isNull(currentTemplate)}
                onClose={onCloseDialog}
                onBack={onClickBack}
                dialogProps={{ disableEnforceFocus: true }}
            >
                {form && formInstance ? (
                    <Box
                        sx={{
                            "& > form": {
                                m: 2,
                            },
                        }}
                    >
                        <CustomForm
                            formName={form.name}
                            formSchema={form}
                            formData={formInstance.data}
                            onChange={onChangeFormData}
                            onSubmit={onSubmit}
                            loader={<Skeletons quantity={5} />}
                            formRef={formRef}
                        >
                            <div />
                        </CustomForm>
                    </Box>
                ) : (
                    !!templates.length &&
                    !currentTemplate && (
                        <Box bgcolor={"background.paper"}>
                            {templates.map((template, index) => (
                                <React.Fragment
                                    key={`item-${index}-${template.name}`}
                                >
                                    <ListItem
                                        button
                                        disableRipple
                                        divider={index !== templates.length - 1}
                                        onClick={() =>
                                            onChangeTemplate(
                                                template,
                                                reportParams,
                                            )
                                        }
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            p: (theme) =>
                                                theme.spacing(1.75, 2),
                                        }}
                                    >
                                        <ListItemText
                                            primary={template.templateName}
                                            primaryTypographyProps={{
                                                variant: "body2",
                                            }}
                                        />
                                        <ListItemIcon
                                            sx={{
                                                minWidth: ICON_WIDTH,
                                            }}
                                        >
                                            <Icon
                                                iconName={
                                                    FluentUiIconName.ChevronRightRegular
                                                }
                                            />
                                        </ListItemIcon>
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </Box>
                    )
                )}
            </UniversalDialog>
        );
    },
);
